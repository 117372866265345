<template>
  <div id="aggre_main">
    <v-container>
      <v-row>
        <v-col>
          <aggre-menu :target-date="toDate" :selected-category="selectedCategory" @update-target-date="updateTargetDate" @update-aggregation="updateAggregation" />
        </v-col>
      </v-row>
      <v-row v-if="isAggregate">
        <v-col v-if="isOpenDailyChart && $store.state.aggreMode.span === 1">
          <aggre-daily :to-date="toDate" />
        </v-col>
        <v-col v-else-if="isOpenWeeklyChart && $store.state.aggreMode.span === 2">
          <aggre-weekly :to-date="toDate" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import moment from "moment";
  import Loading from "@/components/Loading";
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import AggreMenu from "@/components/aggre/AggreMenu";
  import AggreDaily from "@/components/aggre/charts/daily/AggreDaily";
  import AggreWeekly from "@/components/aggre/charts/weekly/AggreWeekly";

  import { STORE_ACTIONS } from "@/utils/config";

  /**
   * @class
   * @classdesc 集計表示メイン部分
   * @vue-prop {Object} poleData ポール情報
   */
  const AggreMain = {
    components: {
      Loading,
      AggreMenu,
      AggreDaily,
      AggreWeekly
    },
    props: {
      poleData: Object,
      selectedCategory: Number
    },
    mixins: [commonFunction, communicationFunction],
    data() {
      return {
        // レポートデータ取得用
        dailyReports: {},
        weeklyReports: {},
        // グラフ表示フラグ
        isOpenDailyChart: false,
        isOpenWeeklyChart: false,
        // 取得対象時刻(文字列形式、YYYY/MM/DD HH:mm:ss)
        fromDate: "",
        toDate: "",
        currentDate: "",
        fromDateString: "",
        toDateString: "",
        isAggregate: false,
        // エラーコード種別
        errcode: {
          noContent: 204,
          badRequest: 400,
          internalServerError: 500
        }
      };
    },
    async created() {
      // 時間別、日別、月別のデータをリセットする
      this.$store.dispatch("setSumariesDailyList", null);
      this.$store.dispatch("setSumariesWeeklyList", null);
      // 初期の日付を設定する
      this.currentDate = moment();
      this.setDateSpan();
    },
    watch: {
      poleData: {
        // ポール情報が変更されたら、集計データを更新する
        async handler() {
          if (this.$store.state.aggreMode.category !== 0) {
            switch (this.$store.state.aggreMode.span) {
              case 1:
                this.$emit("loading-chart-data", true);
                this.isOpenDailyChart = false;
                this.getDailyData();
                break;
              case 2:
                this.$emit("loading-chart-data", true);
                this.isOpenWeeklyChart = false;
                this.getWeeklyData();
                break;
            }
          }
        },
        deep: true
      },
      $store: {
        state: {
          async handler(val) {
            console.log(val);
          },
          deep: true
        }
      }
    },
    methods: {
      /**
       * momentデータの日付情報を文字列に変換する（時刻は0リセット)
       * @param {moment} date 対象の日付情報
       * @return {String} 日付文字列
       */
      setDateString(date) {
        return date.format("YYYY/MM/DD") + " 00:00:00";
      },
      /**
       * 取得対象とする期間を設定する
       */
      setDateSpan() {
        this.toDate = moment(this.currentDate);
        this.toDateString = this.setDateString(this.toDate);
        // 取得開始日を、取得終了日の7日前に設定する
        this.fromDate = moment(this.currentDate).subtract(7, "d");
        this.fromDateString = this.setDateString(this.fromDate);
      },
      /**
       * 対象の集計データが既に設定済みかを確認し、各グラフデータとして設定する
       * @param {Number} val 表示モード(1: 時間別、2: 日別、3: 月別)
       */
      checkChartData(val) {
        const state = this.$store.state.aggregations;
        switch (val) {
          // 時間別
          case 1:
            // Vuexストアに集計データが存在してない場合、APIから取得する
            if (
              state.daily.date !== null &&
              typeof state.daily.date === "object" &&
              state.daily.date.diff(this.toDate) === 0 &&
              state.summariesDailyList !== void 0 &&
              state.summariesDailyList !== null
            ) {
              // 存在している場合は各グラフデータ用のVuexストアに設定する
              const daily = this.$store.state.summariesDailyList;
              this.$store.dispatch(STORE_ACTIONS.dailyNearMissReport, daily.nearMisses[0]);
              this.$store.dispatch(STORE_ACTIONS.dailyTrafficReport, daily.traffic[0]);
              this.$store.dispatch(STORE_ACTIONS.dailyAxlAppReport, [daily.accelerationApproachAverageData[0], daily.accelerationApproachMaxData[0]]);
              this.isOpenDailyChart = true;
            } else {
              this.$store.dispatch("updateDailyDate", this.toDate);
              this.$emit("loading-chart-data", true);
              this.isOpenDailyChart = false;
              this.getDailyData();
            }
            break;
          // 日別
          case 2:
            if (
              state.weekly.date !== null &&
              state.weekly.date === "object" &&
              state.weekly.date.diff(this.fromDate) === 0 &&
              state.summariesWeeklyList !== void 0 &&
              state.summariesWeeklyList !== null
            ) {
              const weekly = this.$store.state.summariesWeeklyList;
              this.$store.dispatch(STORE_ACTIONS.weeklyNearMissReport, weekly.nearMisses[0]);
              this.$store.dispatch(STORE_ACTIONS.weeklyTrafficReport, weekly.traffic[0]);
              this.$store.dispatch(STORE_ACTIONS.weeklyAxlAppReport, [weekly.accelerationApproachAverageData[0], weekly.accelerationApproachMaxData[0]]);
              this.isOpenWeeklyChart = true;
            } else {
              this.$store.dispatch("updateWeeklyDate", this.fromDate);
              this.$emit("loading-chart-data", true);
              this.isOpenWeeklyChart = false;
              this.getWeeklyData();
            }
            break;
          default:
        }
      },
      /**
       * デイリーレポートを取得する
       */
      getDailyData() {
        const getDailyDataFunc = this.updateSumariesDailyData(this.poleData.poleId, this.toDateString, 20, 50);
        getDailyDataFunc
          .then(() => {
            // 日次データがVuexストア上に登録されたら成功と判定する
            if (this.$store.state.summariesDailyList !== void 0 && this.$store.state.summariesDailyList !== null) {
              return this.$store.state.summariesDailyList;
            } else {
              throw new Error(this.errcode.noContent);
            }
          })
          .then(val => {
            // 日次データから各グラフ用データを抽出してVuexストアに登録する
            this.$store.dispatch(STORE_ACTIONS.dailyNearMissReport, val.nearMisses[0]);
            this.$store.dispatch(STORE_ACTIONS.dailyTrafficReport, val.traffic[0]);
            this.$store.dispatch(STORE_ACTIONS.dailyAxlAppReport, [val.accelerationApproachAverageData[0], val.accelerationApproachMaxData[0]]);
          })
          .then(() => {
            // ローディング画面を消去、各グラフコンポーネントを有効にする
            this.isOpenDailyChart = true;
            this.isAggregate = true;
            this.$emit("loading-chart-data", false);
          })
          .catch(error => {
            if (error === this.errcode.noContent) {
              this.$emit("set-dialog", true, "時間別データなし", "時間別データが存在しません。");
            } else {
              this.$emit("set-dialog", true, "日別データ取得失敗", "日別データの取得に失敗しました。:" + error);
            }
          });
      },
      /**
       * ウィークリーレポートを取得する
       */
      getWeeklyData() {
        const getWeeklyDataFunc = this.updateSumariesWeeklyData(this.poleData.poleId, this.fromDateString, 20, 50);
        getWeeklyDataFunc
          .then(() => {
            if (this.$store.state.summariesWeeklyList !== void 0 && this.$store.state.summariesWeeklyList !== null) {
              return this.$store.state.summariesWeeklyList;
            } else {
              throw new Error(this.errcode.noContent);
            }
          })
          .then(val => {
            this.$store.dispatch(STORE_ACTIONS.weeklyNearMissReport, val.nearMisses[0]);
            this.$store.dispatch(STORE_ACTIONS.weeklyTrafficReport, val.traffic[0]);
            this.$store.dispatch(STORE_ACTIONS.weeklyAxlAppReport, [val.accelerationApproachAverageData[0], val.accelerationApproachMaxData[0]]);
          })
          .then(() => {
            // ローディング画面を消去、各グラフコンポーネントを有効にする
            this.isOpenWeeklyChart = true;
            this.isAggregate = true;
            this.$emit("loading-chart-data", false);
          })
          .catch(error => {
            if (error === this.errcode.noContent) {
              this.$emit("set-dialog", true, "日別データなし", "日別データが存在しません。");
            } else {
              this.$emit("set-dialog", true, "日別データ取得失敗", "日別データの取得に失敗しました。:" + error);
            }
          });
      },
      /**
       * メニュー側の操作に応じて対象の日付を変更する
       * @param {Number} mode 表示モード（1: 時間別、2: 日別、3: 月別)
       * @param {Number} diff 前回からの差分
       */
      updateTargetDate(mode, diff) {
        const modeStringList = ["days", "weeks", "months", "years"];
        if (diff >= 0) {
          this.currentDate.add(diff, modeStringList[mode - 1]);
          // 更新した日付が当日よりも先になった場合、当日に変更する
          if (moment().diff(this.currentDate) < 0) {
            this.currentDate = moment();
          }
        } else {
          this.currentDate.subtract(Math.abs(diff), modeStringList[mode - 1]);
        }
        // 集計データのリセット
        switch (this.$store.state.aggreMode.span) {
          case 1:
            this.$store.dispatch("setSumariesDailyList", null);
            break;
          case 2:
            this.$store.dispatch("setSumariesWeeklyList", null);
            break;
          default:
        }
        // 取得期間の変更
        this.setDateSpan();
      },
      /**
       * 集計ボタン押下状態の変更
       * @param {Boolean} val 集計ボタン押下状態
       */
      updateAggregation(val) {
        this.isAggregate = val;
        // ボタンを押した状態の場合、対象データが存在するかチェックする
        if (this.isAggregate) {
          this.checkChartData(this.$store.state.aggreMode.span);
        }
      }
    }
  };

  export default AggreMain;
</script>
