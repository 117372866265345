<template>
  <div class="virtual_view">
    <v-container fluid fill-height>
      <v-row class="v3-3-grid-1">
        <v-col cols="8">
          <!-- Virtual映像ブロック -->
          <v-card id="virtual_vmap" elevation="10">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 10vh">
              <span class="py-0">VIRTUAL映像</span>
              <v-spacer />
              <date-picker
                v-model="datetime"
                type="datetime"
                placeholder="再生日時選択"
                format="YYYY/MM/DD HH:mm:ss"
                formatted="YYYY/MM/DD HH:mm:ss"
                style="width: 20vw; height: 5vh; line-height: 10px;"
                :disabled="isDataAcquisition"
              ></date-picker>
              <v-tooltip top v-if="!isDataAcquisition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-2" fab small color="dark" v-bind="attrs" v-on="on" @click="play()">
                    <v-icon color="dark">
                      mdi-play
                    </v-icon>
                  </v-btn>
                </template>
                <span>再生開始</span>
              </v-tooltip>
              <v-tooltip top v-if="isDataAcquisition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-2" fab small color="dark" v-bind="attrs" v-on="on" @click="pause()">
                    <v-icon color="dark">
                      mdi-stop
                    </v-icon>
                  </v-btn>
                </template>
                <span>停止</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-2" fab small color="dark" v-bind="attrs" v-on="on" @click="rewind()">
                    <v-icon color="dark"> mdi-rewind-10 </v-icon>
                  </v-btn>
                </template>
                <span>10秒巻き戻し</span>
              </v-tooltip>
              <v-spacer />
              <SelectSensor :sensorList="sensorList" :sensorTemp="selectSensorTemp" @setUpSelectSensor="setUpSelectSensor" classCom="virtualCom" classTree="virtualTree" />
            </v-card-title>
            <v-divider />
            <div class="pt-0" align="center">
              <VirtualMap ref="virtual_map" :selectSensor="selectSensor" :accident="false" />
            </div>
          </v-card>
        </v-col>

        <v-col cols="4">
          <!-- 交差点内物標情報 -->
          <v-card id="virtual_location" elevation="1">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 5vh">
              <span class="py-0">交差点内物標情報</span>
              <v-spacer />
              <v-btn color="blue-grey" dark v-on:click="onClickTableSet" height="4vh">
                <v-icon>mdi-table-settings</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider />
            <LocationInfoTable ref="location_table" :locationTable="locationTable" :selectSensor="selectSensor" :itemsPerPage="10" v-on:selectObject="selectObject" />
          </v-card>
          <v-card id="realtime_led" elevation="0" outlined max-height="30vh">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 4vh">
              <span class="py-0">LED表示板</span>
              <v-spacer />
            </v-card-title>
            <LedInfoTable ref="led_info_table" :selectSensor="selectSensor" :ledSensorList="ledSensorList" :mode="mode" />
            <v-divider />
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-main>
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" />
    <Loading v-show="isLoading" />
    <CommonDialog :dialog="dialog" />
  </div>
</template>

<script>
  import moment from "moment";
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import CommonDialog from "@/components/CommonDialog";
  import VirtualMap from "@/components/VirtualMap";
  import LocationInfoTable from "@/components/LocationInfoTable";
  import SelectSensor from "@/components/SelectSensor";
  import LedInfoTable from "@/components/LedInfoTable";
  import { commonFunction, timerFunction, communicationFunction } from "@/mixins/utils";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import "vue2-datepicker/locale/ja";

  export default {
    name: "VirtualView",
    components: {
      Title,
      Loading,
      CommonDialog,
      VirtualMap,
      LocationInfoTable,
      DatePicker,
      SelectSensor,
      LedInfoTable
    },
    mixins: [commonFunction, timerFunction, communicationFunction],
    data: function() {
      return {
        setting: undefined,
        address: undefined,
        titleInfo: {
          title: "",
          show: {
            realtime: true,
            multicamera: true,
            menu: true
          },
          menuList: []
        },
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        isLoading: true,
        poleid: undefined,
        locationTable: undefined,
        sensorList: [],
        selectSensor: [],
        selectSensorTemp: [],
        poleInfo: undefined,
        datetime: null,
        interval_sec: undefined,
        rewindValue: 10,
        isDataAcquisition: false,
        isChange: false,
        ledSensorList: [],
        mode: "realTime"
      };
    },
    created() {
      this.initialize();
    },
    mounted() {},
    beforeDestroy() {
      this.stopPosListWithPoleUpdateTimer();
    },
    methods: {
      async play() {
        this.isDataAcquisition = true;
        if (this.datetime != null) {
          this.isLoading = true;
          this.mode = "virtual";
          this.startPosListWithPoleUpdateTimer(this.poleId, this.setting.common.posUpdateInterval, this.timeoutPosListWithPoleTimer);
          this.isChange = true;
        } else {
          this.isLoading = true;
          this.mode = "realTime";
          if (this.isChange) {
            this.isChange = false;
            let promise = this.updatePoleList(null, this.poleId, null, true);
            promise
              .then(() => {
                this.setSelectPole(this.poleId, this.address);
                this.updateSensorList(this.poleInfo, this.$store.state.selectPole, this.updatePoleEvent, this.updateSensorEvent);
                this.isLoading = false;
                this.startPosListWithPoleUpdateTimer(this.poleId, this.setting.common.posUpdateIntervalReal, this.timeoutPosListRealTimer);
              })
              .catch(() => {
                this.isLoading = false;
                this.showErrorDialig(this.str.dialog.title.getError, this.str.dialog.message.getErrorSensorInfo);
              });
          } else {
            this.startPosListWithPoleUpdateTimer(this.poleId, this.setting.common.posUpdateIntervalReal, this.timeoutPosListRealTimer);
          }
        }
      },
      pause() {
        this.isDataAcquisition = false;
        this.stopPosListWithPoleUpdateTimer();
      },
      rewind() {
        if (this.datetime != null) {
          let tempDatetime = moment(this.datetime);
          this.datetime = new Date(tempDatetime.subtract(this.rewindValue, "seconds").format("YYYY/MM/DD HH:mm:ss"));
        }
      },
      initialize() {
        this.setting = this.getSetting();
        this.address = this.$store.state.selectPole.address;
        this.titleInfo.title = this.$store.state.selectPole.address + " バーチャルモニター";
        this.titleInfo.menuList = this.setMenuList(this.setting.menu.realtime);
        this.poleId = this.$store.state.selectPole.poleId;
        this.locationTable = this.setting.locationInfoTableType2;
        this.interval_sec = this.setting.common.posUpdateInterval / 1000;
        this.initSensorList(this.poleId);
      },
      async initSensorList(poleId) {
        let promise = this.updatePoleList(null, poleId, null, true);
        promise
          .then(() => {
            this.setSelectPole(poleId, this.address);
            this.sensorList = this.getSensorSettingList();
            if (this.sensorList.length != 0) {
              for (const item of this.sensorList) {
                for (const itemData of item.children) {
                  this.selectSensorTemp.push(itemData.id);
                  let obj = JSON.parse(itemData.id);
                  if (obj.kind == "表示板") {
                    this.ledSensorList.push(obj);
                  }
                }
              }
            }
            this.poleInfo = JSON.parse(JSON.stringify(this.$store.state.selectPole));
            this.isLoading = false;
            this.play();
          })
          .catch(() => {
            this.isLoading = false;
            this.showErrorDialig(this.str.dialog.title.getError, this.str.dialog.message.getErrorSensorInfo);
          });
      },
      async timeoutPosListRealTimer(poleId) {
        let sensorIds = this.$store.state.selectPole.sensorList.map(obj => obj.sensorId);
        let promise = this.updatePosListReal(poleId, sensorIds);
        promise
          .then(() => {
            this.setSelectPole(poleId, this.address);
            this.updateSensorList(this.poleInfo, this.$store.state.selectPole, this.updatePoleEvent, this.updateSensorEvent);
            this.$refs.virtual_map.updatePos();
            this.$refs.location_table.update();
            this.$refs.led_info_table.update();
            this.isLoading = false;
          })
          .catch(error => {
            if (error == this.errcode.noContent) {
              this.setSelectPole(poleId, this.address);
              this.updateSensorList(this.poleInfo, this.$store.state.selectPole, this.updatePoleEvent, this.updateSensorEvent);
              this.$refs.virtual_map.updatePos();
              this.$refs.location_table.update();
              this.$refs.led_info_table.update();
              this.isLoading = false;
            }
          });
      },
      async timeoutPosListWithPoleTimer(poleId) {
        let tempDatetime = moment(this.datetime)
          .add(this.interval_sec, "seconds")
          .format("YYYY/MM/DD HH:mm:ss");
        this.datetime = new Date(tempDatetime);
        let promise = this.updatePosListWithPole(poleId, tempDatetime);
        promise
          .then(() => {
            this.setSelectPole(poleId, this.address);
            this.updateSensorList(this.poleInfo, this.$store.state.selectPole, this.updatePoleEvent, this.updateSensorEvent);
            this.$refs.virtual_map.updatePos();
            this.$refs.location_table.update();
            this.$refs.led_info_table.update();
            this.isLoading = false;
          })
          .catch(error => {
            if (error == this.errcode.noContent) {
              this.setSelectPole(poleId, this.address);
              this.updateSensorList(this.poleInfo, this.$store.state.selectPole, this.updatePoleEvent, this.updateSensorEvent);
              this.$refs.virtual_map.updatePos();
              this.$refs.location_table.update();
              this.$refs.led_info_table.update();
            }
            this.isLoading = false;
          });
      },
      showErrorDialig(title, message) {
        this.dialog.title = title;
        this.dialog.message = message;
        this.dialog.isDialog = true;
      },
      updatePoleEvent() {
        this.$refs.virtual_map.updatePole();
        this.poleInfo.latlng = JSON.parse(JSON.stringify(this.$store.state.selectPole.latlng));
      },
      updateSensorEvent() {
        this.sensorList = this.getSensorSettingList();
        this.selectSensor = this.updateSelectSensor(this.selectSensor, this.sensorList);
        this.$refs.virtual_map.updateSensor();
        this.poleInfo.sensorList = JSON.parse(JSON.stringify(this.$store.state.selectPole.sensorList));
      },
      async onClickTableSet() {
        await this.$refs.location_table.columSelectModal();
      },
      selectObject(latlng) {
        this.$refs.virtual_map.setCenter(latlng);
      },
      setUpSelectSensor(value) {
        this.selectSensor = [];
        if (value.length != 0) {
          for (const item of value) {
            let obj = JSON.parse(item);
            this.selectSensor.push(obj);
          }
        }
      }
    }
  };
</script>

<style>
  .virtual_view {
    padding-top: 40px;
    height: calc(100vh - 50px);
  }

  #virtual_vmap {
    height: 100%;
  }

  #virtual_location {
    height: 70%;
  }
</style>
