import { Amplify } from "aws-amplify";
// Amplifyデータを暗号化するパッケージ
import { EncryptStorage } from "encrypt-storage";
// ローカルストレージに対してデータを暗号化して保存する
const es = new EncryptStorage("smart-pole-aws", {
  stateManagementUse: true
});

// 設定本体
Amplify.configure({
  // 認証情報
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.VUE_APP_AUTH_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.VUE_APP_AUTH_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: process.env.VUE_APP_AUTH_AUTHENTICATION_FLOW_TYPE,

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.VUE_APP_AUTH_IDENTIFY_POOL_ID,

    // encription storage package instance
    storage: es
  },
  // APIエンドポイントURL
  API: {
    endpoints: [
      {
        name: "getSignedUrl",
        endpoint: process.env.VUE_APP_API_GET_SIGNED_URL
      },
      {
        name: "spitsServer",
        endpoint: process.env.VUE_APP_API_SPITS_SERVER_URL
      },
      {
        name: "spitsServerDev",
        endpoint: process.env.VUE_APP_API_SPITS_SERVER_DEV_URL
      },
      {
        name: "spitsServerLocal",
        endpoint: process.env.VUE_APP_API_SPITS_SERVER_LOCAL_URL
      },
      {
        name: "spitsServerCloud",
        endpoint: process.env.VUE_APP_API_SPITS_SERVER_CLOUD_URL,
        custom_header: async () => {
          return {
            "x-api-key": process.env.VUE_APP_API_SPITS_SERVER_CLOUD_API_KEY
          };
        }
      },
      {
        name: "spitsAdminApiUrl",
        endpoint: process.env.VUE_APP_API_SPITS_ADMIN_URL,
        custom_header: async () => {
          return {
            "x-api-key": process.env.VUE_APP_API_SPITS_ADMIN_API_KEY
          };
        }
      }
    ]
  }
});
