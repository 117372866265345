<template>
  <div>
    <v-card>
      <div class="p-2">
        <v-row>
          <v-col cols="12" class="d-flex">
            <slot name="dateTime" />
          </v-col>

          <v-col cols="12" class="w-100 d-flex space-between">
            <v-col cols="6">
              <slot name="threshold" />
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="4">
              <slot name="button" />
            </v-col>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "Form"
  };
</script>

<style lang="scss" scoped>
  .p-2 {
    padding: 10px;
  }
</style>
