<template>
  <div id="map_area">
    <div id="target_map" />
  </div>
</template>

<script>
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import "leaflet-semicircle";
  import "leaflet-rotatedmarker";
  import { commonFunction, mapFunction } from "@/mixins/utils";

  /**
   * @class
   * @classdesc 集計対象地図
   * @author HBA
   */
  const TargetMap = {
    props: {
      poleData: Object
    },
    data() {
      return {
        setting: {},
        sensorList: [],
        accidentPoint: [],
        map: {}
      };
    },
    mixins: [commonFunction, mapFunction],
    created() {
      this.setting = this.getSetting();
      this.accidentPoint = this.$store.state.selectAccident.latlng;
    },
    mounted() {
      this.setMapData();
    },
    watch: {
      poleData: {
        async handler() {
          this.updateMap();
        },
        deep: true
      }
    },
    methods: {
      /**
       * 地図を表示する
       */
      setMapData() {
        this.sensorList = this.poleData.sensorList;
        let latlng = this.poleData.latlng;
        let zoom = this.setting.virtualMap.zoom.default;
        let zoom_min = this.setting.virtualMap.zoom.min;
        let zoom_max = this.setting.virtualMap.zoom.max;
        let zoom_max_native = this.setting.virtualMap.zoom.maxNative;

        this.map = L.map("target_map", {
          dragging: true,
          touchZoom: true,
          scrollWheelZoom: true,
          doubleClickZoom: true,
          boxZoom: true,
          tap: true,
          keyboard: true,
          zoomControl: true,
          minZoom: zoom_min,
          maxZoom: zoom_max
        }).setView(latlng, zoom);
        L.Icon.Default.mergeOptions(this.getMarkerIcon(false));
        L.control.layers(this.getTileLayer(this.map, zoom_max, zoom_max_native)).addTo(this.map);
        this.addPoleMarker(latlng);
      },
      /**
       * 指定されたポールの場所に地図を動かす
       */
      updateMap() {
        this.map.setView(this.poleData.latlng, this.setting.virtualMap.zoom.default);
        L.Icon.Default.mergeOptions(this.getMarkerIcon(false));
        this.addPoleMarker(this.poleData.latlng);
      },
      /**
       * ポールのある場所にマーカーを追加する
       * @param {Number[]} latlng 緯度経度
       */
      addPoleMarker(latlng) {
        this.poleMarkers = null;
        this.poleMarkers = L.marker(latlng).addTo(this.map);
      }
    }
  };

  export default TargetMap;
</script>

<style>
  #map_area {
    background-color: #eee;
  }

  #target_map {
    height: 300px;
    width: 100%;
    border: 1px solid #999;
    position: relative;
  }
</style>
