<template>
  <div class="login_view">
    <v-card width="30vw" class="mx-auto mt-10">
      <v-card-title>
        <h1 class="display-1">ログイン</h1>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field v-model="userInfo.username" prepend-icon="mdi-account-circle" label="ユーザ名" />
          <v-text-field
            v-model="userInfo.password"
            v-bind:type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            label="パスワード"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="blue-grey" v-on:click="onClickLogin">ログイン</v-btn>
      </v-card-actions>
    </v-card>

    <v-main>
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" />
    <CommonDialog :dialog="dialog" />
    <Loading v-show="isLoading" />
  </div>
</template>

<script>
  import AWS from "aws-sdk";
  import { Auth } from "aws-amplify";
  import router from "@/router";
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import CommonDialog from "@/components/CommonDialog";
  import { commonFunction } from "@/mixins/utils";
  import * as log from "loglevel";

  export default {
    name: "LoginView",
    components: {
      Title,
      CommonDialog,
      Loading
    },
    mixins: [commonFunction],
    data: function() {
      return {
        titleInfo: {
          title: "",
          show: {}
        },
        showPassword: false,
        userInfo: {
          username: "",
          password: ""
        },
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        isLoading: false
      };
    },
    created() {
      // アカウント情報、AWS認証情報など、ローカルストレージ、セッションストレージの内容を消去する
      localStorage.clear();
      sessionStorage.clear();
    },
    mounted() {
      if (this.$store.state.userInfo.user != undefined) {
        this.dialog.title = this.str.dialog.title.authError;
        this.dialog.message = this.str.dialog.message.authErrorSession;
        this.dialog.isDialog = true;
      }
    },
    methods: {
      onClickLogin() {
        if (this.checkLogin()) {
          this.signIn();
        }
      },
      checkLogin() {
        let result = false;
        if (this.userInfo.username === "") {
          this.dialog.title = this.str.dialog.title.authError;
          this.dialog.message = this.str.dialog.message.authErrorUser;
          this.dialog.isDialog = true;
        } else if (this.userInfo.password === "") {
          this.dialog.title = this.str.dialog.title.authError;
          this.dialog.message = this.str.dialog.message.authErrorPassword;
          this.dialog.isDialog = true;
        } else {
          result = true;
        }
        return result;
      },
      async signIn() {
        this.isLoading = true;
        await Auth.signIn(this.userInfo.username, this.userInfo.password)
          .then(user => {
            this.setUserInfo(user);
            this.setCognitoIdentityCredentials(user);
          })
          .catch(err => {
            this.checkLoginError(err);
            this.isLoading = false;
          });
      },
      checkLoginError(error) {
        log.debug("errorCode" + error.code);
        switch (error.code) {
          case "UserNotFoundException":
          case "NotAuthorizedException":
            this.dialog.title = this.str.dialog.title.authError;
            this.dialog.message = this.str.dialog.message.authErrorUserPassword;
            this.dialog.isDialog = true;
            this.isLoading = false;
            break;
          default:
            this.dialog.title = this.str.dialog.title.authError;
            this.dialog.message = this.str.dialog.message.authErrorOther;
            this.dialog.isDialog = true;
            this.isLoading = false;
            break;
        }
      },
      async setCognitoIdentityCredentials(user) {
        await user.getSession(function(err, session) {
          if (session) {
            AWS.config.region = Auth._config.region;
            let identityPoolId = Auth._config.identityPoolId;
            let logins = {};
            const key = "cognito-idp." + Auth._config.region + ".amazonaws.com/" + Auth._config.userPoolId;
            logins[key] = session.getIdToken().getJwtToken();
            const cognitoIdentityParams = {
              IdentityPoolId: identityPoolId,
              Logins: logins
            };
            AWS.config.credentials = new AWS.CognitoIdentityCredentials(cognitoIdentityParams);
            AWS.config.credentials.refresh(err => {
              if (err) {
                log.error("\n" + "refreshing AWS credentials failed. Cognito Identity returns messages as follows: \n" + JSON.stringify(err));
              }
            });
          } else {
            log.error(err);
          }
        });
        this.nextScreen();
      },
      nextScreen() {
        router.push({ path: this.getRouterPath("/monitor/selectarea") });
      }
    }
  };
</script>

<style lang="scss">
  .login_view {
    padding-top: 40px;
  }
</style>
