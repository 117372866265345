import "leaflet/dist/leaflet.css";
import L from "leaflet";

export const poleIcon = L.icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [25, 41],
  shadowSize: [0, 0]
});

export const nearMissIcon = L.icon({
  iconUrl: require("@/assets/icons/blueIcon.png"),
  iconRetinaUrl: require("@/assets/icons/blueIcon.png"),
  iconSize: [10, 10],
  shadowSize: [0, 0]
});

export const accidentIcon = L.icon({
  iconUrl: require("@/assets/icons/accidentIcon.png"),
  iconRetinaUrl: require("@/assets/icons/accidentIcon.png"),
  iconSize: [10, 10],
  shadowSize: [0, 0]
});

export const greenIcon = L.icon({
  iconUrl: require("@/assets/icons/greenIcon.png"),
  iconRetinaUrl: require("@/assets/icons/greenIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const blueIcon = L.icon({
  iconUrl: require("@/assets/icons/blueIcon.png"),
  iconRetinaUrl: require("@/assets/icons/blueIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const redIcon = L.icon({
  iconUrl: require("@/assets/icons/redIcon.png"),
  iconRetinaUrl: require("@/assets/icons/redIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const yellowIcon = L.icon({
  iconUrl: require("@/assets/icons/yellowIcon.png"),
  iconRetinaUrl: require("@/assets/icons/yellowIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const lightgreenIcon = L.icon({
  iconUrl: require("@/assets/icons/lightgreenIcon.png"),
  iconRetinaUrl: require("@/assets/icons/lightgreenIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const lightblueIcon = L.icon({
  iconUrl: require("@/assets/icons/lightblueIcon.png"),
  iconRetinaUrl: require("@/assets/icons/lightblueIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const pinkIcon = L.icon({
  iconUrl: require("@/assets/icons/pinkIcon.png"),
  iconRetinaUrl: require("@/assets/icons/pinkIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const orangeIcon = L.icon({
  iconUrl: require("@/assets/icons/orangeIcon.png"),
  iconRetinaUrl: require("@/assets/icons/orangeIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const wineIcon = L.icon({
  iconUrl: require("@/assets/icons/wineIcon.png"),
  iconRetinaUrl: require("@/assets/icons/wineIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const blackIcon = L.icon({
  iconUrl: require("@/assets/icons/blackIcon.png"),
  iconRetinaUrl: require("@/assets/icons/blackIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const purpleIcon = L.icon({
  iconUrl: require("@/assets/icons/purpleIcon.png"),
  iconRetinaUrl: require("@/assets/icons/purpleIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const greenTIcon = L.icon({
  iconUrl: require("@/assets/icons/greenTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/greenTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const blueTIcon = L.icon({
  iconUrl: require("@/assets/icons/blueTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/blueTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const redTIcon = L.icon({
  iconUrl: require("@/assets/icons/redTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/redTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const yellowTIcon = L.icon({
  iconUrl: require("@/assets/icons/yellowTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/yellowTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const lightgreenTIcon = L.icon({
  iconUrl: require("@/assets/icons/lightgreenTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/lightgreenTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const lightblueTIcon = L.icon({
  iconUrl: require("@/assets/icons/lightblueTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/lightblueTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const pinkTIcon = L.icon({
  iconUrl: require("@/assets/icons/pinkTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/pinkTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const orangeTIcon = L.icon({
  iconUrl: require("@/assets/icons/orangeTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/orangeTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const wineTIcon = L.icon({
  iconUrl: require("@/assets/icons/wineTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/wineTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const blackTIcon = L.icon({
  iconUrl: require("@/assets/icons/blackTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/blackTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const purpleTIcon = L.icon({
  iconUrl: require("@/assets/icons/purpleTIcon.png"),
  iconRetinaUrl: require("@/assets/icons/purpleTIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const greenSIcon = L.icon({
  iconUrl: require("@/assets/icons/greenSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/greenSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const blueSIcon = L.icon({
  iconUrl: require("@/assets/icons/blueSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/blueSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const redSIcon = L.icon({
  iconUrl: require("@/assets/icons/redSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/redSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const yellowSIcon = L.icon({
  iconUrl: require("@/assets/icons/yellowSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/yellowSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const lightgreenSIcon = L.icon({
  iconUrl: require("@/assets/icons/lightgreenSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/lightgreenSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const lightblueSIcon = L.icon({
  iconUrl: require("@/assets/icons/lightblueSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/lightblueSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const pinkSIcon = L.icon({
  iconUrl: require("@/assets/icons/pinkSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/pinkSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const orangeSIcon = L.icon({
  iconUrl: require("@/assets/icons/orangeSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/orangeSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const wineSIcon = L.icon({
  iconUrl: require("@/assets/icons/wineSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/wineSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const blackSIcon = L.icon({
  iconUrl: require("@/assets/icons/blackSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/blackSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});

export const purpleSIcon = L.icon({
  iconUrl: require("@/assets/icons/purpleSIcon.png"),
  iconRetinaUrl: require("@/assets/icons/purpleSIcon.png"),
  iconSize: [20, 20],
  shadowSize: [0, 0]
});
