<template>
  <div id="pole_list_mini">
    <v-data-table light dense hide-default-footer :headers="poleListHeader" :items="poleList" item-key="poleId">
      <template v-slot:item.poleId="{ item }">
        <a @click="setPoleData(item.poleId)">{{ "0x" + item.poleId.toString(16) }}</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  /**
   * @class
   * @classdesc 集計画面用地図
   */
  const PostListMini = {
    data() {
      return {
        poleListHeader: [
          { text: "ポールID", value: "poleId" },
          { text: "ポール名", value: "address" }
        ],
        poleList: []
      };
    },
    created() {
      // ポール一覧を取得する
      this.poleList = this.$store.state.poleList;
    },
    methods: {
      /**
       * 対象のポールを変更したら親コンポーネントにポールIDを伝える
       * @param {Number} poleId ポールID
       */
      setPoleData(poleId) {
        this.$emit("update-pole-data", poleId);
      }
    }
  };

  export default PostListMini;
</script>

<style>
  #pole_list_mini {
    position: relative;
    overflow-y: hidden;
  }
</style>
