export const STORE_ACTIONS = {
  setUserInfo: "setUserInfo",
  setUserMovieToken: "setUserMovieToken",
  getPosition: "getPosition",
  setSelectArea: "setSelectArea",
  setSelectPole: "setSelectPole",
  setSelectAccident: "setSelectAccident",

  setGroupList: "setGroupList",
  setPoleGroupList: "setPoleGroupList",
  setAreaList: "setAreaList",
  setPoleList: "setPoleList",
  setPoleInfo: "setPoleInfo",
  setLedList: "setLedList",
  setAccidentList: "setAccidentList",
  setPosList: "setPosList",
  setCameraList: "setCameraList",

  setAreaPoleList: "setAreaPoleList",

  setNearMisses: "setNearMisses",
  setAccidents: "setAccidents",
  setParking: "setParking",
  setWatching: "setWatching",
  setSumariesDailyList: "setSumariesDailyList",
  setSumariesWeeklyList: "setSumariesWeeklyList",

  setNearMissDetailInfo: "setNearMissDetailInfo",
  setNearMissSummaryList: "setNearMissSummaryList",
  setMacroAnalysisInfo: "setMacroAnalysisInfo",
  setMacroAnalysisDetaiInfo: "setMacroAnalysisDetaiInfo",
  setMacroAnalysisInfoDataForExcel: "setMacroAnalysisInfoDataForExcel",
  setDisplayPoleList: "setDisplayPoleList",
  // 管理API
  setAdminPoleList: "setAdminPoleList",
  updateAdminPoleList: "updateAdminPoleList",
  // 集計グラフ画面用
  dailyNearMissReport: "updateDailyNearMissReportList",
  dailyTrafficReport: "updateDailyTrafficReportList",
  dailyAxlAppReport: "updateDailyAxlApproarchReportList",
  weeklyNearMissReport: "updateWeeklyNearMissReportList",
  weeklyTrafficReport: "updateWeeklyTrafficReportList",
  weeklyAxlAppReport: "updateWeeklyAxlApproarchReportList",
  // 集計画面用
  setPassingObjectsCollectionList: "setPassingObjectsCollectionList",
  setPassingObjectsPoleId: "setPassingObjectsPoleId",
  setPassingObjectsStartDate: "setPassingObjectsStartDate",
  setPassingObjectsEndDate: "setPassingObjectsEndDate",
  setVdiParamList: "setVdiParamList",
  setVdiPoleId: "setVdiPoleId"
};
