<template>
  <div>
    <v-card>
      <div class="p-2">
        <p class="data-and-time">対象日及び時刻</p>
        <v-row>
          <v-col cols="12" class="d-flex pa-10">
            <slot name="dateTime" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="pa-10">
            <slot name="threshold" />
          </v-col>
        </v-row>
        <v-row class="dedup">
          <v-col cols="5" class="pa-2">
            <slot name="deduplication" />
          </v-col>
          <v-col cols="7" class="pa-2">
            <slot name="button" />
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "AccidentInitialForm"
  };
</script>

<style lang="scss" scoped>
  .p-2 {
    padding: 5px;
  }
  .data-and-time {
    color: gray;
    position: absolute;
    margin: 13px;
  }
  .dedup {
    margin: 12px;
  }
</style>
