import AccidentAllIndividualForm from "@/components/molecules/AccidentAllIndividualForm.vue";
import AccidentInitialForm from "@/components/molecules/AccidentInitialForm.vue";
import AccidentTimeIndividualForm from "@/components/molecules/AccidentTimeIndividualForm.vue";
import CheckBoxes from "@/components/molecules/CheckBoxes.vue";
import DataTable from "@/components/molecules/DataTable.vue";
import DatePicker from "@/components/molecules/DatePicker.vue";
import DateTime from "@/components/molecules/DateTime.vue";
import Form from "@/components/molecules/Form.vue";
import TimePicker from "@/components/molecules/TimePicker.vue";

export default [AccidentInitialForm, AccidentAllIndividualForm, AccidentTimeIndividualForm, CheckBoxes, DataTable, DatePicker, DateTime, Form, TimePicker];
