<template>
  <div class="videoContainer">
    <div class="nearMissesVideoinfo">
      <v-card>
        <v-card-title class="nearMissesinfoTitle">ヒヤリハット映像情報</v-card-title>
        <v-card-text>
          <div class="nearMissesinfo">
            <span>ヒヤリハットID: {{ nearMissesinfo.id }}</span>
            <span>発生時刻: {{ nearMissesinfo.nearMissTime }}</span>
            <span>判定エリア: {{ nearMissesinfo.area }}</span>
            <span>車両1 : {{ nearMissesinfo.vehicleId1Kind }} ({{ nearMissesinfo.vehicleId1KindDirection }})</span>
            <span>車両2 : {{ nearMissesinfo.vehicleId2Kind }} ({{ nearMissesinfo.vehicleId2KindDirection }})</span>
            <span>TimeGap: {{ nearMissesinfo.timeGap }}</span>
          </div>
          <div class="playerDiv">
            <video ref="videoPlayer" class="video-js vjs-big-play-centered child"></video>
          </div>
          <div class="closeBtnDiv">
            <v-btn dark class="closeBtn" color="blue-grey" v-on:click="VideoClose">閉じる</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div class="closeOverLay" v-on:click="VideoClose"></div>
  </div>
</template>

<script>
  import videojs from "video.js";

  export default {
    name: "VideoPlayer",
    props: {
      options: {
        type: Object,
        default() {
          return {};
        }
      },
      nearMissesinfo: {
        type: Object,
        default() {
          return {
            id: "",
            area: "",
            vehicleId1Kind: "",
            vehicleId1KindDirection: "",
            vehicleId2Kind: "",
            vehicleId2KindDirection: "",
            nearMissTime: "",
            timeGap: ""
          };
        }
      }
    },
    data() {
      return {
        player: null
      };
    },
    mounted() {
      console.log(this.$refs.videoPlayer);
      console.log(this.options);
      this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {});
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    },
    methods: {
      VideoClose() {
        if (this.player) {
          this.player.dispose();
        }
        this.$emit("parentFunc");
      }
    }
  };
</script>
<style scoped>
  .videoContainer {
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #fefefe;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .child {
    width: 30vw;
    height: 30vw;
  }
  .closeBtnDiv {
    padding: 10px;
  }
  .closeBtn {
    width: 100%;
    padding: 10px;
  }
  .closeOverLay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
  }
  .nearMissesVideoinfo {
    position: absolute;
    top: 3vw;
    background-color: black;
    z-index: 4;
  }
  .nearMissesinfoTitle {
    background-color: #0041c0;
    color: white;
  }
  .nearMissesinfo {
    font-size: 20px;
    text-align: left;
    display: inline-grid;
  }
</style>
