<template>
  <div class="group-setting">
    <div class="group-data">
      <v-card elevation="3">
        <v-card-title class="py-0 card">
          <span>グループ一覧</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="groupSearch" append-icon="mdi-magnify" label="検索" single-line hide-details clearable></v-text-field>
          <v-data-table :headers="groupHeaders" :items="groupItems" :search="groupSearch" :items-per-page="5">
            <template v-slot:item.groupid="{ item }">
              {{ toHexNumber(item.groupid) }}
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon small class="mr-2" @click="onClickUpdateGroup(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-icon small @click="onClickDeleteGroup(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-btn color="primary darken-2" class="create-btn" @click="onClickCreateGroup">グループ作成</v-btn>
      <v-card class="pole-card" elevation="3">
        <v-card-title class="py-0 card">
          <span>ポールのグループ一覧</span>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="poleGroupSearch" append-icon="mdi-magnify" label="検索" single-line hide-details clearable></v-text-field>
          <v-data-table :headers="poleHeaders" :items="poleItems" :search="poleGroupSearch" :items-per-page="5">
            <template v-slot:item.poleid="{ item }">
              {{ toHexNumber(item.poleid) }}
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon small class="mr-2" @click="onClickUpdatePoleGroup(item)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-main>
      <router-view />
    </v-main>
    <ErrorDialog :dialog="errDialog" @close-error-dialog="closeErrorDialog" />
    <Loading v-show="isLoading" />
    <Title :titleInfo="titleInfo" />
    <GroupCreateDialog v-if="isCreateGroupDialog" :dialog="isCreateGroupDialog" @dialogClose="dialogClose" @show-error-dialog="showUpdateResult" />
    <GroupUpdateDialog v-if="isUpdateGroupDialog.isDialog" :dialog="isUpdateGroupDialog" @dialogClose="dialogClose" @show-error-dialog="showUpdateResult" />
    <GroupDeleteDialog v-if="isDeleteGroupDialog.isDialog" :dialog="isDeleteGroupDialog" @dialogClose="dialogClose" @show-error-dialog="showUpdateResult" />
    <PoleGroupUpdateDialog v-if="isUpdatePoleGroupDialog.isDialog" :dialog="isUpdatePoleGroupDialog" @dialogClose="dialogClose" @show-error-dialog="showUpdateResult" />
  </div>
</template>

<script>
  import ErrorDialog from "@/components/ErrorDialog";
  import Loading from "@/components/Loading";
  import Title from "@/components/Title";
  import GroupCreateDialog from "@/components/groupSetting/GroupCreateDialog";
  import GroupUpdateDialog from "@/components/groupSetting/GroupUpdateDialog";
  import GroupDeleteDialog from "@/components/groupSetting/GroupDeleteDialog";
  import PoleGroupUpdateDialog from "@/components/groupSetting/PoleGroupUpdateDialog";
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import mathFunctions from "@/utils/math";

  export default {
    name: "GroupSetting",
    components: {
      ErrorDialog,
      Loading,
      Title,
      GroupCreateDialog,
      GroupUpdateDialog,
      GroupDeleteDialog,
      PoleGroupUpdateDialog
    },
    mixins: [commonFunction, communicationFunction],
    data: function() {
      return {
        isResult: false,
        setting: undefined,
        groupSearch: "",
        poleGroupSearch: "",
        isCreateGroupDialog: false,
        isUpdateGroupDialog: {
          isDialog: false,
          item: undefined
        },
        isDeleteGroupDialog: {
          isDialog: false,
          item: undefined
        },
        isUpdatePoleGroupDialog: {
          isDialog: false,
          item: undefined
        },
        groupHeaders: [
          {
            text: "グループID",
            align: "start",
            sortable: true,
            value: "groupid"
          },
          { text: "グループ名", sortable: true, value: "groupname" },
          {
            text: "編集",
            value: "edit",
            sortable: false
          },
          {
            text: "削除",
            value: "delete",
            sortable: false
          }
        ],
        poleHeaders: [
          {
            text: "ポールID",
            align: "start",
            sortable: true,
            value: "poleid"
          },
          { text: "ポール名", sortable: true, value: "polename" },
          { text: "グループ名", sortable: true, value: "groupname" },
          {
            text: "編集",
            value: "edit",
            sortable: false
          }
        ],
        groupItems: [],
        poleItems: [],
        titleInfo: {
          title: undefined,
          show: {
            menu: true
          },
          menuList: undefined
        },
        errDialog: {
          isDialog: false,
          title: "",
          message: ""
        }
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      ...mathFunctions,
      /**
       * 初期処理
       */
      initialize() {
        this.setting = this.getSetting();
        // 画面タイトルを設定する
        this.titleInfo.title = "グループ設定";
        // 右サイドメニューを取得
        this.titleInfo.menuList = this.setting.menu.poleGroupSetting;
        // 一覧取得
        this.getList();
      },
      /**
       * グループ一覧、ポールのグループ一覧取得処理
       */
      getList() {
        // グループ一覧取得
        this.groupInfoCall();
        // ポールのグループ一覧取得
        this.poleGroupInfoCall();
      },
      /**
       * グループ一覧取得処理
       */
      groupInfoCall() {
        this.isLoading = true;
        let promise = this.getGroupInfoList();
        promise
          .then(() => {
            this.groupItems = this.$store.state.groupList;
            this.isLoading = false;
          })
          .catch(error => {
            this.isLoading = false;
            // エラーメッセージを設定する
            let title = this.str.dialog.title.getError;
            let message = this.str.dialog.message.getErrorGroupInfo;
            if (error == this.errcode.noContent) {
              message = this.str.dialog.message.getErrorGroupNoContent;
            }
            this.showErrorDialog(title, message);
          });
      },
      /**
       * ポールのグループ一覧取得処理
       */
      poleGroupInfoCall() {
        this.isLoading = true;
        let promise = this.getPoleGroupInfoList();
        promise
          .then(() => {
            this.poleItems = this.$store.state.poleGroupList;
            this.isLoading = false;
          })
          .catch(error => {
            this.isLoading = false;
            // エラーメッセージを設定する
            let title = this.str.dialog.title.getError;
            let message = this.str.dialog.message.getErrorPoleGroupInfo;
            if (error == this.errcode.noContent) {
              message = this.str.dialog.message.getErrorPoleGroupNoContent;
            }
            this.showErrorDialog(title, message);
          });
      },
      /**
       * ダイアログ上にエラーメッセージを設定して表示
       * @param {String} title タイトル
       * @param {String} message メッセージ
       */
      showErrorDialog(title, message) {
        this.errDialog.title = title;
        this.errDialog.message = message;
        this.errDialog.isDialog = true;
      },
      /**
       * グループ作成処理
       */
      onClickCreateGroup() {
        this.isCreateGroupDialog = true;
      },
      /**
       * グループ更新処理
       * @param {Object} item グループ情報
       */
      onClickUpdateGroup(item) {
        this.isUpdateGroupDialog.isDialog = true;
        this.isUpdateGroupDialog.item = item;
      },
      /**
       * グループ削除処理
       * @param {Object} item グループ情報
       */
      onClickDeleteGroup(item) {
        this.isDeleteGroupDialog.isDialog = true;
        this.isDeleteGroupDialog.item = item;
      },
      /**
       * ポールのグループ更新処理
       * @param {Object} item ポールのグループ情報
       */
      onClickUpdatePoleGroup(item) {
        this.isUpdatePoleGroupDialog.isDialog = true;
        this.isUpdatePoleGroupDialog.item = item;
      },
      /**
       * 作成、更新、削除処理結果をダイアログで表示させる
       * @param result 処理結果
       * @param title ダイアログタイトル
       * @param message ダイアログメッセージ
       */
      showUpdateResult(result, title, message) {
        this.isResult = result;
        this.showErrorDialog(title, message);
      },
      /**
       * エラーダイアログを閉じたときの処理
       */
      closeErrorDialog() {
        this.errDialog.isDialog = false;
        if (this.isResult) {
          this.successClose();
        } else {
          this.dialogClose();
        }
      },
      /**
       * ダイアログ閉じる処理
       */
      dialogClose() {
        this.resetData();
      },
      /**
       * API呼び出し処理成功した後ダイアログ閉じて一覧を再取得処理
       */
      successClose() {
        this.resetData();
        // 一覧更新
        this.getList();
      },
      /**
       * リセット処理
       */
      resetData() {
        this.isCreateGroupDialog = false;
        this.isUpdateGroupDialog.isDialog = false;
        this.isDeleteGroupDialog.isDialog = false;
        this.isUpdatePoleGroupDialog.isDialog = false;
      }
    }
  };
</script>

<style scoped>
  .card {
    font-size: 22px;
    font-weight: bold;
    color: white;
    background-color: #0041c0;
    height: 3vh;
  }
  .group-data {
    width: 80%;
    margin: auto;
    padding-top: 30px;
  }
  .create-btn {
    position: absolute;
    right: 10%;
    margin-top: 10px;
    width: 10%;
  }
  .pole-card {
    margin-top: 10%;
  }
</style>
