<template>
  <div>
    <div>
      <Loading v-show="isLoading" />
      <v-row class="ml-2 mr-2">
        <v-col cols="12">
          <Bar :Title="pageTitle" />
        </v-col>
      </v-row>
      <v-row class="ml-2 mr-2">
        <v-col cols="12">
          <Bar Title="デイリーレポート">
            <ValidationObserver ref="form" v-slot="ObserverProps">
              <div class="d-flex pt-10 ml-5">
                <DatePicker :value="searchConditions.fromDailyDate" @input="searchConditions.fromDailyDate = $event" :disabledDatePicker="disabledDatePicker" />

                <div v-if="summariesForm" class="d-flex">
                  <ValidationProvider rules="double|required" v-slot="ProviderProps" name="閾値">
                    <p class="mx-3">
                      <TextField :value="searchConditions.dailyNmaThr" @input="searchConditions.dailyNmaThr = $event" label="ヒヤリハット閾値" />
                    </p>
                    <p class="formatError">{{ ProviderProps.errors[0] }}</p>
                  </ValidationProvider>
                  <ValidationProvider rules="double|required" v-slot="ProviderProps" name="閾値">
                    <p class="mx-3">
                      <TextField :value="searchConditions.dailyParkedThr" @input="searchConditions.dailyParkedThr = $event" label="駐停車状況閾値" />
                    </p>
                    <p class="formatError">{{ ProviderProps.errors[0] }}</p>
                  </ValidationProvider>
                </div>

                <div v-if="showButton">
                  <Button v-bind:disabled="isProcessingDaily()" label="決定" @click="setDailyChart((init = false), ObserverProps)" />
                </div>
              </div>
            </ValidationObserver>
          </Bar>
        </v-col>

        <v-col cols="5">
          <Table
            :trafficTotal="editingData.daily_traffic.currentTotal"
            :nearMissesTotal="editingData.daily_nearMisses.currentTotal"
            :accelerationMaxAverage="editingData.daily_AverageData.currentMaxAverage"
            :topAcceleration="editingData.daily_topAcceleration.currentTopAcceleration"
            :parkingTotal="editingData.daily_parkingData.currentTotal"
            :diff_trafficTotal="editingData.daily_diff_traffic"
            :diff_nearMissesTotal="editingData.daily_diff_nearMisses"
            :diff_accelerationMaxAverage="editingData.daily_diff_AverageData"
            :diff_topAcceleration="editingData.daily_diff_topAcceleration"
            :diff_parkingTotal="editingData.daily_diff_parkingData"
            :parkedThr="editingData.select_dailyParkedThr"
          />
        </v-col>

        <v-col cols="7">
          <div class="d-flex">
            <DailyChart
              v-if="isDailyChart"
              :currentData="editingData.daily_traffic.current"
              :prevData="editingData.daily_traffic.prev"
              v-on:showModal="showModal"
              label="走行台数"
              ylabelTitle="台数"
              class="col col-4 chart"
            />
            <DailyChart
              v-if="isDailyChart"
              :currentData="editingData.daily_nearMisses.current"
              :prevData="editingData.daily_nearMisses.prev"
              v-on:showModal="showModal"
              label="ヒヤリハット"
              ylabelTitle="台数"
              class="col col-4 chart"
            />
            <DailyHistogram
              v-if="isDailyChart"
              :currentData="editingData.daily_accelerationApproachData.current"
              :prevData="editingData.daily_accelerationApproachData.prev"
              v-on:showDailyModalHist="showDailyModalHist"
              label="交差点進入時速度ヒストグラム"
              class="col col-4 chart"
            />
          </div>

          <div class="d-flex">
            <DailyChart
              v-if="isDailyChart"
              :currentData="editingData.daily_AverageData.currentAverage"
              :prevData="editingData.daily_AverageData.prevAverage"
              v-on:showModal="showModal"
              label="交差点進入時の速度[平均]"
              ylabelTitle="平均速度[km/h]"
              class="col col-4 chart"
            />
            <DailyChart
              v-if="isDailyChart"
              :currentData="editingData.daily_topAcceleration.currentMaxAcceleration"
              :prevData="editingData.daily_topAcceleration.prevMaxAcceleration"
              v-on:showModal="showModal"
              label="交差点進入時の速度[最大]"
              ylabelTitle="最大速度[km/h]"
              class="col col-4 chart"
            />
            <DailyChart
              v-if="isDailyChart"
              :currentData="editingData.daily_parkingData.current"
              :prevData="editingData.daily_parkingData.prev"
              v-on:showModal="showModal"
              :label="editingData.select_dailyParkedThr + '分以上の駐停車車両'"
              ylabelTitle="台数"
              class="col col-4 chart"
            />
          </div>
        </v-col>
      </v-row>

      <v-row class="ml-2 mr-2">
        <v-col cols="12">
          <Bar Title="ウィークリーレポート">
            <ValidationObserver ref="form" v-slot="ObserverProps">
              <div class="d-flex pt-10 ml-5">
                <DatePicker :value="searchConditions.toWeeklyDate" @input="setToWeeklyDate" :disabledDatePicker="disabledDatePicker" />

                <p class="pt-1 px-2" :class="addStyle" style="color: black">～</p>

                <DatePicker :value="searchConditions.fromWeeklyDate" @input="setFromWeeklyDate" :disabledDatePicker="disabledDatePicker" />

                <div v-if="summariesForm" class="d-flex">
                  <ValidationProvider rules="double|required" v-slot="ProviderProps" name="閾値">
                    <p class="mx-3">
                      <TextField :value="searchConditions.weeklyNmaThr" @input="searchConditions.weeklyNmaThr = $event" label="ヒヤリハット閾値" textFieldclass="textField" />
                    </p>
                    <p class="formatError">{{ ProviderProps.errors[0] }}</p>
                  </ValidationProvider>

                  <ValidationProvider rules="double|required" v-slot="ProviderProps" name="閾値">
                    <p class="mx-3">
                      <TextField :value="searchConditions.weeklyParkedThr" @input="searchConditions.weeklyParkedThr = $event" label="駐停車状況閾値" textFieldclass="textField" />
                    </p>
                    <p class="formatError">{{ ProviderProps.errors[0] }}</p>
                  </ValidationProvider>
                </div>
                <div v-if="showButton">
                  <Button v-bind:disabled="isProcessingWeekly()" label="決定" @click="setWeeklyChart((init = false), ObserverProps)" />
                </div>
              </div>
            </ValidationObserver>
          </Bar>
        </v-col>
        <v-col cols="5">
          <Table
            :trafficTotal="editingData.weekly_traffic.currentTotal"
            :nearMissesTotal="editingData.weekly_nearMisses.currentTotal"
            :accelerationMaxAverage="editingData.weekly_AverageData.currentMaxAverage"
            :topAcceleration="editingData.weekly_topAcceleration.currentTopAcceleration"
            :parkingTotal="editingData.weekly_parkingData.currentTotal"
            :diff_trafficTotal="editingData.weekly_diff_traffic"
            :diff_nearMissesTotal="editingData.weekly_diff_nearMisses"
            :diff_accelerationMaxAverage="editingData.weekly_diff_AverageData"
            :diff_topAcceleration="editingData.weekly_diff_topAcceleration"
            :diff_parkingTotal="editingData.weekly_diff_parkingData"
            :parkedThr="editingData.select_weeklyParkedThr"
          />
        </v-col>

        <v-col cols="7">
          <div class="d-flex">
            <WeeklyChart
              v-if="isWeeklyChart"
              :currentData="editingData.weekly_traffic.current"
              :prevData="editingData.weekly_traffic.prev"
              v-on:showModal="showModal"
              :labels="editingData.weekly_tabel_label_list"
              label="走行台数"
              ylabelTitle="台数"
              class="col col-4 chart"
            />
            <WeeklyChart
              v-if="isWeeklyChart"
              :currentData="editingData.weekly_nearMisses.current"
              :prevData="editingData.weekly_nearMisses.prev"
              :labels="editingData.weekly_tabel_label_list"
              v-on:showModal="showModal"
              label="ヒヤリハット"
              ylabelTitle="台数"
              class="col col-4 chart"
            />
            <WeeklyHistogram
              v-if="isWeeklyChart"
              :currentData="editingData.weekly_accelerationApproachData.current"
              :prevData="editingData.weekly_accelerationApproachData.prev"
              v-on:showWeeklyModalHist="showWeeklyModalHist"
              :selectToWeeklydateTime="this.toWeeklydateTime"
              label="交差点進入時速度ヒストグラム"
              class="col col-4 chart"
            />
          </div>

          <div class="d-flex">
            <WeeklyChart
              v-if="isWeeklyChart"
              :currentData="editingData.weekly_AverageData.currentAverage"
              :prevData="editingData.weekly_AverageData.prevAverage"
              :labels="editingData.weekly_tabel_label_list"
              v-on:showModal="showModal"
              label="交差点進入時の速度[平均]"
              ylabelTitle="平均速度[km/h]"
              class="col col-4 chart"
            />
            <WeeklyChart
              v-if="isWeeklyChart"
              :currentData="editingData.weekly_topAcceleration.currentMaxAcceleration"
              :prevData="editingData.weekly_topAcceleration.prevMaxAcceleration"
              :labels="editingData.weekly_tabel_label_list"
              v-on:showModal="showModal"
              label="交差点進入時の速度[最大]"
              ylabelTitle="最大速度[km/h]"
              class="col col-4 chart"
            />
            <WeeklyChart
              v-if="isWeeklyChart"
              :currentData="editingData.weekly_parkingData.current"
              :prevData="editingData.weekly_parkingData.prev"
              v-on:showModal="showModal"
              :labels="editingData.weekly_tabel_label_list"
              :label="editingData.select_weeklyParkedThr + '分以上の駐停車車両'"
              ylabelTitle="台数"
              class="col col-4 chart"
            />
          </div>
        </v-col>
      </v-row>

      <ModalDisplayComponent :value="isDisplay" @input="isDisplay = $event" text="errorText" :prefix="prefix" />
      <ModalDisplayDailyHistogram :value="isDailyModalHist" @input="isDailyModalHist = $event" text="errorText" :prefix="prefix" />
      <ModalDisplayWeeklyHistogram :value="isWeeklyModalHist" @input="isWeeklyModalHist = $event" text="errorText" :prefix="prefix" />

      <Modal :value="error" @input="error = $event" :text="errorText" />
      <Title :titleInfo="titleInfo" :inScreenTransition="true" />
    </div>
  </div>
</template>

<script>
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import { getDate, formatDate } from "@/utils/day.js";
  import { commonFunction, communicationFunction } from "@/mixins/utils";

  export default {
    name: "Summary",
    components: {
      Title,
      Loading
    },
    mixins: [commonFunction, communicationFunction],
    props: {
      pageTitle: {
        type: String,
        default: "サマリー"
      },
      summariesForm: {
        type: Boolean,
        default: true
      },
      showButton: {
        type: Boolean,
        default: true
      },
      disabledDatePicker: {
        type: Boolean,
        default: false
      },
      disabledTimePicker: {
        type: Boolean,
        default: false
      },
      addStyle: {
        type: String,
        require: false
      }
    },
    data() {
      return {
        poleId: this.$store.state.selectPole.poleId,
        init: false,
        isDailyChart: false,
        isWeeklyChart: false,
        isLoading: false,
        isDisplay: false,
        isDailyModalHist: false,
        isWeeklyModalHist: false,
        prefix: {
          component: null,
          props: {}
        },
        titleInfo: {
          title: "",
          show: {
            realtime: true,
            multicamera: true,
            virtual: true,
            menu: true
          },
          menuList: []
        },

        searchConditions: {
          fromDailyDate: "",
          fromDailyTime: "",
          fromWeeklyDate: "",
          fromWeeklyTime: "",
          toWeeklyDate: "",
          toWeeklydateTime: "",
          toDailydateTime: "",
          dailyNmaThr: 0,
          dailyParkedThr: 5,
          weeklyNmaThr: 0,
          weeklyParkedThr: 5
        },

        editingData: {
          dailyData: {},

          daily_traffic: {},
          daily_nearMisses: {},
          daily_accelerationApproachData: {},
          daily_AverageData: {},
          daily_topAcceleration: {},
          daily_parkingData: {},

          daily_diff_traffic: 0,
          daily_diff_nearMisses: 0,
          daily_diff_AverageData: 0,
          daily_diff_topAcceleration: 0,
          daily_diff_parkingData: 0,
          select_dailyParkedThr: 5,

          weeklyData: {},

          weekly_traffic: {},
          weekly_nearMisses: {},
          weekly_accelerationApproachData: {},
          weekly_AverageData: {},
          weekly_topAcceleration: {},
          weekly_parkingData: {},

          weekly_diff_traffic: 0,
          weekly_diff_nearMisses: 0,
          weekly_diff_AverageData: 0,
          weekly_diff_topAcceleration: 0,
          weekly_diff_parkingData: 0,
          select_weeklyParkedThr: 5,
          weekly_tabel_label_list: []
        },
        error: false,
        errorText: "データ"
      };
    },

    created() {
      this.initialize();
      const formDate = getDate();
      this.searchConditions.fromDailyDate = formDate[0];
      this.searchConditions.fromDailyTime = formDate[1];

      this.searchConditions.fromWeeklyDate = formDate[0];
      this.searchConditions.fromWeeklyTime = formDate[1];

      this.setDailyChart((this.init = true));
      this.setWeeklyChart((this.init = true));
      this.setFromWeeklyDate(this.searchConditions.fromWeeklyDate);
    },

    computed: {
      toWeeklydateTime: {
        get() {
          let targetDate = new Date(this.searchConditions.fromWeeklyDate);
          targetDate.setDate(targetDate.getDate() - 6);

          const weeklyDate = formatDate(targetDate, "YYYY-MM-DD");
          const weeklyDateTime = weeklyDate;
          return weeklyDateTime;
        },
        set() {
          return this.searchConditions.toWeeklydateTime;
        }
      },
      dailyLabels() {
        let label = {};
        for (let i = 0; i < 12; i++) {
          let key = String(i);
          label[key] = String(i * 2);
        }
        return label;
      },
      weeklyLabels() {
        let label = {};
        let date = new Date(this.searchConditions.fromWeeklyDate);
        label[0] = formatDate(date, "YYYY/MM/DD");
        for (let i = 1; i < 7; i++) {
          label[i] = formatDate(date.setDate(date.getDate() + 1), "YYYY/MM/DD");
        }
        return label;
      }
    },

    methods: {
      initialize() {
        this.setting = this.getSetting();
        this.titleInfo.menuList = this.setting.menu.summally;
        this.isProcessingDaily();
        this.isProcessingWeekly();
      },
      isProcessingDaily: function() {
        let isProcessingDaily = false;
        let dailyNmaThr = Number(this.searchConditions.dailyNmaThr);
        let dailyParkedThr = Number(this.searchConditions.dailyParkedThr);
        if (this.searchConditions.dailyNmaThr === "" || this.searchConditions.dailyParkedThr === "" || Number.isNaN(dailyNmaThr) || Number.isNaN(dailyParkedThr)) {
          isProcessingDaily = true;
        }

        return isProcessingDaily;
      },
      isProcessingWeekly: function() {
        let isProcessingWeekly = false;
        let weeklyNmaThr = Number(this.searchConditions.weeklyNmaThr);
        let weeklyParkedThr = Number(this.searchConditions.weeklyParkedThr);
        if (this.searchConditions.weeklyNmaThr === "" || this.searchConditions.weeklyParkedThr === "" || Number.isNaN(weeklyNmaThr) || Number.isNaN(weeklyParkedThr)) {
          isProcessingWeekly = true;
        }
        return isProcessingWeekly;
      },
      getDayOfWeek(weekly_tabel_label_list) {
        let dt = new Date(this.toWeeklydateTime);
        let week = {
          0: "日",
          1: "月",
          2: "火",
          3: "水",
          4: "木",
          5: "金",
          6: "土"
        };
        weekly_tabel_label_list.length = 0;
        let index = 0;
        for (let step = 0; step < 7; step++) {
          if (step == 0) {
            index = dt.getDay();
          } else {
            dt.setDate(dt.getDate() + 1);
            index = dt.getDay();
          }
          weekly_tabel_label_list.push(week[index]);
        }
      },
      async setDailyChart(init, ObserverProps) {
        if (!init) {
          const valid = await ObserverProps.validate();
          if (!valid) return;
        }
        const SummariesDate = this.searchConditions.fromDailyDate + " " + "00:00:00";
        this.isLoading = true;
        let promise = this.updateSumariesDailyData(
          this.poleId,
          SummariesDate.replaceAll("-", "/"),
          Number(this.searchConditions.dailyNmaThr),
          Number(this.searchConditions.dailyParkedThr)
        );
        promise
          .then(() => {
            this.isLoading = false;
            if (this.$store.state.summariesDailyList) {
              this.editingData.dailyData = this.$store.state.summariesDailyList;
              if (this.editingData.dailyData !== null) {
                this.isDailyChart = false;
                this.setDailyChartData(this.editingData.dailyData);
              } else {
                this.error = true;
              }
            }
          })
          .then(() => {
            this.isDailyChart = true;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },

      checkCurrentNull(chartData) {
        if (chartData.traffic[0].current == null) {
          chartData.traffic[0].current = [];
        } else {
          this.checkListDataNull(chartData.traffic[0].current);
        }

        if (chartData.traffic[0].current === null) {
          chartData.nearMisses[0].current = [];
        } else {
          this.checkListDataNull(chartData.nearMisses[0].current);
        }

        if (chartData.accelerationApproachData[0].current == null) {
          chartData.accelerationApproachData[0].current = [];
        } else {
          this.checkListDataNull(chartData.accelerationApproachData[0].current);
        }

        if (chartData.accelerationApproachAverageData[0].currentAverage == null) {
          chartData.accelerationApproachAverageData[0].currentAverage = [];
        } else {
          this.checkListDataNull(chartData.accelerationApproachAverageData[0].currentAverage);
        }

        if (chartData.accelerationApproachMaxData[0].currentMaxAcceleration == null) {
          chartData.accelerationApproachMaxData[0].currentMaxAcceleration = [];
        } else {
          this.checkListDataNull(chartData.accelerationApproachMaxData[0].currentMaxAcceleration);
        }

        if (chartData.parkingData[0].current == null) {
          chartData.parkingData[0].current = [];
        } else {
          this.checkListDataNull(chartData.parkingData[0].current);
        }
      },

      checkPrevNull(chartData) {
        if (chartData.traffic[0].prev == null) {
          chartData.traffic[0].prev = [];
        } else {
          this.checkListDataNull(chartData.traffic[0].prev);
        }

        if (chartData.traffic[0].prev === null) {
          chartData.nearMisses[0].prev = [];
        } else {
          this.checkListDataNull(chartData.nearMisses[0].prev);
        }

        if (chartData.accelerationApproachAverageData[0].prevAverage == null) {
          chartData.accelerationApproachAverageData[0].prevAverage = [];
        } else {
          this.checkListDataNull(chartData.accelerationApproachAverageData[0].prevAverage);
        }

        if (chartData.accelerationApproachMaxData[0].prevMaxAcceleration == null) {
          chartData.accelerationApproachMaxData[0].prevMaxAcceleration = [];
        } else {
          this.checkListDataNull(chartData.accelerationApproachMaxData[0].prevMaxAcceleration);
        }

        if (chartData.parkingData[0].prev == null) {
          chartData.parkingData[0].prev = [];
        } else {
          this.checkListDataNull(chartData.parkingData[0].prev);
        }
      },

      checkListDataNull(target) {
        let currentData = target;
        for (let i = 0; i <= currentData.length; i++) {
          if (currentData[i] === null) {
            currentData[i] = 0;
          }
        }
      },

      async setDailyChartData(dailyData) {
        await this.checkCurrentNull(dailyData);
        await this.checkPrevNull(dailyData);

        this.editingData.daily_traffic = dailyData.traffic[0];
        this.editingData.daily_nearMisses = dailyData.nearMisses[0];
        this.editingData.daily_accelerationApproachData = this.setAccelerationApproachData(dailyData.accelerationApproachData[0], this.dailyLabels);
        this.editingData.daily_AverageData = dailyData.accelerationApproachAverageData[0];
        this.editingData.daily_topAcceleration = dailyData.accelerationApproachMaxData[0];
        this.editingData.daily_parkingData = dailyData.parkingData[0];

        this.editingData.daily_diff_traffic = this.editingData.daily_traffic.currentTotal - dailyData.traffic[0].prevTotal;
        this.editingData.daily_diff_nearMisses = this.editingData.daily_nearMisses.currentTotal - dailyData.nearMisses[0].prevTotal;
        this.editingData.daily_diff_AverageData = this.editingData.daily_AverageData.currentMaxAverage - dailyData.accelerationApproachAverageData[0].prevMaxAverage;
        this.editingData.daily_diff_topAcceleration = this.editingData.daily_topAcceleration.currentTopAcceleration - dailyData.accelerationApproachMaxData[0].prevTopAcceleration;
        this.editingData.daily_diff_parkingData = this.editingData.daily_parkingData.currentTotal - dailyData.parkingData[0].prevTotal;

        this.editingData.select_dailyParkedThr = this.searchConditions.dailyParkedThr;
      },

      async setWeeklyChart(init, ObserverProps) {
        if (!init) {
          const valid = await ObserverProps.validate();
          if (!valid) return;
        }

        const SummariesDate = this.toWeeklydateTime + " " + "00:00:00";
        this.isLoading = true;
        let promise = this.updateSumariesWeeklyData(
          this.poleId,
          SummariesDate.replaceAll("-", "/"),
          Number(this.searchConditions.weeklyNmaThr),
          Number(this.searchConditions.weeklyParkedThr)
        );
        promise
          .then(() => {
            this.isLoading = false;
            if (this.$store.state.summariesWeeklyList) {
              this.editingData.weeklyData = this.$store.state.summariesWeeklyList;
              if (this.editingData.weeklyData !== null) {
                this.isWeeklyChart = false;
                this.setWeeklyChartData(this.editingData.weeklyData);
              } else {
                this.error = true;
              }
            }
          })
          .then(() => {
            this.isWeeklyChart = true;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },

      async setWeeklyChartData(weeklyData) {
        await this.checkCurrentNull(weeklyData);
        await this.checkPrevNull(weeklyData);

        this.editingData.weekly_traffic = weeklyData.traffic[0];
        this.editingData.weekly_nearMisses = weeklyData.nearMisses[0];
        this.editingData.weekly_accelerationApproachData = this.setAccelerationApproachData(weeklyData.accelerationApproachData[0], this.weeklyLabels);
        this.editingData.weekly_AverageData = weeklyData.accelerationApproachAverageData[0];
        this.editingData.weekly_topAcceleration = weeklyData.accelerationApproachMaxData[0];
        this.editingData.weekly_parkingData = weeklyData.parkingData[0];

        this.editingData.weekly_diff_traffic = this.editingData.weekly_traffic.currentTotal - weeklyData.traffic[0].prevTotal;
        this.editingData.weekly_diff_nearMisses = this.editingData.weekly_nearMisses.currentTotal - weeklyData.nearMisses[0].prevTotal;
        this.editingData.weekly_diff_AverageData = this.editingData.weekly_AverageData.currentMaxAverage - weeklyData.accelerationApproachAverageData[0].prevMaxAverage;
        this.editingData.weekly_diff_topAcceleration =
          this.editingData.weekly_topAcceleration.currentTopAcceleration - weeklyData.accelerationApproachMaxData[0].prevTopAcceleration;
        this.editingData.weekly_diff_parkingData = this.editingData.weekly_parkingData.currentTotal - weeklyData.parkingData[0].prevTotal;

        this.editingData.select_weeklyParkedThr = this.searchConditions.weeklyParkedThr;
        this.getDayOfWeek(this.editingData.weekly_tabel_label_list);
      },

      setAccelerationApproachData(data, labels) {
        let current = [];
        let prev = [];
        Object.keys(labels).forEach(function(key) {
          Object.keys(this.getSummarySpeed()).forEach(function(a) {
            if (typeof data.current[key] !== "undefined") {
              current = current.concat(data.current[key][a] ? data.current[key][a] : 0);
              prev = prev.concat(data.prev[key][a] ? data.prev[key][a] : 0);
            }
          }, this);
        }, this);
        return { current: current, prev: prev };
      },

      showModal(component, props) {
        this.prefix.component = component;
        this.prefix.props = props;

        this.isDisplay = true;
      },
      showDailyModalHist(component, props0, props2, props4, props6, props8, props10, props12, props14, props16, props18, props20, props22) {
        this.prefix.component = component;
        this.prefix.props0 = props0;
        this.prefix.props2 = props2;
        this.prefix.props4 = props4;
        this.prefix.props6 = props6;
        this.prefix.props8 = props8;
        this.prefix.props10 = props10;
        this.prefix.props12 = props12;
        this.prefix.props14 = props14;
        this.prefix.props16 = props16;
        this.prefix.props18 = props18;
        this.prefix.props20 = props20;
        this.prefix.props22 = props22;
        this.isDailyModalHist = true;
      },
      showWeeklyModalHist(component, props0, props1, props2, props3, props4, props5, props6) {
        this.prefix.component = component;
        this.prefix.props0 = props0;
        this.prefix.props1 = props1;
        this.prefix.props2 = props2;
        this.prefix.props3 = props3;
        this.prefix.props4 = props4;
        this.prefix.props5 = props5;
        this.prefix.props6 = props6;
        this.isWeeklyModalHist = true;
      },

      setToWeeklyDate(date) {
        this.searchConditions.toWeeklyDate = date;
        let targetDate = new Date(formatDate(this.searchConditions.toWeeklyDate, "YYYY/MM/DD HH:mm:ss"));
        targetDate.setDate(targetDate.getDate() + 6);

        const weeklyDate = formatDate(targetDate, "YYYY-MM-DD");
        this.searchConditions.fromWeeklyDate = weeklyDate;
      },

      setFromWeeklyDate(date) {
        this.searchConditions.fromWeeklyDate = date;
        let targetDate = new Date(formatDate(this.searchConditions.fromWeeklyDate, "YYYY/MM/DD HH:mm:ss"));
        targetDate.setDate(targetDate.getDate() - 6);

        const weeklyDate = formatDate(targetDate, "YYYY-MM-DD");
        this.searchConditions.toWeeklyDate = weeklyDate;
      }
    }
  };
</script>

<style>
  .chart {
    height: 100%;
  }

  .formatError {
    color: red;
    position: absolute;
    bottom: -18px;
    font-size: 10px;
  }

  .textField {
    width: 7rem;
  }
</style>
