<template>
  <div class="divVCard1">
    <Loading v-show="isLoading" />
    <v-card class="vCardTabel2">
      <table>
        <thead>
          <tr>
            <th>区間</th>
            <th colspan="2">
              進入路
              <v-icon large color="white" @click="viewSectionDetail('enter')"> mdi-table-arrow-up </v-icon>
            </th>
            <th colspan="2">
              停止線
              <v-icon large color="white" @click="viewSectionDetail('stopLine')"> mdi-table-arrow-up </v-icon>
            </th>
            <th colspan="2">
              停止線~心的停止線
              <v-icon large color="white" @click="viewSectionDetail('stopLineMentalStopLine')"> mdi-table-arrow-up </v-icon>
            </th>
            <th colspan="2">
              心的停止線
              <v-icon large color="white" @click="viewSectionDetail('mentalStopLine')"> mdi-table-arrow-up </v-icon>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="empty"></td>
            <!-- 進入路 -->
            <td class="periodA">期間A</td>
            <td class="periodB">期間B</td>
            <!-- 停止線 -->
            <td class="periodA">期間A</td>
            <td class="periodB">期間B</td>
            <!-- 停止線~心的停止線 -->
            <td class="periodA">期間A</td>
            <td class="periodB">期間B</td>
            <!-- 心的停止線 -->
            <td class="periodA">期間A</td>
            <td class="periodB">期間B</td>
          </tr>
          <tr>
            <td>走行台数[台]</td>
            <!-- 進入路 -->
            <td class="periodA">{{ termA.enter.number }}</td>
            <td class="periodB">{{ termB.enter.number }}</td>
            <!-- 停止線 -->
            <td class="periodA">{{ termA.stopLine.number }}</td>
            <td class="periodB">{{ termB.stopLine.number }}</td>
            <!-- 停止線~心的停止線 -->
            <td class="periodA">{{ termA.stopLineMentalStopLine.number }}</td>
            <td class="periodB">{{ termB.stopLineMentalStopLine.number }}</td>
            <!-- 心的停止線 -->
            <td class="periodA">{{ termA.mentalStopLine.number }}</td>
            <td class="periodB">{{ termB.mentalStopLine.number }}</td>
          </tr>
          <tr>
            <td>平均速度[km/h]</td>
            <!-- 進入路 -->
            <td class="periodA">{{ termA.enter.avgSpeed }}</td>
            <td class="periodB">{{ termB.enter.avgSpeed }}</td>
            <!-- 停止線 -->
            <td class="periodA">{{ termA.stopLine.avgSpeed }}</td>
            <td class="periodB">{{ termB.stopLine.avgSpeed }}</td>
            <!-- 停止線~心的停止線 -->
            <td class="periodA">{{ termA.stopLineMentalStopLine.avgSpeed }}</td>
            <td class="periodB">{{ termB.stopLineMentalStopLine.avgSpeed }}</td>
            <!-- 心的停止線 -->
            <td class="periodA">{{ termA.mentalStopLine.avgSpeed }}</td>
            <td class="periodB">{{ termB.mentalStopLine.avgSpeed }}</td>
          </tr>
          <tr>
            <td>最高速度[km/h]</td>
            <!-- 進入路 -->
            <td class="periodA">{{ termA.enter.maxSpeed }}</td>
            <td class="periodB">{{ termB.enter.maxSpeed }}</td>
            <!-- 停止線 -->
            <td class="periodA">{{ termA.stopLine.maxSpeed }}</td>
            <td class="periodB">{{ termB.stopLine.maxSpeed }}</td>
            <!-- 停止線~心的停止線 -->
            <td class="periodA">{{ termA.stopLineMentalStopLine.maxSpeed }}</td>
            <td class="periodB">{{ termB.stopLineMentalStopLine.maxSpeed }}</td>
            <!-- 心的停止線 -->
            <td class="periodA">{{ termA.mentalStopLine.maxSpeed }}</td>
            <td class="periodB">{{ termB.mentalStopLine.maxSpeed }}</td>
          </tr>
          <tr>
            <td>最頻値</td>
            <!-- 進入路 -->
            <td class="periodA">{{ termA.enter.mode }}</td>
            <td class="periodB">{{ termB.enter.mode }}</td>
            <!-- 停止線 -->
            <td class="periodA">{{ termA.stopLine.mode }}</td>
            <td class="periodB">{{ termB.stopLine.mode }}</td>
            <!-- 停止線~心的停止線 -->
            <td class="periodA">{{ termA.stopLineMentalStopLine.mode }}</td>
            <td class="periodB">{{ termB.stopLineMentalStopLine.mode }}</td>
            <!-- 心的停止線 -->
            <td class="periodA">{{ termA.mentalStopLine.mode }}</td>
            <td class="periodB">{{ termB.mentalStopLine.mode }}</td>
          </tr>
          <tr>
            <td>中央値</td>
            <!-- 進入路 -->
            <td class="periodA">{{ termA.enter.median }}</td>
            <td class="periodB">{{ termB.enter.median }}</td>
            <!-- 停止線 -->
            <td class="periodA">{{ termA.stopLine.median }}</td>
            <td class="periodB">{{ termB.stopLine.median }}</td>
            <!-- 停止線~心的停止線 -->
            <td class="periodA">{{ termA.stopLineMentalStopLine.median }}</td>
            <td class="periodB">{{ termB.stopLineMentalStopLine.median }}</td>
            <!-- 心的停止線 -->
            <td class="periodA">{{ termA.mentalStopLine.median }}</td>
            <td class="periodB">{{ termB.mentalStopLine.median }}</td>
          </tr>
          <tr>
            <td>標準偏差[km/h]</td>
            <!-- 進入路 -->
            <td class="periodA">{{ termA.enter.std }}</td>
            <td class="periodB">{{ termB.enter.std }}</td>
            <!-- 停止線 -->
            <td class="periodA">{{ termA.stopLine.std }}</td>
            <td class="periodB">{{ termB.stopLine.std }}</td>
            <!-- 停止線~心的停止線 -->
            <td class="periodA">{{ termA.stopLineMentalStopLine.std }}</td>
            <td class="periodB">{{ termB.stopLineMentalStopLine.std }}</td>
            <!-- 心的停止線 -->
            <td class="periodA">{{ termA.mentalStopLine.std }}</td>
            <td class="periodB">{{ termB.mentalStopLine.std }}</td>
          </tr>
          <tr>
            <td>平均速度グラフ</td>
            <!-- 進入路 -->
            <td class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedGraphSetting.xAxes"
                  :yAxes="avgSpeedGraphSetting.yAxes"
                  :backgroundColor="avgSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.enter.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <td class="periodB">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedGraphSetting.xAxes"
                  :yAxes="avgSpeedGraphSetting.yAxes"
                  :backgroundColor="avgSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.enter.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線 -->
            <td class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedGraphSetting.xAxes"
                  :yAxes="avgSpeedGraphSetting.yAxes"
                  :backgroundColor="avgSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.stopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <td class="periodB">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedGraphSetting.xAxes"
                  :yAxes="avgSpeedGraphSetting.yAxes"
                  :backgroundColor="avgSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.stopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedGraphSetting.xAxes"
                  :yAxes="avgSpeedGraphSetting.yAxes"
                  :backgroundColor="avgSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.stopLineMentalStopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <td class="periodB">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedGraphSetting.xAxes"
                  :yAxes="avgSpeedGraphSetting.yAxes"
                  :backgroundColor="avgSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.stopLineMentalStopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 心的停止線 -->
            <td class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedGraphSetting.xAxes"
                  :yAxes="avgSpeedGraphSetting.yAxes"
                  :backgroundColor="avgSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.mentalStopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <td class="periodB">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedGraphSetting.xAxes"
                  :yAxes="avgSpeedGraphSetting.yAxes"
                  :backgroundColor="avgSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.mentalStopLine.avgSpeedHist"
                  :max="sectionMaxBorder.avgSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
          </tr>
          <tr>
            <td>平均速度ヒストグラム</td>
            <!-- 進入路 -->
            <td colspan="2" class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedHistGraphSetting.xAxes"
                  :yAxes="avgSpeedHistGraphSetting.yAxes"
                  :backgroundColor="avgSpeedHistGraphSetting.backgroundColor"
                  :title="avgSpeedHistGraphSetting.title"
                  :data="diff.enter.avgSpeedHist"
                  :isSuggested="true"
                  :max="sectionMaxBorder.diff.avg.suggestedMax"
                  :min="sectionMaxBorder.diff.avg.suggestedMin"
                  :maxTicksLimit="sectionMaxBorder.diff.avg.maxTicksLimit"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線 -->
            <td colspan="2" class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedHistGraphSetting.xAxes"
                  :yAxes="avgSpeedHistGraphSetting.yAxes"
                  :backgroundColor="avgSpeedHistGraphSetting.backgroundColor"
                  :title="avgSpeedHistGraphSetting.title"
                  :data="diff.stopLine.avgSpeedHist"
                  :isSuggested="true"
                  :max="sectionMaxBorder.diff.avg.suggestedMax"
                  :min="sectionMaxBorder.diff.avg.suggestedMin"
                  :maxTicksLimit="sectionMaxBorder.diff.avg.maxTicksLimit"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線~心的停止線 -->
            <td colspan="2" class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedHistGraphSetting.xAxes"
                  :yAxes="avgSpeedHistGraphSetting.yAxes"
                  :backgroundColor="avgSpeedHistGraphSetting.backgroundColor"
                  :title="avgSpeedHistGraphSetting.title"
                  :data="diff.stopLineMentalStopLine.avgSpeedHist"
                  :isSuggested="true"
                  :max="sectionMaxBorder.diff.avg.suggestedMax"
                  :min="sectionMaxBorder.diff.avg.suggestedMin"
                  :maxTicksLimit="sectionMaxBorder.diff.avg.maxTicksLimit"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 心的停止線 -->
            <td colspan="2" class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="avgSpeedHistGraphSetting.xAxes"
                  :yAxes="avgSpeedHistGraphSetting.yAxes"
                  :backgroundColor="avgSpeedHistGraphSetting.backgroundColor"
                  :title="avgSpeedHistGraphSetting.title"
                  :data="diff.mentalStopLine.avgSpeedHist"
                  :isSuggested="true"
                  :max="sectionMaxBorder.diff.avg.suggestedMax"
                  :min="sectionMaxBorder.diff.avg.suggestedMin"
                  :maxTicksLimit="sectionMaxBorder.diff.avg.maxTicksLimit"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
          </tr>
          <tr>
            <td>最高速度グラフ</td>
            <!-- 進入路 -->
            <td class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedGraphSetting.xAxes"
                  :yAxes="maxSpeedGraphSetting.yAxes"
                  :backgroundColor="maxSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.enter.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <td class="periodB">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedGraphSetting.xAxes"
                  :yAxes="maxSpeedGraphSetting.yAxes"
                  :backgroundColor="maxSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.enter.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線 -->
            <td class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedGraphSetting.xAxes"
                  :yAxes="maxSpeedGraphSetting.yAxes"
                  :backgroundColor="maxSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.stopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <td class="periodB">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedGraphSetting.xAxes"
                  :yAxes="maxSpeedGraphSetting.yAxes"
                  :backgroundColor="maxSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.stopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線~心的停止線 -->
            <td class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedGraphSetting.xAxes"
                  :yAxes="maxSpeedGraphSetting.yAxes"
                  :backgroundColor="maxSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.stopLineMentalStopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <td class="periodB">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedGraphSetting.xAxes"
                  :yAxes="maxSpeedGraphSetting.yAxes"
                  :backgroundColor="maxSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.stopLineMentalStopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 心的停止線 -->
            <td class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedGraphSetting.xAxes"
                  :yAxes="maxSpeedGraphSetting.yAxes"
                  :backgroundColor="maxSpeedGraphSetting.termA.backgroundColor"
                  :data="termA.mentalStopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <td class="periodB">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedGraphSetting.xAxes"
                  :yAxes="maxSpeedGraphSetting.yAxes"
                  :backgroundColor="maxSpeedGraphSetting.termB.backgroundColor"
                  :data="termB.mentalStopLine.maxSpeedHist"
                  :max="sectionMaxBorder.maxSpeedHist"
                  :isSuggested="true"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
          </tr>
          <tr>
            <td>最高速度ヒストグラム</td>
            <!-- 進入路 -->
            <td colspan="2" class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedHistGraphSetting.xAxes"
                  :yAxes="maxSpeedHistGraphSetting.yAxes"
                  :backgroundColor="maxSpeedHistGraphSetting.backgroundColor"
                  :title="maxSpeedHistGraphSetting.title"
                  :data="diff.enter.maxSpeedHist"
                  :isSuggested="true"
                  :max="sectionMaxBorder.diff.max.suggestedMax"
                  :min="sectionMaxBorder.diff.max.suggestedMin"
                  :maxTicksLimit="sectionMaxBorder.diff.max.maxTicksLimit"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線 -->
            <td colspan="2" class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedHistGraphSetting.xAxes"
                  :yAxes="maxSpeedHistGraphSetting.yAxes"
                  :backgroundColor="maxSpeedHistGraphSetting.backgroundColor"
                  :title="maxSpeedHistGraphSetting.title"
                  :data="diff.stopLine.maxSpeedHist"
                  :isSuggested="true"
                  :max="sectionMaxBorder.diff.max.suggestedMax"
                  :min="sectionMaxBorder.diff.max.suggestedMin"
                  :maxTicksLimit="sectionMaxBorder.diff.max.maxTicksLimit"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 停止線~心的停止線 -->
            <td colspan="2" class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedHistGraphSetting.xAxes"
                  :yAxes="maxSpeedHistGraphSetting.yAxes"
                  :backgroundColor="maxSpeedHistGraphSetting.backgroundColor"
                  :title="maxSpeedHistGraphSetting.title"
                  :data="diff.stopLineMentalStopLine.maxSpeedHist"
                  :isSuggested="true"
                  :max="sectionMaxBorder.diff.max.suggestedMax"
                  :min="sectionMaxBorder.diff.max.suggestedMin"
                  :maxTicksLimit="sectionMaxBorder.diff.max.maxTicksLimit"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
            <!-- 心的停止線 -->
            <td colspan="2" class="periodA">
              <v-card class="cardGraph1">
                <MacroAnalysisHistgram
                  v-if="isViewGraph"
                  :xAxes="maxSpeedHistGraphSetting.xAxes"
                  :yAxes="maxSpeedHistGraphSetting.yAxes"
                  :backgroundColor="maxSpeedHistGraphSetting.backgroundColor"
                  :title="maxSpeedHistGraphSetting.title"
                  :data="diff.mentalStopLine.maxSpeedHist"
                  :isSuggested="true"
                  :max="sectionMaxBorder.diff.max.suggestedMax"
                  :min="sectionMaxBorder.diff.max.suggestedMin"
                  :maxTicksLimit="sectionMaxBorder.diff.max.maxTicksLimit"
                  v-on:showModal="showModal"
                />
              </v-card>
            </td>
          </tr>
        </tbody>
      </table>
      <MacroAnalysisDetaiInfoTabel @closeSectionDetail="closeSectionDetail" v-if="isViewDetaiInfo" />
    </v-card>
    <ModalMacroAnalysisHistgram :value="isDisplay" @input="isDisplay = $event" text="errorText" :prefix="prefix" />
    <CommonDialog :dialog="dialog" />
  </div>
</template>
<script>
  import MacroAnalysisHistgram from "@/components/macroAnalysis/MacroAnalysisHistgram";
  import ModalMacroAnalysisHistgram from "@/components/macroAnalysis/ModalMacroAnalysisHistgram";
  import MacroAnalysisDetaiInfoTabel from "@/components/macroAnalysis/MacroAnalysisDetaiInfoTabel";
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import Loading from "@/components/Loading";
  import CommonDialog from "@/components/CommonDialog";
  export default {
    name: "MacroAnalysisTabel",
    components: {
      MacroAnalysisHistgram,
      ModalMacroAnalysisHistgram,
      MacroAnalysisDetaiInfoTabel,
      Loading,
      CommonDialog
    },
    mixins: [commonFunction, communicationFunction],
    data() {
      return {
        prefix: {
          component: null,
          props: {}
        },
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        sendData: undefined,
        isViewDetaiInfo: false,
        isLoading: false,
        isDisplay: false,
        isViewGraph: true,
        // 期間Aデータ
        termA: {
          enter: {
            number: undefined,
            avgSpeed: undefined,
            maxSpeed: undefined,
            mode: undefined,
            median: undefined,
            std: undefined,
            avgSpeedHist: [],
            maxSpeedHist: []
          },
          stopLine: {
            number: undefined,
            avgSpeed: undefined,
            maxSpeed: undefined,
            mode: undefined,
            median: undefined,
            std: undefined,
            avgSpeedHist: [],
            maxSpeedHist: []
          },
          stopLineMentalStopLine: {
            number: undefined,
            avgSpeed: undefined,
            maxSpeed: undefined,
            mode: undefined,
            median: undefined,
            std: undefined,
            avgSpeedHist: [],
            maxSpeedHist: []
          },
          mentalStopLine: {
            number: undefined,
            avgSpeed: undefined,
            maxSpeed: undefined,
            mode: undefined,
            median: undefined,
            std: undefined,
            avgSpeedHist: [],
            maxSpeedHist: []
          }
        },
        // 期間Bデータ
        termB: {
          enter: {
            number: undefined,
            avgSpeed: undefined,
            maxSpeed: undefined,
            mode: undefined,
            median: undefined,
            std: undefined,
            avgSpeedHist: [],
            maxSpeedHist: []
          },
          stopLine: {
            number: undefined,
            avgSpeed: undefined,
            maxSpeed: undefined,
            mode: undefined,
            median: undefined,
            std: undefined,
            avgSpeedHist: [],
            maxSpeedHist: []
          },
          stopLineMentalStopLine: {
            number: undefined,
            avgSpeed: undefined,
            maxSpeed: undefined,
            mode: undefined,
            median: undefined,
            std: undefined,
            avgSpeedHist: [],
            maxSpeedHist: []
          },
          mentalStopLine: {
            number: undefined,
            avgSpeed: undefined,
            maxSpeed: undefined,
            mode: undefined,
            median: undefined,
            std: undefined,
            avgSpeedHist: [],
            maxSpeedHist: []
          }
        },
        // 差分データ
        diff: {
          enter: {
            avgSpeedHist: [],
            maxSpeedHist: []
          },
          stopLine: {
            avgSpeedHist: [],
            maxSpeedHist: []
          },
          stopLineMentalStopLine: {
            avgSpeedHist: [],
            maxSpeedHist: []
          },
          mentalStopLine: {
            avgSpeedHist: [],
            maxSpeedHist: []
          }
        },
        avgSpeedGraphSetting: {
          xAxes: {
            scaleLabelDisplay: true,
            labelString: "平均速度"
          },
          yAxes: {
            scaleLabelDisplay: true,
            labelString: "台数"
          },
          termA: {
            backgroundColor: "rgba(65, 158, 143, 0.8)"
          },
          termB: {
            backgroundColor: "rgba(160, 158, 34, 0.8)"
          }
        },
        avgSpeedHistGraphSetting: {
          xAxes: {
            scaleLabelDisplay: true,
            labelString: "平均速度"
          },
          yAxes: {
            scaleLabelDisplay: true,
            labelString: "差分"
          },
          title: {
            display: true,
            text: "期間Aから期間Bを引いた差分値"
          },
          backgroundColor: "rgba(236, 97, 4, 0.8)"
        },
        maxSpeedGraphSetting: {
          xAxes: {
            scaleLabelDisplay: true,
            labelString: "最高速度"
          },
          yAxes: {
            scaleLabelDisplay: true,
            labelString: "台数"
          },
          termA: {
            backgroundColor: "rgba(65, 158, 143, 0.8)"
          },
          termB: {
            backgroundColor: "rgba(160, 158, 34, 0.8)"
          }
        },
        maxSpeedHistGraphSetting: {
          xAxes: {
            scaleLabelDisplay: true,
            labelString: "最高速度"
          },
          yAxes: {
            scaleLabelDisplay: true,
            labelString: "差分"
          },
          title: {
            display: true,
            text: "期間Aから期間Bを引いた差分値"
          },
          backgroundColor: "rgba(236, 97, 4, 0.8)"
        },
        sectionMaxBorder: {
          avgSpeedHist: 1,
          maxSpeedHist: 1,
          diff: {
            avg: {
              suggestedMax: 1,
              suggestedMin: 0,
              maxTicksLimit: 2
            },
            max: {
              suggestedMax: 1,
              suggestedMin: 0,
              maxTicksLimit: 2
            }
          }
        }
      };
    },
    mounted() {},
    methods: {
      getBorderMaxData(array1) {
        let tempArray = [];
        for (const data of array1)
          for (const item of data) {
            tempArray.push(item);
          }
        let maxValue = Math.max(...tempArray);
        return maxValue;
      },
      getDiffBorderData(array1) {
        let tempArray = [];
        for (const data of array1)
          for (const item of data) {
            tempArray.push(item);
          }
        let max = Math.max(...tempArray);
        let min = Math.min(...tempArray);

        // 初期値設定
        let suggestedMaxValue = 1;
        let suggestedMinValue = 0;
        let maxTicksLimit = 2;

        // boarder範囲の作成
        if (max >= 1 && min >= 0) {
          suggestedMaxValue = max;
          suggestedMinValue = 0;
        } else if (max <= 0 && min < 0) {
          suggestedMaxValue = 0;
          suggestedMinValue = min;
        } else if (max == 0 && min == 0) {
          suggestedMaxValue = 1;
          suggestedMinValue = 0;
          maxTicksLimit = 2;
        } else {
          suggestedMaxValue = max;
          suggestedMinValue = min;
          maxTicksLimit = 3;
        }
        return {
          suggestedMax: suggestedMaxValue,
          suggestedMin: suggestedMinValue,
          maxTicksLimit: maxTicksLimit
        };
      },
      async getData(sendData) {
        this.sendData = sendData;
        this.isLoading = true;
        this.isViewGraph = false;
        let promise = this.updateMacroAnalysisInfo(
          this.sendData.poleId,
          this.sendData.aggregationPeriod.start.replaceAll("-", "/") + " 00:00:00",
          this.sendData.aggregationPeriod.end.replaceAll("-", "/") + " 00:00:00",
          this.sendData.comparisonPeriod.start.replaceAll("-", "/") + " 00:00:00",
          this.sendData.comparisonPeriod.end.replaceAll("-", "/") + " 00:00:00",
          this.sendData.directionValue,
          this.sendData.ledValue,
          this.sendData.aggregationTargetValue,
          this.sendData.aggregationMethodValue
        );
        promise
          .then(() => {
            // データが存在するか判定
            if (this.$store.state.macroAnalysisInfo.enter.number.length != 0) {
              this.setData();
            } else {
              this.clearData();
              this.dialog.title = "";
              this.dialog.message = this.str.dialog.message.getErrorMacroAnalysisInfoNoContent;
              this.dialog.isDialog = true;
            }
          })
          .then(() => {
            this.isViewGraph = true;
            this.isLoading = false;
          })
          .catch(() => {
            this.isViewGraph = true;
            this.isLoading = false;
            this.dialog.title = this.str.dialog.title.getError;
            this.dialog.message = this.str.dialog.message.getErrorMacroAnalysisInfo;
            this.dialog.isDialog = true;
          });
      },
      // データ設定
      setData() {
        // 進入路データ
        this.termA.enter.number = this.$store.state.macroAnalysisInfo.enter.number[0];
        this.termA.enter.avgSpeed = this.$store.state.macroAnalysisInfo.enter.avgSpeed[0];
        this.termA.enter.maxSpeed = this.$store.state.macroAnalysisInfo.enter.maxSpeed[0];
        this.termA.enter.mode = this.$store.state.macroAnalysisInfo.enter.mode[0];
        this.termA.enter.median = this.$store.state.macroAnalysisInfo.enter.median[0];
        this.termA.enter.std = this.$store.state.macroAnalysisInfo.enter.std[0];
        this.termA.enter.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.enter.avgHist[0]);
        this.termA.enter.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.enter.maxHist[0]);

        this.termB.enter.number = this.$store.state.macroAnalysisInfo.enter.number[1];
        this.termB.enter.avgSpeed = this.$store.state.macroAnalysisInfo.enter.avgSpeed[1];
        this.termB.enter.maxSpeed = this.$store.state.macroAnalysisInfo.enter.maxSpeed[1];
        this.termB.enter.mode = this.$store.state.macroAnalysisInfo.enter.mode[1];
        this.termB.enter.median = this.$store.state.macroAnalysisInfo.enter.median[1];
        this.termB.enter.std = this.$store.state.macroAnalysisInfo.enter.std[1];
        this.termB.enter.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.enter.avgHist[1]);
        this.termB.enter.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.enter.maxHist[1]);

        // 停止線データ
        this.termA.stopLine.number = this.$store.state.macroAnalysisInfo.stopLine.number[0];
        this.termA.stopLine.avgSpeed = this.$store.state.macroAnalysisInfo.stopLine.avgSpeed[0];
        this.termA.stopLine.maxSpeed = this.$store.state.macroAnalysisInfo.stopLine.maxSpeed[0];
        this.termA.stopLine.mode = this.$store.state.macroAnalysisInfo.stopLine.mode[0];
        this.termA.stopLine.median = this.$store.state.macroAnalysisInfo.stopLine.median[0];
        this.termA.stopLine.std = this.$store.state.macroAnalysisInfo.stopLine.std[0];
        this.termA.stopLine.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLine.avgHist[0]);
        this.termA.stopLine.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLine.maxHist[0]);

        this.termB.stopLine.number = this.$store.state.macroAnalysisInfo.stopLine.number[1];
        this.termB.stopLine.avgSpeed = this.$store.state.macroAnalysisInfo.stopLine.avgSpeed[1];
        this.termB.stopLine.maxSpeed = this.$store.state.macroAnalysisInfo.stopLine.maxSpeed[1];
        this.termB.stopLine.mode = this.$store.state.macroAnalysisInfo.stopLine.mode[1];
        this.termB.stopLine.median = this.$store.state.macroAnalysisInfo.stopLine.median[1];
        this.termB.stopLine.std = this.$store.state.macroAnalysisInfo.stopLine.std[1];
        this.termB.stopLine.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLine.avgHist[1]);
        this.termB.stopLine.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLine.maxHist[1]);

        // 停止線~心的停止線データ
        this.termA.stopLineMentalStopLine.number = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.number[0];
        this.termA.stopLineMentalStopLine.avgSpeed = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.avgSpeed[0];
        this.termA.stopLineMentalStopLine.maxSpeed = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.maxSpeed[0];
        this.termA.stopLineMentalStopLine.mode = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.mode[0];
        this.termA.stopLineMentalStopLine.median = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.median[0];
        this.termA.stopLineMentalStopLine.std = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.std[0];
        this.termA.stopLineMentalStopLine.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.avgHist[0]);
        this.termA.stopLineMentalStopLine.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.maxHist[0]);

        this.termB.stopLineMentalStopLine.number = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.number[1];
        this.termB.stopLineMentalStopLine.avgSpeed = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.avgSpeed[1];
        this.termB.stopLineMentalStopLine.maxSpeed = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.maxSpeed[1];
        this.termB.stopLineMentalStopLine.mode = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.mode[1];
        this.termB.stopLineMentalStopLine.median = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.median[1];
        this.termB.stopLineMentalStopLine.std = this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.std[1];
        this.termB.stopLineMentalStopLine.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.avgHist[1]);
        this.termB.stopLineMentalStopLine.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.maxHist[1]);

        // 心的停止線
        this.termA.mentalStopLine.number = this.$store.state.macroAnalysisInfo.mentalStopLine.number[0];
        this.termA.mentalStopLine.avgSpeed = this.$store.state.macroAnalysisInfo.mentalStopLine.avgSpeed[0];
        this.termA.mentalStopLine.maxSpeed = this.$store.state.macroAnalysisInfo.mentalStopLine.maxSpeed[0];
        this.termA.mentalStopLine.mode = this.$store.state.macroAnalysisInfo.mentalStopLine.mode[0];
        this.termA.mentalStopLine.median = this.$store.state.macroAnalysisInfo.mentalStopLine.median[0];
        this.termA.mentalStopLine.std = this.$store.state.macroAnalysisInfo.mentalStopLine.std[0];
        this.termA.mentalStopLine.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.mentalStopLine.avgHist[0]);
        this.termA.mentalStopLine.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.mentalStopLine.maxHist[0]);

        this.termB.mentalStopLine.number = this.$store.state.macroAnalysisInfo.mentalStopLine.number[1];
        this.termB.mentalStopLine.avgSpeed = this.$store.state.macroAnalysisInfo.mentalStopLine.avgSpeed[1];
        this.termB.mentalStopLine.maxSpeed = this.$store.state.macroAnalysisInfo.mentalStopLine.maxSpeed[1];
        this.termB.mentalStopLine.mode = this.$store.state.macroAnalysisInfo.mentalStopLine.mode[1];
        this.termB.mentalStopLine.median = this.$store.state.macroAnalysisInfo.mentalStopLine.median[1];
        this.termB.mentalStopLine.std = this.$store.state.macroAnalysisInfo.mentalStopLine.std[1];
        this.termB.mentalStopLine.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.mentalStopLine.avgHist[1]);
        this.termB.mentalStopLine.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.mentalStopLine.maxHist[1]);

        // 差分データ
        // 進入路データ
        this.diff.enter.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.enter.avgHist[2]);
        this.diff.enter.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.enter.maxHist[2]);
        // 停止線データ
        this.diff.stopLine.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLine.avgHist[2]);
        this.diff.stopLine.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLine.maxHist[2]);
        // 停止線~心的停止線データ
        this.diff.stopLineMentalStopLine.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.avgHist[2]);
        this.diff.stopLineMentalStopLine.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.stopLineMentalStopLine.maxHist[2]);

        // 心的停止線
        this.diff.mentalStopLine.avgSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.mentalStopLine.avgHist[2]);
        this.diff.mentalStopLine.maxSpeedHist = Object.values(this.$store.state.macroAnalysisInfo.mentalStopLine.maxHist[2]);

        // border設定
        this.sectionMaxBorder.avgSpeedHist = this.getBorderMaxData([
          this.termA.enter.avgSpeedHist,
          this.termB.enter.avgSpeedHist,
          this.termA.stopLine.avgSpeedHist,
          this.termB.stopLine.avgSpeedHist,
          this.termA.stopLineMentalStopLine.avgSpeedHist,
          this.termB.stopLineMentalStopLine.avgSpeedHist,
          this.termA.mentalStopLine.avgSpeedHist,
          this.termB.mentalStopLine.avgSpeedHist
        ]);
        this.sectionMaxBorder.maxSpeedHist = this.getBorderMaxData([
          this.termA.enter.maxSpeedHist,
          this.termB.enter.maxSpeedHist,
          this.termA.stopLine.maxSpeedHist,
          this.termB.stopLine.maxSpeedHist,
          this.termA.stopLineMentalStopLine.maxSpeedHist,
          this.termB.stopLineMentalStopLine.maxSpeedHist,
          this.termA.mentalStopLine.maxSpeedHist,
          this.termB.mentalStopLine.maxSpeedHist
        ]);

        this.sectionMaxBorder.diff.avg = this.getDiffBorderData([
          this.diff.enter.avgSpeedHist,
          this.diff.stopLine.avgSpeedHist,
          this.diff.stopLineMentalStopLine.avgSpeedHist,
          this.diff.mentalStopLine.avgSpeedHist
        ]);
        this.sectionMaxBorder.diff.max = this.getDiffBorderData([
          this.diff.enter.maxSpeedHist,
          this.diff.stopLine.maxSpeedHist,
          this.diff.stopLineMentalStopLine.maxSpeedHist,
          this.diff.mentalStopLine.maxSpeedHist
        ]);
      },
      clearData() {
        // 進入路データ
        this.termA.enter.number = undefined;
        this.termA.enter.avgSpeed = undefined;
        this.termA.enter.maxSpeed = undefined;
        this.termA.enter.mode = undefined;
        this.termA.enter.median = undefined;
        this.termA.enter.std = undefined;
        this.termA.enter.avgSpeedHist = [];
        this.termA.enter.maxSpeedHist = [];

        this.termB.enter.number = undefined;
        this.termB.enter.avgSpeed = undefined;
        this.termB.enter.maxSpeed = undefined;
        this.termB.enter.mode = undefined;
        this.termB.enter.median = undefined;
        this.termB.enter.std = undefined;
        this.termB.enter.avgSpeedHist = [];
        this.termB.enter.maxSpeedHist = [];

        // 停止線データ
        this.termA.stopLine.number = undefined;
        this.termA.stopLine.avgSpeed = undefined;
        this.termA.stopLine.maxSpeed = undefined;
        this.termA.stopLine.mode = undefined;
        this.termA.stopLine.median = undefined;
        this.termA.stopLine.std = undefined;
        this.termA.stopLine.avgSpeedHist = [];
        this.termA.stopLine.maxSpeedHist = [];

        this.termB.stopLine.number = undefined;
        this.termB.stopLine.avgSpeed = undefined;
        this.termB.stopLine.maxSpeed = undefined;
        this.termB.stopLine.mode = undefined;
        this.termB.stopLine.median = undefined;
        this.termB.stopLine.std = undefined;
        this.termB.stopLine.avgSpeedHist = [];
        this.termB.stopLine.maxSpeedHist = [];

        // 停止線~心的停止線データ
        this.termA.stopLineMentalStopLine.number = undefined;
        this.termA.stopLineMentalStopLine.avgSpeed = undefined;
        this.termA.stopLineMentalStopLine.maxSpeed = undefined;
        this.termA.stopLineMentalStopLine.mode = undefined;
        this.termA.stopLineMentalStopLine.median = undefined;
        this.termA.stopLineMentalStopLine.std = undefined;
        this.termA.stopLineMentalStopLine.avgSpeedHist = [];
        this.termA.stopLineMentalStopLine.maxSpeedHist = [];

        this.termB.stopLineMentalStopLine.number = undefined;
        this.termB.stopLineMentalStopLine.avgSpeed = undefined;
        this.termB.stopLineMentalStopLine.maxSpeed = undefined;
        this.termB.stopLineMentalStopLine.mode = undefined;
        this.termB.stopLineMentalStopLine.median = undefined;
        this.termB.stopLineMentalStopLine.std = undefined;
        this.termB.stopLineMentalStopLine.avgSpeedHist = [];
        this.termB.stopLineMentalStopLine.maxSpeedHist = [];

        // 心的停止線
        this.termA.mentalStopLine.number = undefined;
        this.termA.mentalStopLine.avgSpeed = undefined;
        this.termA.mentalStopLine.maxSpeed = undefined;
        this.termA.mentalStopLine.mode = undefined;
        this.termA.mentalStopLine.median = undefined;
        this.termA.mentalStopLine.std = undefined;
        this.termA.mentalStopLine.avgSpeedHist = [];
        this.termA.mentalStopLine.maxSpeedHist = [];

        this.termB.mentalStopLine.number = undefined;
        this.termB.mentalStopLine.avgSpeed = undefined;
        this.termB.mentalStopLine.maxSpeed = undefined;
        this.termB.mentalStopLine.mode = undefined;
        this.termB.mentalStopLine.median = undefined;
        this.termB.mentalStopLine.std = undefined;
        this.termB.mentalStopLine.avgSpeedHist = [];
        this.termB.mentalStopLine.maxSpeedHist = [];

        // 差分データ
        // 進入路データ
        this.diff.enter.avgSpeedHist = [];
        this.diff.enter.maxSpeedHist = [];
        // 停止線データ
        this.diff.stopLine.avgSpeedHist = [];
        this.diff.stopLine.maxSpeedHist = [];
        // 停止線~心的停止線データ
        this.diff.stopLineMentalStopLine.avgSpeedHist = [];
        this.diff.stopLineMentalStopLine.maxSpeedHist = [];
        // 心的停止線
        this.diff.mentalStopLine.avgSpeedHist = [];
        this.diff.mentalStopLine.maxSpeedHist = [];
      },
      showModal(component, props) {
        this.prefix.component = component;
        this.prefix.props = props;
        this.isDisplay = true;
      },
      async viewSectionDetail(section) {
        if (this.sendData == undefined) {
          this.dialog.title = "";
          this.dialog.message = "データ読込が行われていません。\r\nデータ読込を行ってください";
          this.dialog.isDialog = true;
          return;
        }
        if (this.termA.enter.number == undefined) {
          this.dialog.title = "";
          this.dialog.message = "現在のパラメータ設定ではデータが存在していません \r\n再度パラメータを設定後 \r\nデータ読込を行ってください";
          this.dialog.isDialog = true;
          return;
        }

        this.isLoading = true;
        let promise = this.updateMacroAnalysisDetaiInfo(
          section,
          this.sendData.poleId,
          this.sendData.aggregationPeriod.start.replaceAll("-", "/") + " 00:00:00",
          this.sendData.aggregationPeriod.end.replaceAll("-", "/") + " 00:00:00",
          this.sendData.comparisonPeriod.start.replaceAll("-", "/") + " 00:00:00",
          this.sendData.comparisonPeriod.end.replaceAll("-", "/") + " 00:00:00",
          this.sendData.directionValue,
          this.sendData.ledValue,
          this.sendData.aggregationTargetValue,
          this.sendData.aggregationMethodValue
        );
        promise
          .then(() => {
            this.isLoading = false;
            // データが存在するか判定
            if (this.$store.state.macroAnalysisDetaiInfo.termA.number.length != 0) {
              // データが存在する場合マクロ分析詳細表示
              this.isViewDetaiInfo = true;
            } else {
              this.isViewDetaiInfo = false;
              this.dialog.title = "";
              this.dialog.message = this.str.dialog.message.getErrorMacroAnalysisDetailInfoNoContent;
              this.dialog.isDialog = true;
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.isViewDetaiInfo = false;
            this.dialog.title = this.str.dialog.title.getError;
            this.dialog.message = this.str.dialog.message.getErrorMacroAnalysisDetailInfo;
            this.dialog.isDialog = true;
          });
      },
      closeSectionDetail() {
        this.isViewDetaiInfo = false;
      }
    }
  };
</script>
<style scoped>
  .divVCard1 {
    position: relative;
    top: 15%;
    text-align: center;
    width: 100%;
    left: 5%;
  }
  .vCardTabel2 {
    width: 90%;
  }
  table {
    width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  table th:first-child {
    border-radius: 5px 0 0 0;
  }

  table th:last-child {
    border-radius: 0 5px 0 0;
    border-right: 1px solid #3c6690;
  }
  .empty {
    background: linear-gradient(#4334537a, #225588);
  }

  table th {
    text-align: center;
    color: white;
    background: linear-gradient(#4334537a, #225588);
    border-left: 1px solid #3c6690;
    border-top: 1px solid #3c6690;
    border-bottom: 1px solid #3c6690;
    width: 20%;
  }

  table td {
    text-align: center;
    border-left: 0.5px solid #0d0d0e;
    border-bottom: 0.5px solid #0d0d0e;
    border-top: none;
    padding: 5px 0;
  }

  table td:last-child {
    border-right: 1px solid #0d0d0e;
  }

  table tr:last-child td:first-child {
    border-radius: 0 0 0 5px;
  }

  table tr:last-child td:last-child {
    border-radius: 0 0 5px 0;
  }
  .periodA {
    background-color: rgba(65, 158, 143, 0.4);
    width: 10%;
  }
  .periodB {
    background-color: rgba(160, 158, 34, 0.3);
    width: 10%;
  }
  .cardGraph1 {
    height: 100%;
    cursor: pointer;
    outline: none;
  }
</style>
