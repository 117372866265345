<template>
  <div class="accident">
    <Loading v-show="isLoading" />
    <v-row class="ml-2 mr-2">
      <v-col cols="15">
        <div class="area1">
          <div class="mb-2 area1-1">
            <v-card elevation="10">
              <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 3vh">
                <span class="py-0">VIRTUAL映像</span>
              </v-card-title>

              <div class="dataScreen">
                <div class="dataScreen">
                  <div id="monitorMap"></div>
                </div>
              </div>
            </v-card>
          </div>
          <div class="area1-2">
            <v-card elevation="10">
              <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 3vh">
                <span class="py-0">検索方法</span>
              </v-card-title>
              <v-select :items="retrievalItems" v-model="retrievalMethodVlue"></v-select>
            </v-card>
            <v-card elevation="10">
              <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 3vh">
                <span class="py-0">日付(表示範囲)</span>
              </v-card-title>
              <!-- 日時範囲検索 -->
              <ValidationObserver ref="accidentInitialForm" v-slot="ObserverProps" v-show="retrievalMethodVlue == retrievalItems[0].value">
                <AccidentInitialForm>
                  <template v-slot:dateTime>
                    <div class="d-flex">
                      <ValidationProvider rules="required" v-slot="ProviderProps">
                        <DatePicker :value="searchConditions.dates1[0]" @input="searchConditions.dates1[0] = $event" scrollable />
                        <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="ProviderProps">
                        <VueTimepicker
                          format="HH:mm:ss"
                          hour-label="時"
                          minute-label="分"
                          second-label="秒"
                          :value="searchConditions.fromTime"
                          @input="searchConditions.fromTime = $event"
                        />
                        <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                      </ValidationProvider>
                    </div>

                    <p class="pt-6">&emsp;～&emsp;</p>
                    <div class="d-flex">
                      <ValidationProvider rules="required" v-slot="ProviderProps">
                        <DatePicker :value="searchConditions.dates1[1]" @input="searchConditions.dates1[1] = $event" scrollable />
                        <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                      </ValidationProvider>
                      <ValidationProvider rules="required" v-slot="ProviderProps">
                        <VueTimepicker
                          format="HH:mm:ss"
                          hour-label="時"
                          minute-label="分"
                          second-label="秒"
                          :value="searchConditions.toTime"
                          @input="searchConditions.toTime = $event"
                        />
                        <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </template>

                  <template v-slot:threshold>
                    <ValidationProvider :rules="{ required: true, min_value: 0, max_value: 999.999, regex: /^(\d{0,3}\.\d{0,3}|\d{0,3})$/ }" v-slot="ProviderProps" name="閾値">
                      <TextField :value="searchConditions.threshold" @input="searchConditions.threshold = $event" label="TimeGapの閾値を入力してください" />
                      <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                    </ValidationProvider>
                  </template>
                  <template v-slot:deduplication>
                    <v-switch v-model="isDeduplication" label="ヒヤリハット一覧 重複行を除外する"></v-switch>
                  </template>

                  <template v-slot:button>
                    <Button v-bind:disabled="isProcessing()" label="決定" @click="Search((init = false), ObserverProps)" />
                  </template>
                </AccidentInitialForm>
              </ValidationObserver>
              <!-- 日時個別検索 -->
              <ValidationObserver ref="accidentAllIndividualForm" v-slot="ObserverProps" v-show="retrievalMethodVlue == retrievalItems[1].value">
                <AccidentAllIndividualForm>
                  <template v-slot:date>
                    <div>
                      <v-menu
                        ref="menu2"
                        v-model="searchConditions.menu2"
                        :close-on-content-click="false"
                        :return-value.sync="searchConditions.dates2"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider rules="required|requiredArray" v-slot="ProviderProps" name="対象日">
                            <v-combobox
                              v-model="dates2RangeList"
                              multiple
                              chips
                              small-chips
                              label="対象日"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-combobox>
                            <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                          </ValidationProvider>
                        </template>
                        <v-date-picker v-model="searchConditions.dates2" multiple scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="searchConditions.menu2 = false">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.menu2.save(searchConditions.dates2)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                  <template v-slot:time>
                    <div>
                      <ValidationProvider rules="required" v-slot="ProviderProps">
                        <p class="time">時刻</p>
                        <v-row>
                          <div class="timepicker">
                            <VueTimepicker
                              format="HH:mm:ss"
                              hour-label="時"
                              minute-label="分"
                              second-label="秒"
                              :value="searchConditions.fromTime"
                              @input="searchConditions.fromTime = $event"
                            />
                          </div>
                          <p class="pt-3">～</p>
                          <div class="timepicker">
                            <VueTimepicker
                              format="HH:mm:ss"
                              hour-label="時"
                              minute-label="分"
                              second-label="秒"
                              :value="searchConditions.toTime"
                              @input="searchConditions.toTime = $event"
                            />
                          </div>
                        </v-row>
                        <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </template>
                  <template v-slot:threshold>
                    <ValidationProvider :rules="{ required: true, min_value: 0, max_value: 999.999, regex: /^(\d{0,3}\.\d{0,3}|\d{0,3})$/ }" v-slot="ProviderProps" name="閾値">
                      <TextField :value="searchConditions.threshold" @input="searchConditions.threshold = $event" label="TimeGapの閾値を入力してください" />
                      <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                    </ValidationProvider>
                  </template>
                  <template v-slot:deduplication>
                    <v-switch v-model="isDeduplication" label="ヒヤリハット一覧 重複行を除外する"></v-switch>
                  </template>

                  <template v-slot:button>
                    <Button v-bind:disabled="isProcessing()" label="決定" @click="Search((init = false), ObserverProps)" />
                  </template>
                </AccidentAllIndividualForm>
              </ValidationObserver>
              <!-- 日付範囲-日時個別検索 -->
              <ValidationObserver ref="accidentTimeIndividualForm" v-slot="ObserverProps" v-show="retrievalMethodVlue == retrievalItems[2].value">
                <AccidentTimeIndividualForm>
                  <template v-slot:date>
                    <div>
                      <v-menu
                        ref="menu3"
                        v-model="searchConditions.menu3"
                        :close-on-content-click="false"
                        :return-value.sync="searchConditions.dates3"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider rules="required|requiredArray" v-slot="ProviderProps" name="対象日">
                            <v-combobox
                              v-model="dates3RangeList"
                              multiple
                              chips
                              small-chips
                              label="対象日"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-combobox>
                            <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                          </ValidationProvider>
                        </template>
                        <v-date-picker v-model="searchConditions.dates3" range>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="searchConditions.menu3 = false">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.menu3.save(searchConditions.dates3)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                  <template v-slot:time>
                    <div>
                      <ValidationProvider rules="required" v-slot="ProviderProps">
                        <p class="time">時刻</p>
                        <v-row>
                          <div class="timepicker">
                            <VueTimepicker
                              format="HH:mm:ss"
                              hour-label="時"
                              minute-label="分"
                              second-label="秒"
                              :value="searchConditions.fromTime"
                              @input="searchConditions.fromTime = $event"
                            />
                          </div>
                          <p class="pt-3">～</p>
                          <div class="timepicker">
                            <VueTimepicker
                              format="HH:mm:ss"
                              hour-label="時"
                              minute-label="分"
                              second-label="秒"
                              :value="searchConditions.toTime"
                              @input="searchConditions.toTime = $event"
                            />
                          </div>
                        </v-row>
                        <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                  </template>
                  <template v-slot:threshold>
                    <ValidationProvider :rules="{ required: true, min_value: 0, max_value: 999.999, regex: /^(\d{0,3}\.\d{0,3}|\d{0,3})$/ }" v-slot="ProviderProps" name="閾値">
                      <TextField :value="searchConditions.threshold" @input="searchConditions.threshold = $event" label="TimeGapの閾値を入力してください" />
                      <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                    </ValidationProvider>
                  </template>
                  <template v-slot:deduplication>
                    <v-switch v-model="isDeduplication" label="ヒヤリハット一覧 重複行を除外する"></v-switch>
                  </template>

                  <template v-slot:button>
                    <Button v-bind:disabled="isProcessing()" label="決定" @click="Search((init = false), ObserverProps)" />
                  </template>
                </AccidentTimeIndividualForm>
              </ValidationObserver>
            </v-card>
          </div>
        </div>

        <div class="mb-2">
          <v-card elevation="3">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 3vh">
              <span class="py-0">ヒヤリハット一覧</span>
              <button v-on:click="DownloadCsv()" class="download-icon">
                <img src="@/assets/icons/csvDownloadIcon.png" />
              </button>
            </v-card-title>
            <!-- Filter-->
            <v-row>
              <v-col cols="1" class="fId">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissIdFilterValue" type="text" label="id"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1" class="fId2">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissAreaFilterValue" type="text" label="判定エリア"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1" class="fId2">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissVehicleId1FilterValue" type="text" label="車両1ID"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1" class="fId2">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissVehicleId1KindFilterValue" type="text" label="車両1物標種別"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1" class="fId2">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissVehicleId1DirectionFilterValue" type="text" label="車両1方路"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1" class="fId2">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissVehicleId2FilterValue" type="text" label="車両2ID"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1" class="fId2">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissVehicleId2KindFilterValue" type="text" label="車両2物標種別"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1" class="fId2">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissVehicleId2DirectionFilterValue" type="text" label="車両2方路"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1" class="fId2">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissLatitudeFilterValue" type="text" label="緯度"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1" class="fId2">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissLongitudeFilterValue" type="text" label="経度"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissTimeFilterValue" type="text" label="発生時刻"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1">
                <v-row class="pa-5">
                  <v-text-field v-model="nearMissTimeGapFilterValue" type="text" label="TimeGap"> </v-text-field>
                </v-row>
              </v-col>
            </v-row>

            <v-data-table :headers="nearMissesTableHeader" :items="editingData.nearMisses">
              <template v-slot:[`item.nearMisstime`]="{ item }">
                {{ item.nearMisstime }}
              </template>
              <template v-slot:[`item.timeGap`]="{ item }">
                {{ createTimegap4Disp(item.timeGap) }}
              </template>
              <template v-slot:[`item.videPath`]="{ item }">
                <v-icon v-if="item.videoPath == undefined || item.videoPath == null || item.videoPath == ''" small class="mr-2"> mdi-video </v-icon>
                <v-icon v-else small color="purple" class="mr-2" @click="videoPlay(item)"> mdi-video </v-icon>
              </template>
              <template v-slot:[`item.downloadVidePath`]="{ item }">
                <v-icon v-if="item.downloadVidePath == undefined || item.downloadVidePath == null || item.downloadVidePath == ''" dense class="mr-2"> mdi-multimedia </v-icon>
                <v-icon v-else dense color="blue" class="mr-2" @click="downloadData(item)"> mdi-multimedia </v-icon>
              </template>
              <template v-slot:[`item.nearMissinfo`]="{ item }">
                <v-icon small class="mr-2" @click="viewNearMissinfo(item)"> mdi-details </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </div>

        <div class="mb-2">
          <v-card elevation="3">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 3vh">
              <span class="py-0">事故発生一覧</span>
            </v-card-title>
            <!-- Filter-->
            <v-row>
              <v-col cols="1">
                <v-row class="pa-5">
                  <v-text-field v-model="accidentIdFilterValue" type="text" label="id"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1">
                <v-row class="pa-5">
                  <v-text-field v-model="accidentVehicleId1FilterValue" type="text" label="車両1ID"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1">
                <v-row class="pa-5">
                  <v-text-field v-model="accidentVehicleId2FilterValue" type="text" label="車両2ID"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1">
                <v-row class="pa-5">
                  <v-text-field v-model="accidentLatitudeFilterValue" type="text" label="緯度"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="1">
                <v-row class="pa-5">
                  <v-text-field v-model="accidentLongitudeFilterValue" type="text" label="経度"> </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-row class="pa-5">
                  <v-text-field v-model="accidentTimeFilterValue" type="text" label="事故発生時刻"> </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-data-table :headers="accidentsTableHeader" :items="editingData.accidents"> </v-data-table>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <Title :titleInfo="titleInfo" />

    <Modal :value="error" @input="error = $event" :text="errorText" />
    <CommonDialog :dialog="dialog" />
    <div class="videoPlayer" v-if="showMovie">
      <video-player @parentFunc="closeVideo" :options="videoOptions" :nearMissesinfo="nearMissesInfo" v-if="showMovie" />
    </div>
    <NearMissinfoDashboard @colseNearMissinfo="colseNearMissinfo" v-if="showNearMissinfo" />
  </div>
</template>

<script>
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import VideoPlayer from "@/components/video/VideoPlayer";
  import "video.js/dist/video-js.css";
  import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
  import NearMissinfoDashboard from "@/components/accident/NearMissinfoDashboard";
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import CommonDialog from "@/components/CommonDialog";
  import { getInitToDate, getPreviousDay } from "@/utils/day.js";
  import { poleIcon, nearMissIcon, accidentIcon } from "@/utils/mapIcon.js";
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import moment from "moment";

  /**
   * Accident.vue
   * ヒヤリハット・事故情報画面
   */
  export default {
    // コンポーネント名
    name: "Accident",
    /**
     * 子コンポーネント
     */
    components: {
      Title,
      Loading,
      CommonDialog,
      VideoPlayer,
      NearMissinfoDashboard,
      VueTimepicker
    },
    /**
     * 追加オブジェクト
     */
    mixins: [commonFunction, communicationFunction],
    /**
     * プロパティ
     */
    data() {
      return {
        poleId: this.$store.state.selectPole.poleId,
        setting: undefined,
        isLoading: true,
        address: undefined,
        init: false,
        titleInfo: {
          title: "",
          show: {
            realtime: true,
            multicamera: true,
            virtual: true,
            menu: true
          },
          menuList: [],
          poleList: []
        },
        nearMisses: [],
        accidents: [],
        editingData: {
          areaName: "",
          mapLatlng: this.$store.state.selectPole.latlng,
          poleMarkers: [],
          markerLatlng: [0, 0],
          nearMisses: [],
          accidents: []
        },
        map: {},
        error: false,
        errorText: "データ",
        showMovie: false,
        videoUrl: "",
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        videoOptions: {
          autoplay: false,
          controls: true,
          sources: [
            {
              src: "",
              type: "video/mp4"
            }
          ]
        },
        showNearMissinfo: false,
        isDeduplication: true,
        // ヒヤリハット一覧フィルター値
        nearMissIdFilterValue: "",
        nearMissAreaFilterValue: "",
        nearMissVehicleId1FilterValue: "",
        nearMissVehicleId1KindFilterValue: "",
        nearMissVehicleId1DirectionFilterValue: "",
        nearMissVehicleId2FilterValue: "",
        nearMissVehicleId2KindFilterValue: "",
        nearMissVehicleId2DirectionFilterValue: "",
        nearMissLatitudeFilterValue: "",
        nearMissLongitudeFilterValue: "",
        nearMissTimeFilterValue: "",
        nearMissTimeGapFilterValue: "",
        //事故発生一覧フィルター値
        accidentIdFilterValue: "",
        accidentVehicleId1FilterValue: "",
        accidentVehicleId2FilterValue: "",
        accidentLatitudeFilterValue: "",
        accidentLongitudeFilterValue: "",
        accidentTimeFilterValue: "",
        // ビデオ再生時の詳細情報
        nearMissesInfo: {
          id: "",
          area: "",
          vehicleId1Kind: "",
          vehicleId1KindDirection: "",
          vehicleId2Kind: "",
          vehicleId2KindDirection: "",
          nearMissTime: "",
          timeGap: ""
        },
        // 検索方法
        retrievalMethodVlue: 0,
        retrievalItems: [
          { text: "日付日時範囲検索", value: 0 },
          { text: "日付日時個別検索", value: 1 },
          { text: "日付範囲-日時個別検索", value: 2 }
        ],
        // 検索条件
        searchConditions: {
          // 日時範囲検索用
          dates1: [],
          // 日時個別検索
          dates2: [],
          menu2: false,
          // 日付範囲-日時個別検索
          dates3: [],
          menu3: false,
          // 共通
          fromTime: "",
          toTime: "",
          threshold: 30.0,
          // 検索開始・終了日時
          startDateTime: "",
          endDateTime: ""
        }
      };
    },
    /**
     * 算出プロパティ
     */
    computed: {
      dates2RangeList() {
        if (this.searchConditions.dates2.length != 0) {
          let dateList = this.searchConditions.dates2;
          return dateList.sort();
        }
        return "";
      },
      dates3RangeList() {
        if (this.searchConditions.dates3.length == 2) {
          let startDate = new Date(this.searchConditions.dates3[0]);
          let endDate = new Date(this.searchConditions.dates3[1]);
          let dateList = new Array();
          for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            let formatedDate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
            dateList.push(formatedDate);
          }
          return dateList;
        }
        return "";
      },
      /**
       * ヒヤリハット一覧ヘッダ（フィルタリング処理あり）
       */
      nearMissesTableHeader() {
        return [
          { text: "ID", value: "nearMissId", filter: this.nearMissIdFilter },
          { text: "判定エリア", value: "area", filter: this.nearMissAreaFilter },
          { text: "車両1 ID", value: "vehicleId_1", filter: this.nearMissVehicleId1Filter },
          { text: "車両1 物標種別", value: "vehicleId_1_Kind", filter: this.nearMissVehicleId1KindFilter },
          { text: "車両1 方路", value: "vehicleId_1_direction", filter: this.nearMissVehicleId1DirectionFilter },
          { text: "車両2 ID", value: "vehicleId_2", filter: this.nearMissVehicleId2Filter },
          { text: "車両2 物標種別", value: "vehicleId_2_kind", filter: this.nearMissVehicleId2KindFilter },
          { text: "車両2 方路", value: "vehicleId_2_direction", filter: this.nearMissVehicleId2DirectionFilter },
          { text: "緯度", value: "latitude", filter: this.nearMissLatitudeFilter },
          { text: "経度", value: "longitude", filter: this.nearMissLongitudeFilter },
          { text: "発生時刻", value: "nearMisstime", filter: this.nearMissTimeFilter },
          { text: "TimeGap", value: "timeGap", filter: this.nearMissTimeGapFilter },
          { text: "再生", value: "videPath", sortable: false },
          { text: "ダウンロード", value: "downloadVidePath", sortable: false },
          { text: "詳細", value: "nearMissinfo", sortable: false }
        ];
      },
      /**
       * 事故一覧ヘッダ
       */
      accidentsTableHeader() {
        return [
          { text: "ID", value: "accidentId", filter: this.accidentIdFilter },
          { text: "車両1 ID", value: "vehicleId_1", filter: this.accidentVehicleId1Filter },
          { text: "車両2 ID", value: "vehicleId_2", filter: this.accidentVehicleId2Filter },
          { text: "緯度", value: "latitude", filter: this.accidentLatitudeFilter },
          { text: "経度", value: "longitude", filter: this.accidentLongitudeFilter },
          { text: "事故発生時刻", value: "accidentTime", filter: this.accidentTimeFilter }
        ];
      }
    },
    /**
     * 画面情報呼び出し時
     */
    created() {
      this.initialize();
    },
    /**
     * 画面レンダリング完了後
     */
    mounted() {
      this.Search((this.init = true));
      this.isProcessing();
    },
    /**
     * メソッド群
     */
    methods: {
      createTimegap4Disp(item) {
        let n = 3; // 小数点第3位まで残す
        return Math.floor(Number(item) * Math.pow(10, n)) / Math.pow(10, n);
      },
      isProcessing: function() {
        let isProcess = false;
        let threshold = Number(this.searchConditions.threshold);
        if (this.searchConditions.threshold === "" || Number.isNaN(threshold)) {
          isProcess = true;
        }
        switch (this.retrievalMethodVlue) {
          case this.retrievalItems[0].value:
            if (this.searchConditions.dates1.length != 2) {
              isProcess = true;
            }
            break;
          case this.retrievalItems[1].value:
            if (this.searchConditions.dates2.length == 0 || this.searchConditions.menu2 == true) {
              isProcess = true;
            }
            break;
          case this.retrievalItems[2].value:
            if (this.searchConditions.dates3.length == 0 || this.searchConditions.menu3 == true) {
              isProcess = true;
            }
            break;
          default:
            isProcess = true;
        }
        return isProcess;
      },

      initialize() {
        this.setting = this.getSetting();
        this.titleInfo.title = this.$store.state.selectPole.address + " ヒヤリハット・事故情報";
        this.titleInfo.menuList = this.setting.menu.accidents;
        this.titleInfo.poleList = this.setting.poleOnGrafana.poleInfo;
        this.address = this.$store.state.selectPole.address;
        this.initSensorList(this.poleId);

        // 当日の日時データ作成
        const initToDate = getInitToDate();
        // 前日の日時データ作成
        const previousDate = getPreviousDay();
        // 時刻設定
        this.searchConditions.fromTime = "12:30:30";
        this.searchConditions.toTime = "12:30:30";
        // 日時範囲検索用初期値
        this.searchConditions.dates1.push(previousDate[0]);
        this.searchConditions.dates1.push(initToDate[0]);
        // 日時個別検索初期値
        this.searchConditions.dates2.push(initToDate[0]);
        // 日付範囲-日時個別検索初期値
        this.searchConditions.dates3.push(previousDate[0]);
        this.searchConditions.dates3.push(initToDate[0]);
      },
      async initSensorList(poleId) {
        await this.updatePoleList(null, poleId, null, true)
          .then(() => {
            this.setSelectPole(poleId, this.address);
            this.poleInfo = JSON.parse(JSON.stringify(this.$store.state.selectPole));
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },

      async Search(init, ObserverProps) {
        // 検索開始日時
        this.searchConditions.startDateTime = moment(new Date()).format("YYYYDDMM_HHmmss");
        if (!init) {
          const valid = await ObserverProps.validate();
          if (!valid) return;
        }

        this.editingData.nearMisses = [];
        this.editingData.accidents = [];
        const poleId = this.poleId;
        const fromTime = this.searchConditions.fromTime;
        const toTime = this.searchConditions.toTime;
        const threshold = this.searchConditions.threshold;
        const isDeduplication = this.isDeduplication;
        let dates = new Array();
        let isRange = false;
        switch (this.retrievalMethodVlue) {
          case this.retrievalItems[0].value:
            this.searchConditions.dates1.forEach(element => {
              dates.push(element.replaceAll("-", "/"));
            });
            isRange = true;

            break;
          case this.retrievalItems[1].value:
            this.dates2RangeList.forEach(element => {
              dates.push(element.replaceAll("-", "/"));
            });
            isRange = false;

            break;
          case this.retrievalItems[2].value:
            this.dates3RangeList.forEach(element => {
              dates.push(element.replaceAll("-", "/"));
            });
            isRange = false;
            break;
          default:
        }

        this.isLoading = true;
        let promise1 = this.updateNearMissesData(poleId, dates, fromTime, toTime, threshold, isDeduplication, isRange);
        let promise2 = this.updateAccidentsData(poleId, dates, fromTime, toTime, isRange);
        Promise.all([promise1, promise2])
          .then(() => {
            this.isLoading = false;
            this.editingData.nearMisses = this.$store.state.nearMisses;
            this.editingData.accidents = this.$store.state.accidents;
            this.checkNull(this.editingData.nearMisses);
            this.checkNull(this.editingData.accidents);
            this.setMonitorMap();
            // 検索終了日時
            this.searchConditions.endDateTime = moment(new Date()).format("YYYYDDMM_HHmmss");
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      checkNull(data) {
        let targetData = data;
        for (let i = 0; i <= targetData.length; i++) {
          if (targetData[i]) {
            const keys = Object.keys(targetData[i]);
            for (let j = 0; j < keys.length; j++) {
              const key = keys[j];
              if (targetData[i][key] === null) {
                targetData[i][key] = 0;
              }
            }
          }
        }
      },
      /**
       * 出力したヒヤリハット情報をCSV形式でダウンロードできるようにする
       */
      DownloadCsv() {
        const csvSubHeader = "\ufeff" + ",,優先方路,,非優先方路,,,\n";
        const csvHeader = "Date,Id,物標,方路,物標,方路,エリア,TG<" + this.searchConditions.threshold + "\n";
        let csv = csvSubHeader + csvHeader;
        this.editingData.nearMisses.forEach(el => {
          const line =
            el["nearMisstime"] +
            "," +
            el["nearMissId"] +
            "," +
            el["vehicleId_1_Kind"] +
            "," +
            el["vehicleId_1_direction"] +
            "," +
            el["vehicleId_2_kind"] +
            "," +
            el["vehicleId_2_direction"] +
            "," +
            el["area"] +
            "," +
            el["timeGap"] +
            "\n";
          csv += line;
        });
        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "ヒヤリハット一覧" + "_" + this.searchConditions.startDateTime + "-" + this.searchConditions.endDateTime + ".csv";
        link.click();
      },
      setMonitorMap() {
        let latlng = this.$store.state.selectPole.latlng;
        let zoom = this.setting.virtualMap.zoom.default;
        let zoom_min = this.setting.virtualMap.zoom.min;
        let zoom_max = this.setting.virtualMap.zoom.max;

        if (Object.keys(this.map).length === 0) {
          const map = L.map("monitorMap", {
            dragging: true,
            touchZoom: true,
            scrollWheelZoom: true,
            doubleClickZoom: true,
            boxZoom: true,
            tap: true,
            keyboard: true,
            zoomControl: true,
            minZoom: zoom_min,
            maxZoom: zoom_max
          }).setView(latlng, zoom);
          this.map = map;
        } else {
          this.map.remove();
          const map = L.map("monitorMap", {
            dragging: true,
            touchZoom: true,
            scrollWheelZoom: true,
            doubleClickZoom: true,
            boxZoom: true,
            tap: true,
            keyboard: true,
            zoomControl: true,
            minZoom: zoom_min,
            maxZoom: zoom_max
          }).setView(latlng, zoom);
          this.map = map;
        }

        L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: 'Map data © <a href="http://openstreetmap.org/">OpenStreetMap</a>',
          maxZoom: 19
        }).addTo(this.map);

        this.editingData.poleMarkers = L.marker(latlng, { icon: poleIcon }).addTo(this.map);

        if (this.editingData.nearMisses.length !== 0) {
          for (let i = 0; i < this.editingData.nearMisses.length; i++) {
            this.editingData.markerLatlng[0] = this.editingData.nearMisses[i].latitude;
            this.editingData.markerLatlng[1] = this.editingData.nearMisses[i].longitude;
            let nearMissesPoint = L.marker(this.editingData.markerLatlng, { icon: nearMissIcon });
            nearMissesPoint.addTo(this.map);
          }
        }

        if (this.editingData.accidents.length !== 0) {
          for (let i = 0; i < this.editingData.accidents.length; i++) {
            this.editingData.markerLatlng[0] = this.editingData.accidents[i].latitude;
            this.editingData.markerLatlng[1] = this.editingData.accidents[i].longitude;
            let accidentPoint = L.marker(this.editingData.markerLatlng, { icon: accidentIcon });
            accidentPoint.bindPopup(
              `<ul>
							<li>事故ID: ${this.editingData.accidents[i].accidentId}</li>
							<li>事故発生時刻: ${this.editingData.accidents[i].accidentTime}</li>
						</ul>`
            );
            accidentPoint.addTo(this.map);
          }
        }
      },
      closeVideo() {
        this.showMovie = false;
      },
      videoPlay(item) {
        if (item.videoPath == undefined || item.videoPath == null || item.videoPath == "") {
          this.showMovie = false;
          this.dialog.title = "";
          this.dialog.message = this.str.dialog.message.getErrorNearMissInfoVideoNoContent;
          this.dialog.isDialog = true;
          return;
        }
        const videUrl = this.getVideoStrageBucketUrl() + item.videoPath;
        this.videoOptions.sources[0].src = videUrl;
        this.nearMissesInfo.id = item.nearMissId;
        this.nearMissesInfo.area = item.area;
        this.nearMissesInfo.vehicleId1Kind = item.vehicleId_1_Kind;
        this.nearMissesInfo.vehicleId1KindDirection = item.vehicleId_1_direction;
        this.nearMissesInfo.vehicleId2Kind = item.vehicleId_2_kind;
        this.nearMissesInfo.vehicleId2KindDirection = item.vehicleId_2_direction;
        this.nearMissesInfo.nearMissTime = item.nearMisstime.split(".")[0];
        this.nearMissesInfo.timeGap = item.timeGap;
        this.showMovie = true;
      },
      viewNearMissinfo(item) {
        // ヒヤリハットの詳細をGrafanaに表示する処理
        const baseUrl = "https://g-339943402d.grafana-workspace.ap-northeast-1.amazonaws.com";
        const tableName = "t_corrected_pos_info";
        const sensorId = "13633281";
        const type1 = "speed";
        const type2 = "acceleration";
        const vid1 = item.vehicleId_2;
        const vid2 = item.vehicleId_1;
        const class1 = this.checkTargetType(item.vehicleId_2_kind, true);
        const class2 = this.checkTargetType(item.vehicleId_1_Kind, false);
        const utcTimestamp = moment.utc(item.nearMisstime).valueOf();
        const tenSecondsBefore = moment
          .utc(utcTimestamp)
          .subtract(10, "seconds")
          .valueOf();
        const tenSecondsAfter = moment
          .utc(utcTimestamp)
          .add(10, "seconds")
          .valueOf();
        const poleList = this.titleInfo.poleList;
        poleList.forEach(pole => {
          if (this.poleId == pole.poleId) {
            const dashboardSlug = pole.nearMissPath;
            let dashboardUrl = `${baseUrl}/d/${dashboardSlug}&from=${tenSecondsBefore}&to=${tenSecondsAfter}&var-poleid=${this.poleId}&var-table_name1=${tableName}&var-sensorid1=${sensorId}&${class1}&var-vid1=${vid1}&var-table_name2=${tableName}&var-sensorid2=${sensorId}&${class2}&var-vid2=${vid2}&var-val_type1=${type1}&var-val_type2=${type2}`;
            window.open(dashboardUrl, "_blank");
          }
        });
      },
      checkTargetType(kind, isClass1) {
        // 物標種別判定処理
        let result = undefined;
        let classNumber = undefined;
        if (isClass1) {
          classNumber = "1";
        } else {
          classNumber = "2";
        }
        switch (kind) {
          case "Vehicle":
            result = `var-class${classNumber}=0&var-class${classNumber}=1&var-class${classNumber}=2`;
            break;
          case "Motorcycle":
            result = `var-class${classNumber}=3`;
            break;
          case "Bicycle":
            result = `var-class${classNumber}=4`;
            break;
          case "Pedestrian":
            result = `var-class${classNumber}=6`;
            break;
        }
        return result;
      },
      colseNearMissinfo() {
        this.showNearMissinfo = false;
      },
      downloadData(item) {
        const url = this.getVideoStrageBucketUrl() + item.videoPath;
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.click();
        a.remove();
      },
      // 以下、フィルタ類メソッド
      /**
       * ヒヤリハットIDフィルタ作動
       * @param {String} value ヒヤリハット一覧内、ヒヤリハットID
       */
      nearMissIdFilter(value) {
        // フィルタの入力がない場合はTRUEを返す
        if (!this.nearMissIdFilterValue) {
          return true;
        }
        // パラメータの内容を文字列に変換(不要だと思うが)
        value = String(value);
        // フィルタの入力値とパラメータの半角英大文字を小文字にして、
        // パラメータ内にフィルタ入力値が含まれていればTRUEを返す。なければFALSE
        return value.toLowerCase().includes(this.nearMissIdFilterValue.toLowerCase());
      },
      /**
       * ヒヤリハット判定エリア フィルタ作動
       * @param {String} value ヒヤリハット一覧内、判定エリア
       */
      nearMissAreaFilter(value) {
        if (!this.nearMissAreaFilterValue) {
          return true;
        }
        // 判定エリアの先頭部分でフィルタ入力値と一致する場合にTRUEを返す
        const regexp = new RegExp("^" + this.nearMissAreaFilterValue);
        return value.match(regexp) !== null;
      },
      /**
       * ヒヤリハット車両1 ID フィルタ作動
       * @param {String} value 一覧内ヒヤリハット車両1 ID
       */
      nearMissVehicleId1Filter(value) {
        if (!this.nearMissVehicleId1FilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.nearMissVehicleId1FilterValue.toLowerCase());
      },
      /**
       * ヒヤリハット車両1物標種別 フィルタ作動
       * @param {String} value 一覧内ヒヤリハット車両1物標種別
       */
      nearMissVehicleId1KindFilter(value) {
        if (!this.nearMissVehicleId1KindFilterValue) {
          return true;
        }
        return value.toLowerCase().includes(this.nearMissVehicleId1KindFilterValue.toLowerCase());
      },
      /**
       * ヒヤリハット車両1方路 フィルタ作動
       * @param {String} value 一覧内ヒヤリハット車両1方路
       */
      nearMissVehicleId1DirectionFilter(value) {
        if (!this.nearMissVehicleId1DirectionFilterValue) {
          return true;
        }
        return value.toLowerCase().includes(this.nearMissVehicleId1DirectionFilterValue.toLowerCase());
      },
      /**
       * ヒヤリハット車両2 ID フィルタ作動
       * @param {String} value 一覧内ヒヤリハット車両2 ID
       */
      nearMissVehicleId2Filter(value) {
        if (!this.nearMissVehicleId2FilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.nearMissVehicleId2FilterValue.toLowerCase());
      },
      /**
       * ヒヤリハット車両2物標種別 フィルタ作動
       * @param {String} value 一覧内ヒヤリハット車両2物標種別
       */
      nearMissVehicleId2KindFilter(value) {
        if (!this.nearMissVehicleId2KindFilterValue) {
          return true;
        }
        return value.toLowerCase().includes(this.nearMissVehicleId2KindFilterValue.toLowerCase());
      },
      /**
       * ヒヤリハット車両2方路 フィルタ作動
       * @param {String} value 一覧内ヒヤリハット車両2方路
       */
      nearMissVehicleId2DirectionFilter(value) {
        if (!this.nearMissVehicleId2DirectionFilterValue) {
          return true;
        }
        return value.toLowerCase().includes(this.nearMissVehicleId2DirectionFilterValue.toLowerCase());
      },
      /**
       * ヒヤリハット緯度 フィルタ作動
       * @param {String} value 一覧内ヒヤリハット緯度
       */
      nearMissLatitudeFilter(value) {
        if (!this.nearMissLatitudeFilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.nearMissLatitudeFilterValue.toLowerCase());
      },
      /**
       * ヒヤリハット経度 フィルタ作動
       * @param {String} value 一覧内ヒヤリハット経度
       */
      nearMissLongitudeFilter(value) {
        if (!this.nearMissLongitudeFilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.nearMissLongitudeFilterValue.toLowerCase());
      },
      /**
       * ヒヤリハット発生時刻 フィルタ作動
       * @param {String} value 一覧内ヒヤリハット発生時刻
       */
      nearMissTimeFilter(value) {
        if (!this.nearMissTimeFilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.nearMissTimeFilterValue.toLowerCase());
      },
      /**
       * ヒヤリハット タイムギャップ フィルタ作動
       * @param {String} value 一覧内ヒヤリハット タイムギャップ
       */
      nearMissTimeGapFilter(value) {
        if (!this.nearMissTimeGapFilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.nearMissTimeGapFilterValue.toLowerCase());
      },

      accidentIdFilter(value) {
        if (!this.accidentIdFilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.accidentIdFilterValue.toLowerCase());
      },
      accidentVehicleId1Filter(value) {
        if (!this.accidentVehicleId1FilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.accidentVehicleId1FilterValue.toLowerCase());
      },
      accidentVehicleId2Filter(value) {
        if (!this.accidentVehicleId2FilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.accidentVehicleId2FilterValue.toLowerCase());
      },
      accidentLatitudeFilter(value) {
        if (!this.accidentLatitudeFilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.accidentLatitudeFilterValue.toLowerCase());
      },
      accidentLongitudeFilter(value) {
        if (!this.accidentLongitudeFilterValue) {
          return true;
        }
        value = String(value);
        return value.toLowerCase().includes(this.accidentLongitudeFilterValue.toLowerCase());
      },
      accidentTimeFilter(value) {
        if (!this.accidentTimeFilterValue) {
          return true;
        }
        return value.toLowerCase().includes(this.accidentTimeFilterValue.toLowerCase());
      }
    }
  };
</script>

<style>
  .accident {
    padding-top: 40px;
    height: calc(100vh - 50px);
  }

  #monitorMap {
    z-index: 0;
    height: 500px;
    width: 100%;
    margin: 0 auto;
  }

  li {
    list-style: none;
  }

  .searchError {
    color: red;
  }

  .accident {
    position: relative;
    bottom: -10px;
  }

  .videoPlayer {
    position: absolute;
    right: 30px;
    bottom: -20px;
  }
  .timepicker {
    width: 10vw;
    font-size: medium;
  }
  .vue__time-picker .dropdown ul li:not([disabled]).active,
  .vue__time-picker .dropdown ul li:not([disabled]).active:focus,
  .vue__time-picker .dropdown ul li:not([disabled]).active:hover {
    background-color: #1495d1c5;
  }
  .vue__time-picker input.display-time {
    border: none;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.438);
    margin: 10px;
  }
  .vue__time-picker {
    margin: 8px;
  }
  .vue__time-picker .controls {
    z-index: 1;
  }
  .v-application .mb-2 {
    padding: 2px;
  }
  .time {
    color: gray;
    padding: 2px;
    margin: 10px;
  }
  .area1 {
    display: flex;
  }
  .area1-1 {
    width: 950px;
  }
  .area1-2 {
    width: calc(100% - 950px);
    height: 15vw;
  }
  .fId {
    flex: 0 0 8.3333333333%;
    max-width: 5.333333%;
  }
  .fId2 {
    flex: 0 0 8.3333333333%;
    max-width: 7.333333%;
  }
  .download-icon {
    position: absolute;
    right: 20px;
    width: 30px;
    top: 2px;
  }
</style>
