<template>
  <v-dialog width="550" :value="value" v-model="modal" class="state_modal">
    <v-card>
      <v-card-title />
      <v-card-text>
        <component :is="prefix.component" v-bind="prefix.props" v-if="resetFlag"></component>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ModalDisplayComponent",
    props: {
      value: {
        type: Boolean,
        require: true
      },
      text: {
        type: String,
        default: "データ"
      },
      prefix: {
        type: Object
      }
    },
    data() {
      return {
        resetFlag: false
      };
    },
    computed: {
      modal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    },
    methods: {
      render() {
        if (typeof this.$refs.aaa !== "undefined") {
          this.$refs.aaa.render();
        }
      }
    },
    watch: {
      value() {
        if (this.value) {
          this.resetFlag = true;
        } else {
          this.resetFlag = false;
        }
      }
    }
  };
</script>

<style>
  .state_modal {
    height: 200px;
  }
</style>
