<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="date" label="YYYY/MM/DD" v-bind="attrs" v-on="on" :value="value" readonly :disabled="disabledDatePicker" />
    </template>

    <v-date-picker v-model="picker" @click="menu = false" />
  </v-menu>
</template>

<script>
  export default {
    name: "DatePicker",
    props: {
      value: {
        type: String,
        require: true
      },
      disabledDatePicker: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        menu: false
      };
    },

    computed: {
      picker: {
        get() {
          return this.value;
        },
        set(val) {
          this.menu = false;
          this.$emit("input", val);
        }
      },
      date: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    }
  };
</script>
