<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      <v-checkbox v-model="innerValue" :value="item" :label="item" :input-value="checkValue" @input="$emit($event.target.item)" />
    </div>
  </div>
</template>

<script>
  export default {
    name: "CheckBoxes",
    props: {
      items: {
        type: [Array, Object],
        require: true
      },
      checkValue: {
        type: Array,
        require: true
      }
    },
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    }
  };
</script>
