<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field dark v-model="selectSensorDisplay" label="センサー選択" readonly v-on="on" v-bind="attrs" :class="classCom"></v-text-field>
    </template>
    <v-treeview selectable :items="sensorList" v-model="selectSensor" item-text="name" item-key="id" :class="classTree"></v-treeview>
  </v-menu>
</template>

<script>
  import { commonFunction, mapFunction } from "@/mixins/utils";

  export default {
    name: "SelectSensor",
    components: {},
    mixins: [commonFunction, mapFunction],
    props: ["sensorList", "sensorTemp", "classCom", "classTree"],
    data() {
      return {
        selectSensor: this.sensorTemp
      };
    },
    created() {
      this.$emit("setUpSelectSensor", this.sensorTemp);
    },
    watch: {
      selectSensor: {
        immediate: true,
        handler(value) {
          this.$emit("setUpSelectSensor", value);
        }
      }
    },
    computed: {
      selectSensorDisplay() {
        let displaySelectSensorSort = [];
        if (this.selectSensor.length != 0) {
          for (const item of this.selectSensor) {
            let obj = JSON.parse(item);
            displaySelectSensorSort.push(obj.name);
          }
        }
        return displaySelectSensorSort.sort();
      }
    }
  };
</script>

<style>
  .realtimeCom {
    width: 15vw;
    height: 6vh;
    padding-left: 3%;
  }
  .realtimeTree {
    width: auto;
    height: auto;
    background-color: aliceblue;
  }
  .virtualCom {
    width: 20vw;
    height: 6vh;
  }
  .virtualTree {
    width: auto;
    height: auto;
    background-color: aliceblue;
  }
  .historyCom {
    width: 20vw;
    height: 6vh;
  }
  .historyTree {
    width: auto;
    height: auto;
    background-color: aliceblue;
  }
  .v-select__selections input {
    position: relative;
    z-index: 3;
  }
</style>
