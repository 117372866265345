<script>
  import { Line } from "vue-chartjs";
  import moment from "moment";

  /**
   * @class
   * @classdesc 日次通過台数グラフ
   */
  const TrafficDailyChart = {
    extends: Line,
    props: {
      label: Array,
      toDate: moment
    },
    data() {
      return {
        chartContent: {},
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "通過台数"
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0
                }
              }
            ]
          }
        }
      };
    },
    created() {
      // Vuexストアからグラフデータを取得する
      this.chartContent = this.$store.state.aggregations.daily.trafficReportList;
    },
    mounted() {
      // グラフを生成する
      this.renderChart(this.chartData, this.options);
    },
    computed: {
      /**
       * グラフ生成に必要なデータを設定する
       */
      chartData() {
        if (this.chartContent !== void 0 && this.chartContent.current !== void 0 && this.chartContent.current.length > 0) {
          return {
            labels: this.label,
            datasets: [
              {
                title: "当日台数",
                label: "当日台数: " + this.chartContent.currentTotal + "台",
                borderColor: "#c00",
                fill: false,
                data: this.chartContent.current,
                tension: 0
              },
              {
                title: "前日台数",
                label: "前日台数: " + this.chartContent.prevTotal + "台",
                borderColor: "#393",
                fill: false,
                data: this.chartContent.prev,
                tension: 0
              }
            ]
          };
        }
      }
    }
  };

  export default TrafficDailyChart;
</script>
