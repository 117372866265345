<template>
  <div class="MacroAnalysisInfoDataDownload">
    <Loading v-show="isLoading" />
    <v-row class="ml-2 mr-2">
      <v-col cols="12">
        <Bar Title="マクロ分析データダウンロード" />
      </v-col>
    </v-row>
    <div class="data-field">
      <v-text-field class="search-field" v-model="search" append-icon="mdi-magnify" label="検索" single-line hide-details></v-text-field>
      <v-data-table :headers="headers" :items="datas" :items-per-page="5" class="elevation-1" :footer-props="{ 'items-per-page-text': '行/ページ:' }" :search="search">
        <template v-slot:[`item.downloadDataPath`]="{ item }">
          <v-icon v-if="item.downloadDataPath != null" large class="mr-2" color="green" @click="exportData(item)">
            mdi-file-excel
          </v-icon>
          <v-icon v-else large class="mr-2">
            mdi-file-excel
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <Title :titleInfo="titleInfo" />
    <CommonDialog :dialog="dialog" />
  </div>
</template>

<script>
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import CommonDialog from "@/components/CommonDialog";
  import { commonFunction, communicationFunction } from "@/mixins/utils";

  export default {
    name: "MacroAnalysisInfoDataDownload",
    components: {
      Title,
      Loading,
      CommonDialog
    },
    mixins: [commonFunction, communicationFunction],
    data() {
      return {
        poleId: this.$store.state.selectPole.poleId,
        isLoading: false,
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        titleInfo: {
          title: "",
          show: {
            realtime: true,
            multicamera: true,
            virtual: true,
            menu: true
          },
          menuList: []
        },
        search: "",
        headers: [
          {
            text: "データ作成日時",
            align: "start",
            sortable: true,
            value: "createDate"
          },
          { text: "エリア名", sortable: true, value: "areaName" },
          { text: "地域", sortable: true, value: "cityName" },
          { text: "条件", sortable: true, value: "searchCondition" },
          { text: "作成ステータス", sortable: true, value: "creationStatus" },
          {
            text: "エクスポート",
            value: "downloadDataPath",
            sortable: false
          }
        ],
        datas: []
      };
    },
    mounted() {
      this.initialize();
    },
    methods: {
      initialize() {
        this.setting = this.getSetting();
        this.titleInfo.menuList = this.setting.menu.macroAnalysisInfoDataDownload;
        this.setData();
      },
      setData() {
        this.isLoading = true;
        let promise = this.updateMacroAnalysisInfoDataForExcel(this.poleId);
        promise
          .then(() => {
            this.datas = this.$store.state.macroAnalysisInfoDataForExcel;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.dialog.title = this.str.dialog.title.getError;
            this.dialog.message = this.str.dialog.message.getErrorMacroAnalysisInfoDataForExcel;
            this.dialog.isDialog = true;
          });
      },
      exportData(item) {
        const url = this.getSpitsDownloadBucketUrl() + item.downloadDataPath;
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.click();
        a.remove();
      }
    }
  };
</script>

<style scoped>
  .v-data-table {
    white-space: pre-line;
  }
  .data-field {
    width: 97%;
    margin-left: 45px;
    margin-top: 45px;
  }
</style>
