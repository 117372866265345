/**
 * 数式関連メソッド群
 */
const mathFunctions = {
  /**
   * 対象の数値を16進表記にする
   * @param {Number} val 数値
   * @returns 16進表記文字列
   */
  toHexNumber(val) {
    return "0x" + val.toString(16).padStart(8, "0");
  }
};
export default mathFunctions;
