<template>
  <div class="macroAnalysis">
    <Loading v-show="isLoading" />
    <v-row class="ml-2 mr-2">
      <v-col cols="12">
        <Bar Title="マクロ分析" />
      </v-col>
    </v-row>
    <div class="searchField">
      <v-card class="vCardTabel1">
        <v-row>
          <v-col cols="3" class="searchFieldArea1">
            <div class="aggregationPeriod">
              <span>集計期間[A] &nbsp;</span>
              <div class="d-flex">
                <DatePicker v-model="aggregationPeriod.start" @input="aggregationPeriod.start = $event" />
                <p class="pt-3">～&nbsp;</p>
                <DatePicker v-model="aggregationPeriod.end" @input="aggregationPeriod.end = $event" />
              </div>
            </div>
            <div class="comparisonPeriod">
              <span>比較期間[B] &nbsp;</span>
              <div class="d-flex">
                <DatePicker v-model="comparisonPeriod.start" @input="comparisonPeriod.start = $event" />
                <p class="pt-3">～&nbsp;</p>
                <DatePicker v-model="comparisonPeriod.end" @input="comparisonPeriod.end = $event" />
              </div>
            </div>
          </v-col>
          <v-col class="d-flex searchFieldArea2">
            <div class="vselect">
              <v-select :items="directionItems" v-model="directionValue" label="方路" dense></v-select>
            </div>
            <p class="pt-3">&nbsp;&nbsp;&nbsp;</p>
            <div class="vselect">
              <v-select :items="ledItems" v-model="ledValue" label="LED" dense></v-select>
            </div>
            <p class="pt-3">&nbsp;&nbsp;&nbsp;</p>
            <div class="vselect">
              <v-select :items="aggregationTargetItems" v-model="aggregationTargetValue" label="集計対象" dense></v-select>
            </div>
            <p class="pt-3">&nbsp;&nbsp;&nbsp;</p>
            <div class="vselect">
              <v-select :items="aggregationMethodItems" v-model="aggregationMethodValue" label="集計方法" dense></v-select>
            </div>
            <p class="pt-3">&nbsp;&nbsp;&nbsp;</p>
            <div class="tooltip">
              <span class="tooltip-text">対象の分析データをExcelファイルに出力する</span>
              <Button label="分析データ作成" @click="createDataExcel()" />
            </div>
            <p class="pt-3">&nbsp;</p>
            <div>
              <Button class="searchFieldAreaButton" label="データ読込" @click="getData()" />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <MacroAnalysisTabel ref="MacroAnalysisTabel" />
    <Title :titleInfo="titleInfo" />
    <CommonDialog :dialog="dialog" />
  </div>
</template>

<script>
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import MacroAnalysisTabel from "@/components/macroAnalysis/MacroAnalysisTabel";
  import CommonDialog from "@/components/CommonDialog";
  import { getDate, getPreviousDay } from "@/utils/day.js";
  import { commonFunction, communicationFunction } from "@/mixins/utils";

  export default {
    name: "MacroAnalysis",
    components: {
      Title,
      Loading,
      MacroAnalysisTabel,
      CommonDialog
    },
    mixins: [commonFunction, communicationFunction],
    data() {
      return {
        isViewMap: true,
        map: {},
        isLoading: false,
        titleInfo: {
          title: "",
          show: {
            realtime: true,
            multicamera: true,
            virtual: true,
            menu: true
          },
          menuList: []
        },
        aggregationPeriod: {
          start: undefined,
          end: undefined
        },
        comparisonPeriod: {
          start: undefined,
          end: undefined
        },
        directionItems: ["1", "2", "3", "4", "5"],
        directionValue: "1",
        ledItems: ["ON", "OFF", "EITHER"],
        ledValue: "ON",
        aggregationTargetItems: ["自動四輪車", "自動二輪車", "自転車", "歩行者"],
        aggregationTargetValue: "自転車",
        aggregationMethodItems: ["1hour", "3hours", "6hours", "12hours", "1day", "3days", "1week", "2weeks", "1month"],
        aggregationMethodValue: "1hour",
        // 送信用オブジェクト
        sendData: {
          poleId: this.$store.state.selectPole.poleId,
          address: undefined,
          aggregationPeriod: {
            start: undefined,
            end: undefined
          },
          comparisonPeriod: {
            start: undefined,
            end: undefined
          },
          directionValue: undefined,
          ledValue: undefined,
          aggregationTargetValue: undefined,
          aggregationMethodValue: undefined
        },
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        }
      };
    },
    mounted() {
      this.initialize();
    },

    methods: {
      async initialize() {
        this.aggregationPeriod.start = getPreviousDay()[0];
        this.aggregationPeriod.end = getDate()[0];
        this.comparisonPeriod.start = getPreviousDay()[0];
        this.comparisonPeriod.end = getDate()[0];
        this.setting = this.getSetting();
        this.titleInfo.menuList = this.setting.menu.macroAnalysis;
        await this.getAddress();
      },
      async getAddress() {
        try {
          this.Loading = true;
          let response = await this.addressByCoordinates(this.$store.state.selectPole.latlng[0], this.$store.state.selectPole.latlng[1]);
          this.Loading = false;
          if (response.ok) {
            let address = "";
            if (response.body.address.city != undefined) {
              address += response.body.address.city;
            }
            if (response.body.address.county != undefined) {
              address += response.body.address.county;
            }
            if (response.body.address.hamlet != undefined) {
              address += response.body.address.hamlet;
            }
            if (response.body.address.suburb != undefined) {
              address += response.body.address.suburb;
            }
            if (response.body.address.neighbourhood != undefined) {
              address += response.body.address.neighbourhood;
            }
            if (response.body.address.road != undefined) {
              address += response.body.address.road;
            }
            this.sendData.address = address;
          }
        } catch {
          this.sendData.address = "";
        }
      },
      getData() {
        this.sendData.aggregationPeriod.start = this.aggregationPeriod.start;
        this.sendData.aggregationPeriod.end = this.aggregationPeriod.end;
        this.sendData.comparisonPeriod.start = this.comparisonPeriod.start;
        this.sendData.comparisonPeriod.end = this.comparisonPeriod.end;
        this.sendData.directionValue = this.directionValue;
        this.sendData.ledValue = this.ledValue;
        this.sendData.aggregationTargetValue = this.aggregationTargetValue;
        this.sendData.aggregationMethodValue = this.aggregationMethodValue;
        this.$refs.MacroAnalysisTabel.getData(this.sendData);
      },
      createDataExcel() {
        if (this.sendData.aggregationPeriod.start == undefined) {
          this.dialog.title = "";
          this.dialog.message = "データ読込が行われていません。\nデータ読込を行ってください";
          this.dialog.isDialog = true;
          return;
        }
        if (this.$store.state.macroAnalysisInfo.enter.number.length == 0) {
          this.dialog.title = "";
          this.dialog.message = "現在のパラメータ設定ではデータが存在していません \r\n再度パラメータを設定後 \r\nデータ読込を行ってください";
          this.dialog.isDialog = true;
          return;
        }
        this.isLoading = true;
        let promise = this.createMacroAnalysisInfoDataForExcel(
          this.sendData.address,
          this.sendData.poleId,
          this.sendData.aggregationPeriod.start.replaceAll("-", "/") + " 00:00:00",
          this.sendData.aggregationPeriod.end.replaceAll("-", "/") + " 00:00:00",
          this.sendData.comparisonPeriod.start.replaceAll("-", "/") + " 00:00:00",
          this.sendData.comparisonPeriod.end.replaceAll("-", "/") + " 00:00:00",
          this.sendData.directionValue,
          this.sendData.ledValue,
          this.sendData.aggregationTargetValue,
          this.sendData.aggregationMethodValue
        );
        promise
          .then(() => {
            this.isLoading = false;
            this.dialog.title = "";
            this.dialog.message = "作成依頼成功\r\nマクロ分析データダウンロード画面から\r\nファイルをダウンロードしてください\r\nファイル作成には時間がかかります。";
            this.dialog.isDialog = true;
          })
          .catch(() => {
            this.isLoading = false;
            this.dialog.title = "";
            this.dialog.message = "作成依頼失敗";
            this.dialog.isDialog = true;
          });
      }
    }
  };
</script>

<style scoped>
  .dataScreen {
    width: 1000px;
    height: 1000px;
  }
  #accudentinfoMap {
    z-index: 0;
    height: 800px;
    width: 100%;
    margin: 0 auto;
  }
  #macroAnalysis {
    z-index: 0;
    height: 500px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  li {
    list-style: none;
  }

  .searchError {
    color: red;
  }
  .searchField {
    position: relative;
    top: 15%;
    text-align: center;
    width: 100%;
    left: 5%;
  }
  .vCardTabel1 {
    width: 90%;
  }

  .aggregationPeriod {
    display: flex;
  }
  .aggregationPeriod > span {
    margin: 20px;
    text-align: center;
    white-space: nowrap;
  }
  .comparisonPeriod {
    display: flex;
  }
  .comparisonPeriod > span {
    margin: 20px;
    text-align: center;
    white-space: nowrap;
  }
  .searchFieldArea1 {
    margin-top: 8px;
  }
  .searchFieldArea2 {
    margin-top: 25px;
  }
  .vselect {
    width: -webkit-fill-available;
  }
  .searchFieldAreaButton {
    width: 10%;
  }
  .tooltip {
    position: relative;
    cursor: pointer;
  }
  .tooltip-text {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 125px;
    display: inline-block;
    padding: 5px;
    white-space: nowrap;
    font-size: 0.8rem;
    line-height: 1.3;
    background: #333;
    color: #fff;
    border-radius: 3px;
    transition: 0.3s ease-in;
  }
  .tooltip:hover .tooltip-text {
    opacity: 1;
    visibility: visible;
  }
</style>
