<template>
  <Card>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>走行台数:</td>
            <td>
              {{ `${trafficTotal}台(前日差分:${checkSign(diff_trafficTotal)}台)` }}
            </td>
          </tr>
          <tr>
            <td>ヒヤリハット発生件数:</td>
            <td>
              {{ `${nearMissesTotal}件(前日差分:${checkSign(diff_nearMissesTotal)}件)` }}
            </td>
          </tr>
          <tr>
            <td>交差点進入時の速度[平均]:</td>
            <td>
              {{ `${Math.floor(accelerationMaxAverage * 100) / 100}km/h(前日差分:${checkSign(Math.floor(diff_accelerationMaxAverage * 100) / 100)}km/h)` }}
            </td>
          </tr>
          <tr>
            <td>交差点進入時の速度[最大]:</td>
            <td>
              {{ `${Math.floor(topAcceleration * 100) / 100}km/h(前日差分:${checkSign(Math.floor(diff_topAcceleration * 100) / 100)}km/h)` }}
            </td>
          </tr>
          <tr>
            <td>{{ parkedThr }}分以上の駐停車車両:</td>
            <td>
              {{ `${parkingTotal}台(前日差分:${checkSign(diff_parkingTotal)}台)` }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </Card>
</template>

<script>
  export default {
    name: "Table",
    props: {
      trafficTotal: {
        type: Number,
        default: 0
      },
      nearMissesTotal: {
        type: Number,
        default: 0
      },
      accelerationMaxAverage: {
        type: Number,
        default: 0
      },
      topAcceleration: {
        type: Number,
        default: 0
      },
      parkingTotal: {
        type: Number,
        default: 0
      },
      diff_trafficTotal: {
        type: Number,
        require: true
      },
      diff_nearMissesTotal: {
        type: Number,
        require: true
      },
      diff_accelerationMaxAverage: {
        type: Number,
        require: true
      },
      diff_topAcceleration: {
        type: Number,
        require: true
      },
      diff_parkingTotal: {
        type: Number,
        require: true
      },
      parkedThr: {
        type: Number,
        require: true
      }
    },

    methods: {
      checkSign(value) {
        if (Math.sign(value) === 1) {
          return `+${value}`;
        } else {
          return value;
        }
      }
    }
  };
</script>

<style></style>
