<template>
  <div id="historydata_view">
    <v-container fluid fill-height>
      <v-row>
        <v-col cols="6">
          <!-- Real映像ブロック -->
          <v-card id="history_camera" elevation="5" outlined>
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 8vh">
              <span class="py-0">カメラ映像</span>
              <v-spacer />
              <v-select
                dark
                v-model="selectCamera"
                :items="cameraList"
                item-text="name"
                item-value="filePath"
                label="カメラ選択"
                autowidth
                return-object
                style="width: 10vw; height: 6vh"
              />
              <v-spacer />
              <div
                class="py-0"
                style="
                  font-size: 22px;
                  color: white;
                  background-color: black;
                  width: 16vw;
                  text-align: center;
                  vertical-align: middle;
                  height: 4vh;
                "
              >
                <span>再生時間 : </span>
                <span class="py-0" v-text="currentTimeAsString" />
              </div>
            </v-card-title>
            <v-divider />
            <div id="history_video" align="center">
              <video-player
                ref="video"
                v-show="showMovie"
                :options="videoOptions"
                v-on:updateCurrentTime="updateCurrentTime"
                v-on:startVideo="startVideo"
                v-on:pauseVideo="pauseVideo"
              />
              <div id="realtime_logo" v-if="!showMovie"></div>
            </div>
          </v-card>

          <!-- 交差点内物標情報 -->
          <v-card id="history_location" elevation="5" outlined>
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 5vh">
              <span class="py-0">交差点内物標情報</span>
              <v-spacer />
              <v-btn color="blue-grey" dark v-on:click="onClickTableSet" height="4vh">
                <v-icon>mdi-table-settings</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider />
            <LocationInfoTable ref="location_table" :locationTable="locationTable" :selectSensor="selectSensor" :itemsPerPage="6" v-on:selectObject="selectObject" />
          </v-card>
        </v-col>

        <v-col cols="6">
          <!-- Virtual映像ブロック -->
          <v-card id="history_vmap" elevation="5" outlined>
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 8vh">
              <span class="py-0">VIRTUAL映像</span>
              <SelectSensor :sensorList="sensorList" :sensorTemp="selectSensorTemp" @setUpSelectSensor="setUpSelectSensor" classCom="historyCom" classTree="historyTree" />
            </v-card-title>
            <v-divider />
            <div align="center">
              <VirtualMap ref="virtual_map" :accident="true" :selectSensor="selectSensor" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-main>
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" />
    <Loading v-show="isLoading" />
    <CommonDialog :dialog="dialog" />
  </div>
</template>

<script>
  import "video.js/dist/video-js.css";
  import moment from "moment";
  import VideoPlayer from "@/components/VideoPlayer";
  import Loading from "@/components/Loading";
  import CommonDialog from "@/components/CommonDialog";
  import LocationInfoTable from "@/components/LocationInfoTable";
  import Title from "@/components/Title";
  import VirtualMap from "@/components/VirtualMap";
  import SelectSensor from "@/components/SelectSensor";
  import { commonFunction, timerFunction, communicationFunction } from "@/mixins/utils";

  export default {
    name: "HistoryDataView",
    components: {
      Title,
      CommonDialog,
      Loading,
      LocationInfoTable,
      VirtualMap,
      VideoPlayer,
      SelectSensor
    },
    mixins: [commonFunction, timerFunction, communicationFunction],
    data: function() {
      return {
        setting: undefined,
        address: undefined,
        titleInfo: {
          title: undefined,
          show: {
            back: true,
            menu: true
          },
          menuList: undefined
        },
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        isLoading: true,
        selectCamera: undefined,
        cameraList: [],
        movieList: [],
        sensorList: [],
        selectSensor: [],
        selectSensorTemp: [],
        poleInfo: undefined,
        poleId: undefined,
        locationTable: undefined,
        accidentTime: undefined,
        currentTime: undefined,
        currentTimeAsString: undefined,
        directTransit: false,
        diffTime: undefined,
        videoOptions: {
          autoplay: false,
          controls: true,
          sources: [
            {
              src: "",
              type: "video/mp4"
            }
          ]
        },
        movieToken: undefined,
        showMovie: false,
        timer: undefined,
        ledSensorList: []
      };
    },
    created() {
      this.initialize();
    },
    beforeDestroy() {
      this.stopPosListWithPoleUpdateTimer();
    },
    methods: {
      initialize() {
        this.directTransit = this.$route.query.direct;
        this.setting = this.getSetting();
        this.titleInfo.title = this.$store.state.selectAccident.location + " " + this.$store.state.selectAccident.accidentTime;
        this.poleId = this.$store.state.selectAccident.poleId;
        this.address = this.$store.state.selectPole.address;
        this.diffTime = this.setting.common.histryDiffTime;

        if (this.directTransit != "false") {
          this.titleInfo.menuList = this.setting.menu.historyDataDirect;
        } else {
          this.titleInfo.menuList = this.setting.menu.historyList;
        }

        this.locationTable = this.setting.locationInfoTableType1;
        this.movieList = this.$store.state.selectAccident.movieList;
        this.accidentTime = JSON.parse(JSON.stringify(this.$store.state.selectAccident.accidentTime));
        this.initCameraList();

        this.initialCurrentTime();
        this.initSensorList(this.poleId, this.$store.state.selectAccident.accidentTime);
      },
      async initSensorList(poleId, datetime) {
        let promise = this.updatePoleList(null, poleId, datetime, true);
        promise
          .then(() => {
            this.setSelectPole(poleId, this.address);
            this.sensorList = this.getSensorSettingList();
            if (this.sensorList.length != 0) {
              for (const item of this.sensorList) {
                for (const itemData of item.children) {
                  let obj = JSON.parse(itemData.id);
                  if (obj.kind == "表示板") {
                    this.ledSensorList.push(obj);
                  }
                }
              }
            }
            this.poleInfo = JSON.parse(JSON.stringify(this.$store.state.selectPole));
            this.initPosList(poleId);
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showErrorDialig(this.str.dialog.title.getError, this.str.dialog.message.getErrorSensorInfo);
          });
      },
      initPosList(poleId) {
        this.timeoutPosListWithPoleTimer(poleId);
      },
      initCameraList() {
        if (this.movieList.length == 0) {
          return;
        }
        this.movieList.sort(function(a, b) {
          if (a.cameraKind < b.cameraKind) return -1;
        });
        let count = 0;
        let oldKind = undefined;
        this.movieList.forEach(movie => {
          if (oldKind != movie.cameraKind) {
            oldKind = undefined;
            count = 0;
          }
          let name = this.getMovieName(movie.cameraKind);
          let kindCount = this.movieList.reduce((prev, item) => {
            return prev + (item.cameraKind == movie.cameraKind ? 1 : 0);
          }, 0);
          if (kindCount != 1) {
            count += 1;
            name += " " + count.toString();
          }
          this.cameraList.push({
            name: name,
            path: movie.filePath
          });
          oldKind = movie.cameraKind;
        });
        if (this.cameraList.length != 0) {
          this.selectCamera = this.cameraList[0];
        }
      },
      initialCurrentTime() {
        let datetime = moment(this.accidentTime).subtract(this.diffTime, "s");
        this.currentTimeAsString = datetime.format("HH:mm:ss");
        this.currentTime = datetime;
      },
      showErrorDialig(title, message) {
        this.dialog.title = title;
        this.dialog.message = message;
        this.dialog.isDialog = true;
      },
      async updateMovieCredential() {
        let promise = this.updateMovieToken();
        promise
          .then(() => {
            this.movieToken = this.$store.state.userInfo.movieToken;
            this.setMoviePath(this.selectCamera.path);
            this.showMovie = true;
            const self = this;
            this.timer = setInterval(function() {
              clearInterval(self.timer);
              self.$refs.video.updateVideoPlayer();
            }, this.setting.common.selectMovieInterval);
          })
          .catch(() => {
            this.showErrorDialig(this.str.dialog.title.authError, this.str.dialog.message.authErrorSigned);
          });
      },
      setMoviePath(path) {
        let movieUrl = this.getMovieUrl();
        let credential = this.getMovieCredential(this.movieToken, path);
        if (credential != undefined) {
          this.videoOptions.sources[0].src = movieUrl + path + credential;
        } else {
          this.videoOptions.sources[0].src = "";
        }
      },
      async timeoutPosListWithPoleTimer(poleId) {
        let datetime = this.currentTime.format("YYYY/MM/DD HH:mm:ss");
        let promise = this.updatePosListWithPole(poleId, datetime);
        promise
          .then(() => {
            this.setSelectPole(poleId, this.address);
            this.updateSensorList(this.poleInfo, this.$store.state.selectPole, this.updatePoleEvent, this.updateSensorEvent);
            this.$refs.virtual_map.updatePos();
            this.$refs.location_table.update();
          })
          .catch(error => {
            if (error == this.errcode.noContent) {
              this.setSelectPole(poleId, this.address);
              this.updateSensorList(this.poleInfo, this.$store.state.selectPole, this.updatePoleEvent, this.updateSensorEvent);
              this.$refs.virtual_map.updatePos();
              this.$refs.location_table.update();
            }
          });
      },
      updateCurrentTime(time) {
        let datetime = moment(this.accidentTime).subtract(this.diffTime, "s");
        this.currentTimeAsString = datetime.add(time, "seconds").format("HH:mm:ss");
        this.currentTime = datetime;
      },
      updatePoleEvent() {
        this.$refs.virtual_map.updatePole();
        this.poleInfo.latlng = JSON.parse(JSON.stringify(this.$store.state.selectPole.latlng));
      },
      updateSensorEvent() {
        this.sensorList = this.getSensorSettingList();
        this.selectSensor = this.updateSelectSensor(this.selectSensor, this.sensorList);
        this.$refs.virtual_map.updateSensor();
        this.poleInfo.sensorList = JSON.parse(JSON.stringify(this.$store.state.selectPole.sensorList));
      },
      async onClickTableSet() {
        await this.$refs.location_table.columSelectModal();
      },
      selectObject(latlng) {
        this.$refs.virtual_map.setCenter(latlng);
      },
      startVideo() {
        let datatime = this.currentTime.format("YYYY/MM/DD HH:mm:ss");
        this.updatePosListWithPole(this.poleId, datatime);
        this.startPosListWithPoleUpdateTimer(this.poleId, this.setting.common.posUpdateInterval, this.timeoutPosListWithPoleTimer);
      },
      pauseVideo() {
        this.stopPosListWithPoleUpdateTimer();
      },
      setUpSelectSensor(value) {
        this.selectSensor = [];
        if (value.length != 0) {
          for (const item of value) {
            let obj = JSON.parse(item);
            this.selectSensor.push(obj);
          }
        }
      }
    },
    watch: {
      selectCamera() {
        this.$refs.video.pauseVideoPlayer();
        this.updateMovieCredential();
      }
    }
  };
</script>

<style>
  #history_camera {
    height: 50vh;
  }
  #history_location {
    height: 40vh;
  }
  #history_vmap {
    height: 90vh;
  }
  #history_video {
    background-color: #353535;
    height: 50vh;
    width: 100%;
  }
  #history_logo {
    background-color: #353535;
    height: 50;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    opacity: 0.6;
  }

  .theme--light.v-list {
    background: gray;
  }
  .v-list-item__content {
    color: white;
  }
  .theme--light.v-list-item:hover:before {
    opacity: 0.6;
  }
</style>
