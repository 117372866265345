<template>
  <div id="collection_view">
    <v-container id="collection_container">
      <v-row>
        <v-col>
          <v-tabs background-color="#eef" v-model="selectedTabName">
            <v-tab v-for="item in collectionTabMenu" :key="item.tab" :disabled="item.disabled" class="py-0 tab_text">
              {{ item.tab }}
            </v-tab>
            <v-tab-item>
              <passing-object-collection @set-loading="setLoading" @set-error="displayDialog" />
            </v-tab-item>
            <v-tab-item>一時停止数一覧 ダミー</v-tab-item>
            <v-tab-item>ヒヤリハット件数一覧 ダミー</v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
    <v-main style="padding: 0;">
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" @selectPoint="selectPoint" @enterPoint="enterPoint" />
    <Loading v-show="isLoading" />
    <common-dialog :dialog="dialogMessage" />
  </div>
</template>

<script>
  /**
   * CollectionView.vue
   * 集計画面
   */
  import PassingObjectCollection from "@/components/collections/PassingObjectsCollection";
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import CommonDialog from "@/components/CommonDialog";
  import { commonFunction, communicationFunction } from "@/mixins/utils";

  const CollectionView = {
    components: {
      PassingObjectCollection,
      Title,
      Loading,
      CommonDialog
    },
    mixins: [commonFunction, communicationFunction],
    data() {
      return {
        // セッティング情報保存用
        setting: [],
        // タイトル情報
        titleInfo: {
          title: "集計画面",
          show: {
            logout: true,
            point: false,
            menu: true
          },
          pointList: [],
          menuList: []
        },
        // 集計画面メニュータブ
        collectionTabMenu: [
          { tab: "通過物標件数", disabled: false },
          { tab: "一時停止数", disabled: true },
          { tab: "ヒヤリハット件数", disabled: true }
        ],
        selectedTabName: "",
        // ローディングアイコン表示フラグ
        isLoading: false,
        // ダイアログ表示内容
        dialogMessage: {
          isDialog: false,
          title: "",
          message: ""
        },
        selectPoint: "",
        enterPoint: ""
      };
    },
    created() {
      this.setting = this.getSetting();
      // 右サイドメニューを取得する
      this.titleInfo.menuList = this.setting.menu.collection;
    },
    methods: {
      /**
       * ローディングアイコン表示を切り替える
       * @param {Boolean} flag 表示フラグ
       */
      setLoading(flag) {
        this.isLoading = flag;
      },
      /**
       * タイトル、メッセージを設定してダイアログを表示する
       * @param {String} title タイトル
       * @param {String} message メッセージ
       */
      displayDialog(title, message) {
        this.dialogMessage.title = title;
        this.dialogMessage.message = message;
        this.dialogMessage.isDialog = true;
      }
    }
  };

  export default CollectionView;
</script>

<style lang="scss" scoped>
  #collection_view {
    position: absolute;
    top: 48px;
    height: calc(100vh - 48px);
    width: calc(100vw - 20px);
  }
  #collection_container {
    max-width: 100vw;
  }
  .tab_text {
    font-size: 16px;
    font-weight: bold;
  }
</style>
