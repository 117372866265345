<template>
  <v-dialog width="350" :value="value" v-model="modal" class="state_modal">
    <v-card>
      <v-card-title />
      <v-card-text>
        <div class="text-center">{{ text }}がありません</div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "Modal",
    props: {
      value: {
        type: Boolean,
        require: true
      },
      text: {
        type: String,
        default: "データ"
      }
    },
    computed: {
      modal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    }
  };
</script>

<style>
  .state_modal {
    height: 200px;
  }
</style>
