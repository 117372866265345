<template>
  <div id="direction_list">
    <v-simple-table dense light>
      <template v-slot: default>
        <tbody>
          <tr v-for="item in directionList" :key="item.value">
            <td>
              <v-checkbox v-model="selectedDirection" :value="item.value">
                <template v-slot:label>
                  <img :src="item.url" :style="item.style" />
                  {{ item.name }}
                </template>
              </v-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
  import directionIcon from "@/assets/img/object/direction.svg";
  import directions from "@/assets/setting/directions.json";

  /**
   * @class
   * @classdesc 方路一覧
   */
  const DirectionList = {
    props: {
      mode: Number,
      dir: Number
    },
    data() {
      return {
        directionList: [],
        selectedDirection: []
      };
    },
    created() {
      // 方路一覧を設定する
      this.directionList = this.setDirList();
      // デフォルトですべて選択済みにする
      this.directionList.forEach(dirction => {
        this.selectedDirection.push(dirction.value);
      });
      // Vuexストアの内容も更新する
      this.updateSelectedDirectionList();
    },
    watch: {
      selectedDirection() {
        this.updateSelectedDirectionList();
      }
    },
    methods: {
      /**
       * 集計対象及びIN、OUTの分類に応じて、対象のVuexストアを更新する
       */
      updateSelectedDirectionList() {
        switch (this.mode) {
          // 通過台数
          case 0:
            if (this.dir === 1) {
              // IN
              this.$store.dispatch("updateFilteringDirIn", this.selectedDirection);
            } else {
              // OUT
              this.$store.dispatch("updateFilteringDirOut", this.selectedDirection);
            }
            break;
          // ヒヤリハット 優先
          case 1:
            if (this.dir === 1) {
              this.$store.dispatch("updatePriorityDirIn", this.selectedDirection);
            } else {
              this.$store.dispatch("updatePriorityDirOut", this.selectedDirection);
            }
            break;
          // ヒヤリハット 非優先
          case 2:
            if (this.dir === 1) {
              this.$store.dispatch("updateNonPriorityDirIn", this.selectedDirection);
            } else {
              this.$store.dispatch("updateNonPriorityDirOut", this.selectedDirection);
            }
            break;
          default:
        }
      },
      /**
       * 一覧表を作成する
       */
      setDirList() {
        let work = [];
        for (let dir of directions.directions) {
          // アイコン画像は固定で、CSSで回転設定を行う
          const iconStyle = {
            width: "16px",
            height: "16px",
            transform: "rotate(" + dir.dig + "deg)",
            margin: "0 2px 0 0"
          };
          work.push({
            name: dir.name,
            value: dir.dig,
            url: directionIcon,
            style: iconStyle
          });
        }
        return work;
      }
    }
  };

  export default DirectionList;
</script>

<style lang="scss">
  #direction_list {
    table {
      tbody {
        tr {
          td {
            padding: 0;
          }
        }
      }
    }
  }
</style>
