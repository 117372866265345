<template>
  <div id="selectarea_view">
    <v-container fluid fill-height>
      <SelectAreaMenu id="selectarea_menu" :poleList="displayPoleList" @pole-selected="poleSelected" />
      <SelectArea ref="map" id="selectarea_map" align="center" :poleList="poleList" />
      <DataSelectDialog :dialog="dialog" v-on:dialogClose="dialogClose" />
    </v-container>
    <v-main style="padding: 0;">
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" v-on:selectPoint="selectPoint" v-on:enterPoint="enterPoint" />
    <Loading v-show="isLoading" />
    <CommonDialog :dialog="errorDialog" />
  </div>
</template>

<script>
  import SelectArea from "@/components/SelectArea";
  import SelectAreaMenu from "@/components/selectArea/SelectAreaMenu";
  import DataSelectDialog from "@/components/DataSelectDialog";
  import CommonDialog from "@/components/CommonDialog";
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import { STORE_ACTIONS } from "@/utils/config";
  import { commonFunction, communicationFunction, mapFunction } from "@/mixins/utils";

  /**
   * SelectAreaView.vue
   * 地域選択画面
   */
  export default {
    name: "SelectAreaView",
    components: {
      DataSelectDialog,
      CommonDialog,
      SelectArea,
      SelectAreaMenu,
      Title,
      Loading
    },
    mixins: [commonFunction, communicationFunction, mapFunction],
    data() {
      return {
        setting: undefined,
        dialog: {
          isDialog: false,
          name: ""
        },
        titleInfo: {
          title: "検証一覧",
          show: {
            logout: true,
            point: false,
            menu: true
          },
          pointList: undefined,
          menuList: undefined
        },
        // エリア一覧
        areaList: [],
        // ポール一覧
        poleList: [],
        poleData: {},
        displayPoleList: [],
        // エラーダイアログ
        errorDialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        isLoading: true
      };
    },
    /**
     * 画面アクセス時
     */
    created() {
      this.initialize();
    },
    mounted() {
      // ポール一覧を取得済みの場合、地図上にマーカーを設定する
      if (this.$store.state.poleList !== void 0 && this.$store.state.poleList.length > 0) {
        this.$refs.map.addPoleMarker(this.$store.state.poleList);
      }
    },
    watch: {
      poleList: {
        async handler(val) {
          let areaPoleList = [];
          for await (let pole of val) {
            // ポール情報にあるエリアIDから、対象のエリア情報が存在するかを検索する
            const areaData = this.areaList.find(area => area.areaId === pole.areaId);
            // 見つかったら付加情報の追加を始める
            if (areaData !== void 0) {
              // 緯度、経度情報
              pole.latlng = [pole.latitude, pole.longitude];
              // ポールの住所を設定
              await this.setAddress(pole);
              // ポールのステータスを設定（0: 未稼働、1: 正常稼働、2: 異常)
              let inProgress = false;
              let onError = false;
              // ポール情報に紐づくセンサー一覧を調べる
              for (let sensor of pole.sensorList) {
                // いずれかのセンサーの稼働しているなら稼働中と判定する
                if (sensor.sensorStatus === 0) {
                  inProgress = true;
                }
                // いずれかのセンサーで異常が検知されたら異常と判定する
                if (sensor.errorCode !== 0) {
                  onError = true;
                }
              }
              // 未稼働で異常が検知されなければ未稼働と判定する
              if (!inProgress) {
                if (!onError) {
                  pole.status = 0;
                }
              } else {
                // 稼働中で異常が検知されなければ正常稼働と判定する
                if (onError) {
                  pole.status = 2;
                } else {
                  pole.status = 1;
                }
              }
              // メニュー表示用ポール一覧に、ポールに紐づくエリア情報が存在するか検索する
              let target = areaPoleList.find(area => area.areaId === areaData.areaId);
              // 見つからなかった場合、新たにエリア情報を作成し、メニュー表示用ポール一覧に追加する
              if (target === void 0) {
                let work = areaData;
                // ポールの本数を設定する
                work["poleNumber"] = 1;
                work["poleList"] = [pole];
                areaPoleList.push(work);
              } else {
                // 見つかった場合、そのポール一覧にポール情報を追加する
                target.poleList.push(pole);
                // ポールの本数を1加算する
                target.poleNumber++;
              }
            }
          }
          this.setDisplayPoleList(areaPoleList);
        },
        deep: true
      },
      displayPoleList: {
        async handler() {
          this.isLoading = false;
        },
        deep: true
      }
    },
    methods: {
      /**
       * 初期処理
       */
      initialize() {
        this.setting = this.getSetting();
        this.titleInfo.menuList = this.setting.menu.selectArea;
        // エリア、ポール情報を取得する
        // すでに取得済みの場合はAPIから情報を取得しない
        if (this.$store.state.areaList !== void 0 && this.$store.state.areaList.length > 0) {
          this.areaList = this.$store.state.areaList;
          if (this.$store.state.areaPoleList !== void 0 && this.$store.state.areaPoleList.length > 0) {
            this.displayPoleList = this.$store.state.areaPoleList;
            this.isLoading = false;
          } else {
            this.getPoleList();
          }
        } else {
          this.getAreaList();
        }
      },
      /**
       * エリア、ポール情報を取得する
       */
      getAreaList() {
        // エリア情報を取得する
        const getAreaFunc = this.updateAreaList();
        getAreaFunc
          .then(() => {
            // エリア情報を取得できたら、Vuexストアに保存された一覧を設定する
            this.areaList = this.$store.state.areaList;
            this.getPoleList();
          })
          .catch(error => {
            // 失敗したらエラーダイアログを表示する
            const title = this.str.dialog.title.getError;
            let message = this.str.dialog.message.getErrorAreaInfo;
            if (error === this.errcode.noContent) {
              message = this.str.dialog.message.getErrorAreaNoContent;
            }
            this.showErrorDialog(title, message);
          });
      },
      /**
       * ポール情報を設定する
       */
      async getPoleList() {
        // APIからすべてのポール情報を取得する
        // const getPoleFunc = this.updatePoleListAsAreaId(null, false);
        const getPoleFunc = this.updatePoleList(null, null, null, false);
        await getPoleFunc
          .then(async () => {
            // ポール情報が存在していたら、必要な付加情報を追加する
            if (this.$store.state.poleList !== void 0 && this.$store.state.poleList !== null && this.$store.state.poleList.length > 0) {
              this.poleList = this.$store.state.poleList;
            } else {
              // ポール情報が0件の場合はエラーダイアログを表示させる
              throw new Error(this.errcode.noContent);
            }
          })
          .catch(error => {
            // 失敗したらエラーダイアログを表示する
            const title = this.str.dialog.title.getError;
            let message = this.str.dialog.message.getErrorPoleInfo;
            if (error === this.errcode.noContent) {
              message = this.str.dialog.message.getErrorPoleNoContent;
            }
            this.showErrorDialog(title, message);
          });
      },
      /**
       * サイドメニューに表示するポール一覧を設定する
       */
      setDisplayPoleList(list) {
        this.$store.dispatch(STORE_ACTIONS.setAreaPoleList, list);
        this.displayPoleList = list;
      },
      /**
       * OpenStreetMap上の住所情報を取得し、文字列として設定する
       * @param {Object} ポール情報
       */
      async setAddress(pole) {
        // OpenStreetMapから地点情報を取得する
        const response = await this.addressByCoordinates(pole.latitude, pole.longitude);
        if (response.ok) {
          // 取得に成功したら、分かれている住所情報を一つの文字列にまとめる
          let address = "";
          address += response.body.address.city !== void 0 ? response.body.address.city : "";
          address += response.body.address.county !== void 0 ? response.body.address.county : "";
          address += response.body.address.hamlet !== void 0 ? response.body.address.hamlet : "";
          address += response.body.address.suburb !== void 0 ? response.body.address.suburb : "";
          address += response.body.address.neighbourhood !== void 0 ? response.body.address.neighbourhood : "";
          address += response.body.address.road !== void 0 ? response.body.address.road : "";
          address += response.body.address.man_made !== void 0 ? response.body.address.man_made : "";
          pole.address = address;
          // メニューに表示するための市区町村名以下の住所を設定する
          pole.name = response.body.address.neighbourhood !== void 0 ? response.body.address.neighbourhood : "";
        }
      },
      selectPoint(data) {
        this.$refs.map.setCenter(data);
      },
      enterPoint(data) {
        this.$refs.map.enterPoint(data);
      },
      pageSelected(title) {
        this.dialog.name = title;
        this.dialog.isDialog = true;
      },
      /**
       * メニュー画面でポールが選択された場合、ポール選択ダイアログを表示する
       */
      poleSelected(pole) {
        this.$refs.map.setCenter(pole);
        this.setSelectPole(pole.poleId, pole.address);
        this.poleData = pole;
        this.dialog.name = pole.address;
        this.dialog.isDialog = true;
      },
      dialogClose() {
        this.dialog.isDialog = false;
      },
      /**
       * ダイアログ上にエラーメッセージを設定して表示
       * @param {String} title タイトル
       * @param {String} message メッセージ
       */
      showErrorDialog(title, message) {
        this.errorDialog.title = title;
        this.errorDialog.message = message;
        this.errorDialog.isDialog = true;
      }
    }
  };
</script>

<style lang="scss">
  #selectarea_view {
    padding-top: 40px;
    height: calc(100vh - 50px);
  }

  #selectarea_menu {
    position: absolute;
    left: 0px;
    top: 50px;
    width: 400px;
    height: calc(100vh - 50px);
    background-color: #000;
    color: #fff;
  }

  #selectarea_map {
    position: absolute;
    left: 400px;
    top: 50px;
    width: calc(100vw - 400px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
