<template>
  <div id="aggre_daily">
    <near-miss-daily-chart v-if="$store.state.aggreMode.category === 1" :to-date="toDate" :label="dailyLabel" />
    <traffic-daily-chart v-if="$store.state.aggreMode.category === 2" :to-date="toDate" :label="dailyLabel" />
    <axl-daily-chart v-if="$store.state.aggreMode.category === 3" :to-date="toDate" :label="dailyLabel" />
  </div>
</template>

<script>
  import moment from "moment";
  import NearMissDailyChart from "@/components/aggre/charts/daily/NearMissDailyChart";
  import TrafficDailyChart from "@/components/aggre/charts/daily/TrafficDailyChart";
  import AxlDailyChart from "@/components/aggre/charts/daily/AxlDailyChart";
  /**
   * @class
   * @classdesc 日次集計データの表示
   */
  const AggreDaily = {
    components: {
      NearMissDailyChart,
      TrafficDailyChart,
      AxlDailyChart
    },
    props: {
      toDate: moment
    },
    data() {
      return {
        dailyLabel: [],
        chartStyle: {}
      };
    },
    created() {
      // 日次データのグラフラベルを生成する
      this.dailyLabel = this.setDailyChartLabel(this.toDate);
    },
    methods: {
      /**
       * 日別チャート用のラベルを生成する
       */
      setDailyChartLabel(targetDate) {
        let label = [];
        const toDateString = "-" + targetDate.format("MM/DD");
        let work = targetDate
          .hours(0)
          .minutes(0)
          .seconds(0);
        for (let i = 0; i < 12; i++) {
          const segment = i === 11 ? work.subtract(2, "h").format("YYYY/MM/DD") + toDateString + " 00:00" : work.subtract(2, "h").format("HH:mm");
          label.unshift(segment);
        }
        return label;
      }
    }
  };

  export default AggreDaily;
</script>

<style lang="scss">
  #aggre_daily {
    width: 100%;
    height: 40vh;
    border: 1px #eee solid;
    canvas {
      height: 40vh;
    }
  }
</style>
