<template>
  <v-app-bar color="#0041c0" class="monitor-title-bar">
    <v-toolbar-title>{{ Title }}</v-toolbar-title>
    <v-toolbar-title class="ml-2">{{ areaName }}</v-toolbar-title>
    <slot />
  </v-app-bar>
</template>

<script>
  export default {
    name: "Bar",
    props: {
      Title: {
        type: String,
        require: true
      },
      areaName: {
        type: String,
        require: false
      }
    },

    computed: {
      hasAreaName() {
        return this.areaName;
      }
    }
  };
</script>

<style>
  .monitor-title-bar {
    color: white !important;
    font-weight: bold;
    font-size: 22px !important;
    border-radius: 4px !important;
  }
</style>
