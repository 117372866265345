<template>
  <div id="select_area_menu">
    <v-container>
      <v-row>
        <v-col class="menu_title">
          ポール一覧
        </v-col>
      </v-row>
    </v-container>
    <v-expansion-panels accordion dark>
      <v-expansion-panel :disabled="poleList === void 0 || poleList.length === 0">
        <v-expansion-panel-header color="#000">
          <span class="menu_subtitle">エリア</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels accordion dark>
            <v-expansion-panel v-for="item in poleList" :key="item.areaId">
              <v-expansion-panel-header color="#000" hide-actions>
                <template v-slot:default="{ open }">
                  <v-row no-gutters>
                    <v-col md="8">
                      <span class="pole_title">{{ item.name }}</span>
                    </v-col>
                    <v-spacer />
                    <v-col md="4" style="text-align: right;">
                      <span v-if="!open">({{ item.poleNumber }})</span>
                      <v-icon v-if="open">
                        mdi-chevron-up
                      </v-icon>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <v-list-item-group v-model="item.activated">
                    <v-list-item dense v-for="item2 in item.poleList" :key="item2.poleId" @click="poleSelected(item2)" style="padding-left: 2.2em;">
                      <v-list-item-icon dense>
                        <v-icon :color="iconColors[item2.status]">
                          mdi-map-marker
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content dense>
                        <v-list-item-title>{{ item2.name !== "" ? item2.name : item2.address }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  /**
   * SelectAreaMenu.vue
   * ポール一覧メニュー
   */
  export default {
    name: "SelectAreaMenu",
    props: {
      poleList: Array
    },
    data() {
      return {
        isMenuOpen: false,
        iconColors: ["#999", "#0c0", "#F00"]
      };
    },
    watch: {
      areaPoleList: {
        async handler() {
          // メニュー用のポール一覧が設定出来たら表示する
          this.isMenuOpen = true;
        }
      }
    },
    methods: {
      /**
       * ポールが選択されたら、親コンポーネントにポール情報を伝える
       * @param {Object} pole ポール情報
       */
      poleSelected(pole) {
        this.$emit("pole-selected", pole);
      }
    }
  };
</script>

<style lang="scss">
  #select_area_menu {
    background-color: #000;
    color: #fff;
  }

  .menu_title {
    font-size: 1.4em;
    font-weight: bold;
  }

  .menu_subtitle {
    font-size: 1.2em;
    padding-left: 0.7em;
    font-weight: bold;
  }

  .pole_title {
    padding-left: 1.5em;
  }
</style>
