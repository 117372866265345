import { render, staticRenderFns } from "./LocationInfoTable.vue?vue&type=template&id=2049fe38"
import script from "./LocationInfoTable.vue?vue&type=script&lang=js"
export * from "./LocationInfoTable.vue?vue&type=script&lang=js"
import style0 from "./LocationInfoTable.vue?vue&type=style&index=0&id=2049fe38&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VContainer,VDataTable})
