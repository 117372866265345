import Vue from "vue";
import App from "@/App";
import router from "@/router";
import store from "@/store";
import VueResource from "vue-resource";
import VueUid from "vue-uid";
import Components from "@/plugins/global-components";

import vuetify from "@/plugins/vuetify";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

import * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import "@/aws-exports";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import "@/utils/validation";
import * as log from "loglevel";

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueResource);
Vue.use(VueUid);
Vue.use(Components);

Vue.component("v-select", VueSelect.VueSelect);
Vue.component("v-datetime-picker", VueCtkDateTimePicker);

Vue.config.productionTip = false;
// ログレベル設定
// const logLevel = log.levels.DEBUG;
const logLevel = log.levels.ERROR;
// const logLevel = log.levels.SILENT;
log.setLevel(logLevel, false);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
