<template>
  <div class="video">
    <div class="video__spinner">
      <md-progress-spinner v-show="!videoStream && isPlay == true" class="md-accent" md-mode="indeterminate"> </md-progress-spinner>
    </div>
    <div v-show="isPlay == false" class="video__comment">
      <div class="video__comment__notplay">再生できません</div>
    </div>
    <video v-show="videoStream" :id="videoId" autoplay="true" controls="true" />
  </div>
</template>

<script>
  export default {
    name: "Video",
    props: {
      videoId: String,
      isPlay: Boolean,
      videoStream: MediaStream
    }
  };
</script>
<style lang="scss" scoped>
  .video {
    background-color: #353535;
    height: 100%;
    position: relative;
    &__spinner {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__comment {
      position: absolute;
      display: flex;
      justify-content: center;
      margin: 30% 40% 40%;
      &__notplay {
        width: 210px;
        text-align: center;
        padding: 20px;
        border: solid 1px #fff;
        color: #fff;
      }
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
</style>
