<template>
  <div>
    <v-card>
      <div>
        <slot></slot>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "Card",
    elevation: {
      type: [Number, String],
      require: false
    },
    contents: {
      type: String,
      require: false
    }
  };
</script>

<style lang="scss" scoped>
  .p-2 {
    padding: 10px;
  }
</style>
