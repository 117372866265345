<template>
  <div id="aggre_weekly">
    <near-miss-weekly-chart v-if="$store.state.aggreMode.category === 1" :to-date="toDate" :label="weeklyLabel" />
    <traffic-weekly-chart v-if="$store.state.aggreMode.category === 2" :to-date="toDate" :label="weeklyLabel" />
    <axl-weekly-chart v-if="$store.state.aggreMode.category === 3" :to-date="toDate" :label="weeklyLabel" />
  </div>
</template>

<script>
  import moment from "moment";
  import NearMissWeeklyChart from "@/components/aggre/charts/weekly/NearMissWeeklyChart";
  import TrafficWeeklyChart from "@/components/aggre/charts/weekly/TrafficWeeklyChart";
  import AxlWeeklyChart from "@/components/aggre/charts/weekly/AxlWeeklyChart";

  /**
   * @class
   * @classdesc 週次データの表示
   */
  const AggreWeekly = {
    components: {
      NearMissWeeklyChart,
      TrafficWeeklyChart,
      AxlWeeklyChart
    },
    props: {
      toDate: moment,
      priority: Number
    },
    data() {
      return {
        weeklyLabel: [],
        chartStyle: {},
        isChartOpen: false
      };
    },
    created() {
      // グラフのデータラベルを生成する
      this.weeklyLabel = this.setWeeklyChartLabel(this.toDate);
    },
    methods: {
      /**
       * グラフのデータラベルを生成する
       * @param {moment} targetDate 基準となる日付
       */
      setWeeklyChartLabel(targetDate) {
        let label = [];
        for (let i = 0; i < 7; i++) {
          const segment = i === 6 || targetDate.format("MM/DD") === "01/02" ? targetDate.subtract(1, "d").format("YYYY/MM/DD") : targetDate.subtract(1, "d").format("MM/DD");
          label.unshift(segment);
        }
        return label;
      }
    }
  };

  export default AggreWeekly;
</script>

<style lang="scss">
  #aggre_weekly {
    width: 100%;
    height: 40vh;
    border: 1px #eee solid;
    canvas {
      height: 40vh;
    }
  }
</style>
