<script>
  import { Line } from "vue-chartjs";

  export default {
    extends: Line,
    name: "LineChart",
    props: {
      vehicleId1SpeedList: {
        type: Array,
        require: true
      },
      vehicleId2SpeedList: {
        type: Array,
        require: true
      },
      graphTitleSupplement: {
        type: String,
        require: true
      },
      xAxisSupplement: {
        type: Array,
        require: true
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options);
    },
    computed: {
      props() {
        return {
          vehicleId1SpeedList: this.vehicleId1SpeedList,
          vehicleId2SpeedList: this.vehicleId2SpeedList,
          graphTitleSupplement: this.graphTitleSupplement,
          xAxisSupplement: this.xAxisSupplement
        };
      },

      chartData() {
        return {
          labels: this.xAxisSupplement,
          datasets: [
            {
              label: "車両ID１",
              borderWidth: 1.5,
              borderColor: "rgba(19, 110, 214, 0.5)",
              color: "rgba(19, 110, 214, 0.5)",
              data: this.vehicleId1SpeedList,
              fill: false,
              spanGaps: true
            },
            {
              label: "車両ID２",
              borderWidth: 1.5,
              borderColor: "rgba(245, 0, 0, 0.5)",
              data: this.vehicleId2SpeedList,
              fill: false,
              spanGaps: true
            }
          ]
        };
      },
      options() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "速度グラフ(" + this.graphTitleSupplement + ")"
          },
          legend: {
            display: true,
            position: "top"
          },
          tooltips: {
            display: true
          },

          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  parser: "HH:mm:ss.SSS",
                  unit: "second",
                  stepSize: 1,
                  displayFormats: {
                    second: "HH:mm.ss"
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: "時間"
                },
                tension: 0.1,
                gridLines: {
                  display: true
                }
              }
            ],
            yAxes: [
              {
                id: "y-axis",
                position: "left",
                scaleLabel: {
                  display: true,
                  labelString: "速度"
                },
                responsive: true,
                tension: 0.1,
                ticks: {
                  stepSize: 5
                }
              }
            ]
          }
        };
      }
    },
    methods: {}
  };
</script>
