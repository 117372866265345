import { extend, localize } from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import * as rules from "vee-validate/dist/rules";
import { required, numeric, double } from "vee-validate/dist/rules";

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("ja", ja);

extend("required", {
  ...required,
  message: "{_field_}は必須です。"
});

extend("numeric", {
  ...numeric,
  message: "{_field_}は数字のみ入力出来ます"
});

extend("double", {
  ...double,
  message: "{_field_}は数字(少数点以下可)のみです"
});

extend("requiredArray", {
  validate(value) {
    return value.length != 0;
  },
  message: "{_field_}は必須です。"
});
