<template>
  <v-dialog nav dense v-model="dialog.isDialog" width="20vw" persistent>
    <v-card>
      <v-container>
        <v-card-title id="dialog_title">
          <span v-text="dialog.title" />
        </v-card-title>
        <v-divider />
        <v-card-text id="dialog_message">
          <span v-text="dialog.message" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue-grey" dark rounded @click="dialogClose">Close</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ErrorDialog",
    props: ["dialog"],
    methods: {
      dialogClose() {
        this.$emit("close-error-dialog");
      }
    }
  };
</script>

<style>
  #dialog_title {
    height: 5vh;
    font-size: 20px;
    font-weight: bold;
  }
  #dialog_message {
    height: 15vh;
    font-size: 15px;
  }
  #dialog_message > span {
    white-space: pre-line;
  }
</style>
