<template>
  <div class="select_area">
    <div id="pole_map" />
    <v-main style="padding: 0;">
      <router-view />
    </v-main>
    <CommonDialog :dialog="dialog" />
  </div>
</template>

<script>
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import CommonDialog from "@/components/CommonDialog";
  import mathFunctions from "@/utils/math";
  import { commonFunction, communicationFunction, mapFunction, adminCommFunction } from "@/mixins/utils";
  delete L.Icon.Default.prototype._getIconUrl;

  /**
   * SelectArea.vue
   * 設置ポールマップ
   */
  export default {
    name: "SelectArea",
    components: {
      CommonDialog
    },
    props: ["poleList"],
    mixins: [commonFunction, communicationFunction, mapFunction, adminCommFunction],
    data() {
      return {
        // setting.json内の設定値一覧
        setting: undefined,
        markers: [],
        map: undefined,
        selectId: undefined,
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        }
      };
    },
    created() {
      this.initialize();
    },
    mounted() {
      this.createAreaMap();
    },
    watch: {
      poleList: {
        async handler(val) {
          this.addPoleMarker(val);
        },
        deep: true
      }
    },
    methods: {
      ...mathFunctions,
      initialize() {
        this.setting = this.getSetting();
      },
      /**
       * 画面に地図を表示する
       */
      createAreaMap() {
        let zoom = this.setting.selectArea.zoom.default;
        let zoom_mim = this.setting.selectArea.zoom.min;
        let zoom_max = this.setting.selectArea.zoom.max;
        let center = [this.setting.selectArea.center.latitude, this.setting.selectArea.center.longitude];

        L.Icon.Default.mergeOptions(this.getMarkerIcon(false));

        this.map = L.map("pole_map", {
          dragging: true,
          touchZoom: true,
          scrollWheelZoom: true,
          doubleClickZoom: true,
          boxZoom: true,
          tap: true,
          keyboard: true,
          zoomControl: true,
          minZoom: zoom_mim,
          maxZoom: zoom_max
        }).setView(center, zoom);

        L.control.layers(this.getTileLayer(this.map, zoom_max, undefined)).addTo(this.map);
        L.control.scale({ imperial: false }).addTo(this.map);
      },
      addPoleMarker(poleList) {
        poleList.forEach(pole => {
          let latlng = [pole.latitude, pole.longitude];
          this.addMarker(latlng, pole.name, pole.poleId, L.icon(this.getMarkerIcon(false)));
        });
      },
      addMarker(latlng, name, poleId, icon) {
        let marker = L.marker(latlng, {
          title: name,
          icon: icon
        }).addTo(this.map);
        this.markers.push({
          id: poleId,
          name: name,
          latlng: latlng,
          marker: marker
        });
      },
      setCenter(data) {
        // 具体的な場所を見やすくするために拡大
        let zoomLevel = this.map.getZoom();
        if (zoomLevel < 14) {
          zoomLevel = 14;
        }
        this.map.setView(data.latlng, zoomLevel);

        if (this.selectId != undefined) {
          let marker = this.getMarkerInfo(this.selectId, this.markers);
          this.map.removeLayer(marker.marker);
          this.addMarker(marker.latlng, marker.name, marker.id, L.icon(this.getMarkerIcon(false)));
        }

        let markerInfo = this.getMarkerInfo(data.poleId, this.markers);
        this.map.removeLayer(markerInfo.marker);
        this.addMarker(markerInfo.latlng, markerInfo.name, markerInfo.id, L.icon(this.getMarkerIcon(true)));
        this.selectId = data.poleId;
      },
      enterPoint(data) {
        this.setSelectArea(data.poleId, data.name, data.latlng);
        this.nextView();
      }
    }
  };
</script>

<style>
  #pole_map {
    z-index: 0;
    /* 絶対値で高さを指定しないと地図が表示されない */
    height: 95vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 15px;
  }

  #area_list {
    display: inline-table;
    position: absolute;
    left: 10vw;
    top: 0vh;
    width: 70vw;
    height: auto;
    opacity: 0.8;
    z-index: 100;
  }

  #area_menu {
    max-width: 70vw;
    background-color: #ddd;
  }

  #area_list_table {
    height: 30vh;
    overflow-y: auto;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
</style>
