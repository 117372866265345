import Bar from "@/components/atoms/Bar.vue";
import Button from "@/components/atoms/Button.vue";
import Card from "@/components/atoms/Card.vue";
import DailyChart from "@/components/atoms/DailyChart.vue";
import DailyHistogram from "@/components/atoms/DailyHistogram.vue";
import Modal from "@/components/atoms/Modal.vue";
import ModalDisplayComponent from "@/components/atoms/ModalDisplayComponent.vue";
import Table from "@/components/atoms/Table.vue";
import TextField from "@/components/atoms/TextField.vue";
import WeeklyChart from "@/components/atoms/WeeklyChart";
import ModalDisplayDailyHistogram from "@/components/atoms/ModalDisplayDailyHistogram";
import ModalDisplayWeeklyHistogram from "@/components/atoms/ModalDisplayWeeklyHistogram";
import WeeklyHistogram from "@/components/atoms/WeeklyHistogram";

export default [
  Bar,
  Button,
  Card,
  DailyChart,
  DailyHistogram,
  Modal,
  ModalDisplayComponent,
  Table,
  TextField,
  WeeklyChart,
  ModalDisplayDailyHistogram,
  ModalDisplayWeeklyHistogram,
  WeeklyHistogram
];
