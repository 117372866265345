<template>
  <div class="realvideo-container">
    <div class="conference-container__videos">
      <div class="conference-container__videos--remote">
        <div class="video">
          <Video :videoId="aws.videoId" :isPlay="aws.isPlay" :videoStream="aws.remoteStream"> </Video>
        </div>
      </div>
    </div>
    <CommonDialog :dialog="dialog" />
  </div>
</template>

<script>
  import AWS from "aws-sdk";
  import { Auth } from "aws-amplify";
  import { SignalingClient } from "amazon-kinesis-video-streams-webrtc";
  import moment from "moment";
  import Video from "@/components/video/Video";
  import CommonDialog from "@/components/CommonDialog";
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import * as log from "loglevel";

  /**
   * RealTimeVideo.vue
   * リアルタイムモニター用カメラ映像
   * @author HBA
   */
  export default {
    name: "RealTimeVideo",
    components: {
      Video,
      CommonDialog
    },
    mixins: [commonFunction, communicationFunction],
    props: ["camera"],
    data: function() {
      return {
        setting: undefined,
        selectCamera: undefined,
        waitInterval: undefined,
        aws: {
          region: Auth._config.region,
          channelARN: undefined,
          role: "VIEWER",
          credentials: null,
          endpointsByProtocol: null,
          peerConnection: null,
          client: null,
          useTrickleICE: true,
          remoteView: undefined,
          remoteStream: undefined,
          isPlay: true
        },
        streaming: {
          status: undefined,
          channel: undefined,
          respTimer: []
        },
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        }
      };
    },
    /**
     * 画面呼び出し時
     */
    created() {
      // 初期処理
      this.initialize();
    },
    /**
     * 画面レンダリング完了時
     */
    mounted() {
      if (this.selectCamera !== void 0) {
        // this.startStreaming();
        this.getSignalingChannelName();
      } else {
        this.aws.isPlay = false;
      }
    },
    /**
     * 画面消去前
     */
    beforeDestroy() {
      // 消去処理
      this.allClear();
    },
    methods: {
      /**
       * 初期処理
       */
      initialize() {
        this.allClear();
        this.setting = this.getSetting();
        this.selectCamera = this.camera;
        this.waitInterval = this.setting.realVideo.waitInterval;
        let datetime = moment().valueOf();
        this.aws.videoId = datetime.toString() + "_" + this.$_uid.toString();
      },
      /**
       * シグナリングチャネル名の取得
       */
      async getSignalingChannelName() {
        let promise = this.getSignalingChannel(this.selectCamera.poleId, this.selectCamera.sensorId);
        promise
          .then(res => {
            // APIからの戻り値として"channel"プロパティが含まれているかをチェックする
            if (res !== void 0 && res !== null && res.channel !== void 0 && res.channel !== null && res.channel !== "") {
              this.streaming.channel = res.channel;
              this.streaming.status = "RespCheck";
              // チャネル名が見つかったらビデオ映像ビューワを起動する
              this.startWebrtcViewer();
            } else {
              log.error("Signaling channel name is empty.");
            }
          })
          .catch(error => {
            log.error("No signaling channel name" + error);
          });
      },
      /**
       * ストリーミング開始要求
       */
      async startStreaming() {
        let promise = this.streamingStart(this.selectCamera.poleId, this.selectCamera.sensorId);
        promise
          .then(() => {
            this.streaming.status = "RespCheck";
            this.startStreamingTimer(this.streaming.channel, this.setting.streaming.respChkInterval);
          })
          .catch(error => {
            log.error("startStreaming error " + error);
          });
      },
      /**
       * ストリーミング要求タイミングの設定、繰り返し処理の開始
       * @param {string} channel チャネル名
       * @param {number} interval タイムアウト時間
       */
      startStreamingTimer(channel, interval) {
        // すでに動いている場合のため、停止処理を実行
        this.stopStreamingTimer();
        let self = this;
        this.streaming.respTimer.push(
          setInterval(function() {
            self.timeoutStreamingTimer(channel);
          }, interval)
        );
      },
      /**
       * ストリーミング要求処理の停止
       */
      stopStreamingTimer() {
        if (this.streaming.respTimer.length > 0) {
          for (const respTimer of this.streaming.respTimer) {
            clearInterval(respTimer);
          }
        }
      },
      /**
       * 要求時間の経過後のストリームステータスのチェック
       * @param {string} channel チャネル名
       */
      async timeoutStreamingTimer(channel) {
        // ステータスチェック
        if (this.streaming.status === "RespCheck") {
          this.checkStreaming(channel);
          // 再生開始
        } else if (this.streaming.status === "Started") {
          this.notifyStreaming(channel);
          // ストリーミング停止
        } else {
          this.stopStreamingTimer();
        }
      },
      /**
       * ストリーミングステータスのチェック
       * @param {string} channel チャネル名
       */
      checkStreaming(channel) {
        let promise = this.streamingChkResp(channel);
        promise
          .then(res => {
            // OK
            if (res.responseStatus === this.streamingChkRespCode.ok) {
              this.stopStreamingTimer();
              this.streaming.status = "Started";
              this.startStreamingTimer(this.streaming.channel, this.setting.streaming.notifyInterval);
              // NG
            } else if (res.responseStatus === this.streamingChkRespCode.ng) {
              this.stopStreamingTimer();
              this.streaming.status = undefined;
              this.streaming.channel = undefined;
              this.aws.isPlay = false;
            }
          })
          .catch(() => {
            this.stopStreamingTimer();
            this.streaming.status = undefined;
            this.streaming.channel = undefined;
            this.aws.isPlay = false;
          });
      },
      /**
       * ストリーミング開始通知
       * @param {string} channel チャネル名
       */
      notifyStreaming(channel) {
        let promise = this.streamingActiveNotify(channel);
        promise
          .then(() => {})
          .catch(() => {
            this.allClear();
            this.aws.isPlay = false;
          });
      },
      showErrorDialogue(title, message) {
        this.dialog.title = title;
        this.dialog.message = message;
        this.dialog.isDialog = true;
        this.aws.isPlay = false;
      },
      /**
       * カメラ映像ビューワの起動
       */
      async startWebrtcViewer() {
        try {
          // シグナリングチャネルへ接続
          this.aws.peerConnection = await this.connectSignalingChannel(this.aws.role);
          if (this.aws.peerConnection !== null) {
            // 接続したらカメラ映像ビューワを生成する
            this.aws.client = await this.generateSignalingClientViewer(this.aws.peerConnection, this.aws.useTrickleICE);
          }
          if (this.aws.client) {
            this.aws.client.open();
            // ビューワが起動したらストリーミングを開始する
            this.startStreaming();
          } else {
            this.allClear();
            this.aws.isPlay = false;
          }
        } catch (error) {
          this.allClear();
          this.aws.isPlay = false;
          log.error("startWebrtcViewer error " + error);
        }
      },
      /**
       * カメラ映像ビューワの終了
       */
      async endWebrtcViewer() {
        try {
          if (this.aws.client != null) {
            this.aws.client.close();
            this.aws.client = null;
          }
          if (this.aws.peerConnection) {
            this.aws.peerConnection.close();
            this.aws.peerConnection = null;
          }
          if (this.aws.remoteStream) {
            this.aws.remoteStream.getTracks().forEach(track => track.stop());
            this.aws.remoteStream = null;
          }
          if (this.aws.remoteView) {
            this.aws.remoteView.srcObject = null;
          }
          this.aws.isPlay = true;
        } catch (error) {
          log.error("endWebrtcViewer error " + error);
        }
      },
      /**
       * シグナリングチャネルへの接続
       * @param {*} role
       */
      async connectSignalingChannel(role) {
        this.aws.credentials = await this.getCredential();

        if (!this.aws.credentials) return null;

        const kvClient = new AWS.KinesisVideo({
          region: this.aws.region,
          credentials: this.aws.credentials
        });

        const describeSignalingChannelResponse = await kvClient
          .describeSignalingChannel({
            ChannelName: this.streaming.channel
          })
          .promise();

        this.aws.channelArn = describeSignalingChannelResponse.ChannelInfo.ChannelARN;

        this.aws.endpointsByProtocol = await this.fetchEndpoints({
          kvClient,
          channelARN: this.aws.channelArn,
          role: role
        });

        const kvsChannelsClient = new AWS.KinesisVideoSignalingChannels({
          region: this.aws.region,
          credentials: this.aws.credentials,
          endpoint: this.aws.endpointsByProtocol.HTTPS
        });

        const iceServers = await this.fetchTURNServers({
          kvsChannelsClient,
          channelARN: this.aws.channelArn,
          region: this.aws.region
        });

        return new RTCPeerConnection({ iceServers });
      },
      /**
       * 認証情報の取得
       */
      async getCredential() {
        try {
          await Auth.currentAuthenticatedUser();
          const credentials = await Auth.currentCredentials();
          return await Auth.essentialCredentials(credentials);
        } catch (err) {
          log.error("getCredential error " + err);
          return null;
        }
      },
      /**
       * チャネルのエンドポイント情報の取得
       * @param {*} param0
       */
      async fetchEndpoints({ kvClient, channelARN, role }) {
        const getSignalingChannelEndpointResponse = await kvClient
          .getSignalingChannelEndpoint({
            ChannelARN: channelARN,
            SingleMasterChannelEndpointConfiguration: {
              Protocols: ["WSS", "HTTPS"],
              Role: role
            }
          })
          .promise();

        const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
          endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
          return endpoints;
        }, {});

        return endpointsByProtocol;
      },
      async fetchTURNServers({ kvsChannelsClient, channelARN, region }) {
        const getIceServerConfigResponse = await kvsChannelsClient
          .getIceServerConfig({
            ChannelARN: channelARN
          })
          .promise();

        const iceServers = [{ urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443` }];
        getIceServerConfigResponse.IceServerList.forEach(iceServer =>
          iceServers.push({
            urls: iceServer.Uris,
            username: iceServer.Username,
            credential: iceServer.Password
          })
        );
        return iceServers;
      },
      /**
       * ビデオ映像ビューワをs生成する
       * @param {*} peerConnection
       * @param {*} useTrickleICE
       */
      async generateSignalingClientViewer(peerConnection, useTrickleICE) {
        if (!this.aws.credentials) return null;

        const signalingClient = new SignalingClient({
          channelARN: this.aws.channelArn,
          channelEndpoint: this.aws.endpointsByProtocol.WSS,
          clientId: `c${Date.now()}`, // should be random
          role: "VIEWER",
          region: this.aws.region,
          credentials: this.aws.credentials
        });

        signalingClient.on("open", async () => {
          log.info("[VIEWER] Connected to signaling service");
          log.info("[VIEWER] Creating SDP offer");
          await peerConnection.setLocalDescription(
            await peerConnection.createOffer({
              offerToReceiveAudio: true,
              offerToReceiveVideo: true
            })
          );
          log.info("[VIEWER] Sending SDP offer");
          signalingClient.sendSdpOffer(peerConnection.localDescription);
        });
        signalingClient.on("sdpAnswer", async answer => {
          log.info("[VIEWER] Received SDP answer");
          await peerConnection.setRemoteDescription(answer);
        });
        signalingClient.on("iceCandidate", candidate => {
          log.info("[VIEWER] Received ICE candidate");
          peerConnection.addIceCandidate(candidate);
        });
        signalingClient.on("close", () => {
          log.info("[VIEWER] Disconnected from signaling channel");
        });

        signalingClient.on("error", error => {
          log.error("[VIEWER] Signaling client error: " + error);
        });

        // Send any ICE candidates to the other peer
        peerConnection.addEventListener("icecandidate", ({ candidate }) => {
          if (candidate) {
            log.info("[VIEWER] Generated ICE candidate");

            // When trickle ICE is enabled, send the ICE candidates as they are generated.
            if (useTrickleICE) {
              log.info("[VIEWER] Sending ICE candidate");
              signalingClient.sendIceCandidate(candidate);
            }
          } else {
            log.info("[VIEWER] All ICE candidates have been generated");

            // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
            if (!useTrickleICE) {
              log.info("[VIEWER] Sending SDP offer");
              signalingClient.sendSdpOffer(peerConnection.localDescription);
            }
          }
        });

        // As remote tracks are received, add them to the remote view
        peerConnection.addEventListener("track", event => {
          log.info("[VIEWER] Received remote track");
          this.aws.remoteView = document.getElementById(this.aws.videoId);
          if (!this.aws.remoteView.srcObject && event.streams) {
            this.aws.remoteStream = event.streams[0];
            this.aws.remoteView.srcObject = this.aws.remoteStream;
          }
        });

        return signalingClient;
      },
      allClear() {
        this.stopStreamingTimer();
        this.streaming.status = undefined;
        this.streaming.channel = undefined;
        this.endWebrtcViewer();
      },
      changeCamera(selectCamera) {
        this.selectCamera = selectCamera;
        this.allClear();
        setTimeout(
          function() {
            this.startStreaming();
          }.bind(this),
          this.waitInterval
        );
      }
    },
    watch: {
      camera(selectCamera) {
        if (selectCamera == undefined) {
          this.allClear();
          this.aws.isPlay = false;
        } else {
          this.changeCamera(selectCamera);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/styles/variables";

  .realvideo-container {
    background-color: black;
    height: 60vh;
    width: 100%;
    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      background-color: black;
      .md-button {
        transform: translateY(7px);
      }
      .md-icon.md-theme-default.md-icon-font {
        color: white;
      }
    }
    &__videos {
      position: relative;
      border: solid 1px #ffffff78;
      &--remote {
        position: relative;
      }
    }
    .video {
      width: 100%;
      height: 60vh;
    }
    h3 {
      padding-left: 1rem;
      color: white;
    }
  }
</style>
