<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <v-card elevation="20" raised>
      <v-card-title id="dialog_title">表示項目設定</v-card-title>
      <v-divider />
      <v-card-text>
        <v-checkbox primary hide-details v-for="check in checkboxes" :key="check.label" v-model="check.value" :label="check.label" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue-grey" dark rounded @click="onClickOk">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "LocationCheckBox",
    props: ["dialog", "checkboxes"],
    methods: {
      onClickOk() {
        this.$emit("dialogOk");
      }
    }
  };
</script>

<style scoped>
  #dialog_title {
    height: 6vh;
    font-size: 17px;
    font-weight: bold;
  }
</style>
