<template>
  <div class="fullview">
    <div class="loading-spacer"></div>
    <vue-loading type="spiningDubbles" color="#B3424A" :size="{ width: '200px', height: '200px' }"> </vue-loading>
  </div>
</template>

<script>
  import { VueLoading } from "vue-loading-template";

  export default {
    name: "Loading",
    components: {
      VueLoading
    }
  };
</script>

<style>
  .fullview {
    z-index: 100;
    width: 100%;
    height: 100%;
    background: #fefefe;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loading-spacer {
    height: 30%;
  }
</style>
