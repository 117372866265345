<template>
  <v-dialog v-model="showDialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
    <div class="divVCard1">
      <v-card class="vCardTabel">
        <div class="tabel1">
          <table>
            <thead>
              <tr>
                <th class="fixed01 title1">期間</th>
                <th class="fixed02 title1"></th>
                <th class="fixed01" v-for="(header, index) in data.columns" :key="index">
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- 期間A -->
              <tr>
                <th rowspan="7" class="fixed01 periodATh">期間A</th>
                <th class="fixed02 periodATh">平均速度グラフ</th>
                <td class="periodA" v-for="(avgSpeedHistA, index) in data.termA.avgHist" :key="index">
                  <v-card class="cardGraph1">
                    <MacroAnalysisHistgram
                      v-if="isViewGraph"
                      :xAxes="avgSpeedGraphSetting.xAxes"
                      :yAxes="avgSpeedGraphSetting.yAxes"
                      :backgroundColor="avgSpeedGraphSetting.termA.backgroundColor"
                      :data="Object.values(avgSpeedHistA)"
                      :max="sectionMaxBorder.termA.avg.suggestedMax"
                      :isSuggested="true"
                      v-on:showModal="showModal"
                    />
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodATh">最高速度グラフ</th>
                <td class="periodA" v-for="(maxSpeedHistA, index) in data.termA.maxHist" :key="index">
                  <v-card class="cardGraph1">
                    <MacroAnalysisHistgram
                      v-if="isViewGraph"
                      :xAxes="maxSpeedGraphSetting.xAxes"
                      :yAxes="maxSpeedGraphSetting.yAxes"
                      :backgroundColor="maxSpeedGraphSetting.termA.backgroundColor"
                      :data="Object.values(maxSpeedHistA)"
                      :isSuggested="true"
                      :max="sectionMaxBorder.termA.max.suggestedMax"
                      v-on:showModal="showModal"
                    />
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodATh">台数</th>
                <td class="periodA periodATh" v-for="(termANumber, index) in data.termA.number" :key="index">
                  {{ termANumber }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodATh">平均速度</th>
                <td class="periodA" v-for="(termAAvgSpeed, index) in data.termA.avgSpeed" :key="index">
                  {{ termAAvgSpeed }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodATh">最高速度</th>
                <td class="periodA" v-for="(termAMaxSpeed, index) in data.termA.maxSpeed" :key="index">
                  {{ termAMaxSpeed }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodATh">最頻値</th>
                <td class="periodA" v-for="(termAMode, index) in data.termA.mode" :key="index">
                  {{ termAMode }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodATh">標準偏差</th>
                <td class="periodA" v-for="(termAStd, index) in data.termA.std" :key="index">
                  {{ termAStd }}
                </td>
              </tr>

              <!-- 期間B -->
              <tr>
                <th rowspan="7" class="fixed01 periodBTh">期間B</th>
                <th class="fixed02 periodBTh">平均速度グラフ</th>
                <td class="periodB" v-for="(avgSpeedHistB, index) in data.termB.avgHist" :key="index">
                  <v-card class="cardGraph1">
                    <MacroAnalysisHistgram
                      v-if="isViewGraph"
                      :xAxes="avgSpeedGraphSetting.xAxes"
                      :yAxes="avgSpeedGraphSetting.yAxes"
                      :backgroundColor="avgSpeedGraphSetting.termB.backgroundColor"
                      :data="Object.values(avgSpeedHistB)"
                      :isSuggested="true"
                      :max="sectionMaxBorder.termB.avg.suggestedMax"
                      v-on:showModal="showModal"
                    />
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodBTh">最高速度グラフ</th>
                <td class="periodB" v-for="(maxSpeedHistB, index) in data.termB.maxHist" :key="index">
                  <v-card class="cardGraph1">
                    <MacroAnalysisHistgram
                      v-if="isViewGraph"
                      :xAxes="maxSpeedGraphSetting.xAxes"
                      :yAxes="maxSpeedGraphSetting.yAxes"
                      :backgroundColor="maxSpeedGraphSetting.termB.backgroundColor"
                      :data="Object.values(maxSpeedHistB)"
                      :isSuggested="true"
                      :max="sectionMaxBorder.termB.max.suggestedMax"
                      v-on:showModal="showModal"
                    />
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodBTh">台数</th>
                <td class="periodB" v-for="(termBNumber, index) in data.termB.number" :key="index">
                  {{ termBNumber }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodBTh">平均速度</th>
                <td class="periodB" v-for="(termBAvgSpeed, index) in data.termB.avgSpeed" :key="index">
                  {{ termBAvgSpeed }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodBTh">最高速度</th>
                <td class="periodB" v-for="(termBMaxSpeed, index) in data.termB.maxSpeed" :key="index">
                  {{ termBMaxSpeed }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodBTh">最頻値</th>
                <td class="periodB" v-for="(termBMode, index) in data.termB.mode" :key="index">
                  {{ termBMode }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 periodBTh">標準偏差</th>
                <td class="periodB" v-for="(termBStd, index) in data.termB.std" :key="index">
                  {{ termBStd }}
                </td>
              </tr>

              <!-- 差分 -->
              <tr>
                <th rowspan="5" class="fixed01 diffTh">差分</th>
                <th class="fixed02 diffTh">平均速度グラフ</th>
                <td class="diff" v-for="(diffAvgSpeedHist, index) in data.diff.avgHist" :key="index">
                  <v-card class="cardGraph1">
                    <MacroAnalysisHistgram
                      v-if="isViewGraph"
                      :xAxes="avgSpeedHistGraphSetting.xAxes"
                      :yAxes="avgSpeedHistGraphSetting.yAxes"
                      :backgroundColor="avgSpeedHistGraphSetting.backgroundColor"
                      :data="Object.values(diffAvgSpeedHist)"
                      :isSuggested="true"
                      :max="sectionMaxBorder.diff.avg.suggestedMax"
                      :min="sectionMaxBorder.diff.avg.suggestedMin"
                      :maxTicksLimit="sectionMaxBorder.diff.avg.maxTicksLimit"
                      v-on:showModal="showModal"
                    />
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="fixed02 diffTh">台数</th>
                <td class="diff" v-for="(diffNumber, index) in data.diff.number" :key="index">
                  {{ diffNumber }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 diffTh">平均速度</th>
                <td class="diff" v-for="(diffAvgSpeed, index) in data.diff.avgSpeed" :key="index">
                  {{ diffAvgSpeed }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 diffTh">W-t検定</th>
                <td class="diff" v-for="(diffTTest, index) in data.diff.tTest" :key="index">
                  {{ diffTTest }}
                </td>
              </tr>
              <tr>
                <th class="fixed02 diffTh">効果量</th>
                <td class="diff" v-for="(diffEffectSize, index) in data.diff.effectSize" :key="index">
                  {{ diffEffectSize }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
      <v-card-actions>
        <div class="closeBtn">
          <v-btn color="blue" style="color: white" block @click="close()">閉じる</v-btn>
        </div>
      </v-card-actions>
      <ModalMacroAnalysisHistgram :value="isDisplay" @input="isDisplay = $event" text="errorText" :prefix="prefix" />
    </div>
  </v-dialog>
</template>
<script>
  import ModalMacroAnalysisHistgram from "@/components/macroAnalysis/ModalMacroAnalysisHistgram";
  import MacroAnalysisHistgram from "@/components/macroAnalysis/MacroAnalysisHistgram";
  export default {
    name: "MacroAnalysisDetaiInfoTabel",
    components: {
      MacroAnalysisHistgram,
      ModalMacroAnalysisHistgram
    },
    data() {
      return {
        showDialog: true,
        isDisplay: false,
        prefix: {
          component: null,
          props: {}
        },
        data: {
          kind: undefined,
          columns: [],
          termA: {
            number: [],
            avgSpeed: [],
            maxSpeed: [],
            mode: [],
            std: [],
            avgHist: [],
            maxHist: []
          },
          termB: {
            number: [],
            avgSpeed: [],
            maxSpeed: [],
            mode: [],
            std: [],
            avgHist: [],
            maxHist: []
          },
          diff: {
            number: [],
            avgSpeed: [],
            tTest: [],
            effectSize: [],
            avgHist: []
          }
        },
        isViewGraph: false,
        avgSpeedGraphSetting: {
          xAxes: {
            scaleLabelDisplay: true,
            labelString: "平均速度"
          },
          yAxes: {
            scaleLabelDisplay: true,
            labelString: "台数"
          },
          termA: {
            backgroundColor: "rgba(65, 158, 143, 0.8)"
          },
          termB: {
            backgroundColor: "rgba(160, 158, 34, 0.8)"
          }
        },
        avgSpeedHistGraphSetting: {
          xAxes: {
            scaleLabelDisplay: true,
            labelString: "平均速度"
          },
          yAxes: {
            scaleLabelDisplay: true,
            labelString: "差分"
          },
          title: {
            display: true,
            text: "期間Aから期間Bを引いた差分値"
          },
          backgroundColor: "rgba(236, 97, 4, 0.8)"
        },
        maxSpeedGraphSetting: {
          xAxes: {
            scaleLabelDisplay: true,
            labelString: "最高速度"
          },
          yAxes: {
            scaleLabelDisplay: true,
            labelString: "台数"
          },
          termA: {
            backgroundColor: "rgba(65, 158, 143, 0.8)"
          },
          termB: {
            backgroundColor: "rgba(160, 158, 34, 0.8)"
          }
        },
        maxSpeedHistGraphSetting: {
          xAxes: {
            scaleLabelDisplay: true,
            labelString: "最高速度"
          },
          yAxes: {
            scaleLabelDisplay: true,
            labelString: "差分"
          },
          title: {
            display: true,
            text: "期間Aから期間Bを引いた差分値"
          },
          backgroundColor: "rgba(236, 97, 4, 0.8)"
        },
        sectionMaxBorder: {
          termA: {
            avg: {
              suggestedMax: 1
            },
            max: {
              suggestedMax: 1
            }
          },
          termB: {
            avg: {
              suggestedMax: 1
            },
            max: {
              suggestedMax: 1
            }
          },
          diff: {
            avg: {
              suggestedMax: 1,
              suggestedMin: 0,
              maxTicksLimit: 2
            }
          }
        }
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      async initialize() {
        this.data = this.$store.state.macroAnalysisDetaiInfo;
        this.sectionMaxBorder.termA.avg.suggestedMax = await this.getBorderMaxData(this.data.termA.avgHist);
        this.sectionMaxBorder.termA.max.suggestedMax = await this.getBorderMaxData(this.data.termA.maxHist);
        this.sectionMaxBorder.termB.avg.suggestedMax = await this.getBorderMaxData(this.data.termB.avgHist);
        this.sectionMaxBorder.termB.max.suggestedMax = await this.getBorderMaxData(this.data.termB.maxHist);
        this.sectionMaxBorder.diff.avg = await this.getDiffBorderData(this.data.diff.avgHist);
        this.isViewGraph = true;
      },
      showModal(component, props) {
        this.prefix.component = component;
        this.prefix.props = props;
        this.isDisplay = true;
      },
      async getBorderMaxData(object) {
        let array1 = [];
        for (const item of object) {
          let array2 = Object.values(item);
          array1 = array1.concat(array2);
        }
        let maxValue = Math.max(...array1);
        if (maxValue == 0) {
          maxValue = 1;
        }
        return maxValue;
      },
      getDiffBorderData(object) {
        let array1 = [];
        for (const item of object) {
          let array2 = Object.values(item);
          array1 = array1.concat(array2);
        }

        let max = Math.max(...array1);
        let min = Math.min(...array1);

        // 初期値設定
        let suggestedMaxValue = 1;
        let suggestedMinValue = 0;
        let maxTicksLimit = 2;

        // boarder範囲の作成
        if (max >= 1 && min >= 0) {
          suggestedMaxValue = max;
          suggestedMinValue = 0;
        } else if (max <= 0 && min < 0) {
          suggestedMaxValue = 0;
          suggestedMinValue = min;
        } else if (max == 0 && min == 0) {
          suggestedMaxValue = 1;
          suggestedMinValue = 0;
          maxTicksLimit = 2;
        } else {
          suggestedMaxValue = max;
          suggestedMinValue = min;
          maxTicksLimit = 3;
        }
        return {
          suggestedMax: suggestedMaxValue,
          suggestedMin: suggestedMinValue,
          maxTicksLimit: maxTicksLimit
        };
      },
      close() {
        this.$emit("closeSectionDetail");
        this.showDialog = false;
      }
    }
  };
</script>

<style scoped>
  .divVCard1 {
    position: relative;
    top: 12%;
    text-align: center;
    width: 95%;
    left: 2.5%;
  }
  .vCardTabel {
    overflow: auto;
    width: 100%;
    height: 690px;
  }
  .cardGraph1 {
    height: 230px;
    width: 210px;
    cursor: pointer;
    outline: none;
  }
  table {
    width: 200px;
    height: 100%;
  }
  th {
    text-align: center;
    color: white;
    background: rgb(0, 132, 255);
    border-left: 1px solid #3c6690;
    border-top: 1px solid #3c6690;
    border-bottom: 1px solid #3c6690;
    width: auto;
    white-space: nowrap;
  }
  .periodATh {
    background-color: rgba(65, 158, 143);
  }
  .periodBTh {
    background-color: rgba(160, 158, 34);
  }
  .diffTh {
    background-color: rgba(236, 97, 4);
  }
  .diff {
    background-color: rgba(236, 97, 4, 0.3);
  }
  .title1 {
    width: 0.5px;
  }
  td {
    text-align: center;
    border-left: 0.5px solid #0d0d0e;
    border-bottom: 0.5px solid #0d0d0e;
    border-top: none;
    padding: 5px 0;
  }
  .fixed01,
  .fixed02 {
    position: sticky;
    top: 0;
    height: auto;
  }
  .fixed01 {
    left: 0;
  }
  .fixed02 {
    left: 39.5px;
  }
  .fixed01 {
    z-index: 1;
  }
  .fixed02 {
    z-index: 2;
  }
  .periodA {
    background-color: rgba(65, 158, 143, 0.4);
  }
  .periodATitle {
    background-color: rgba(65, 158, 143, 0.4);
  }
  .periodB {
    background-color: rgba(160, 158, 34, 0.3);
  }
  .periodBTitle {
    background-color: rgba(65, 158, 143, 0.4);
  }
  .closeBtn {
    left: 3%;
    width: 90%;
    top: 20px;
    position: relative;
    color: white;
  }

  .dialog-bottom-transition-enter-active,
  .dialog-bottom-transition-leave-active {
    transition: transform 0.2s ease-in-out;
  }
  .v-dialog__content {
    background: #fefefe;
    background-color: rgba(0, 0, 0, 0.3);
  }
</style>
