<template>
  <div>
    <Summary
      v-if="renderComponent"
      :pageTitle="'ダッシュボード'"
      :showButton="false"
      :disabledDatePicker="true"
      :disabledTimePicker="true"
      :addStyle="'addStyle'"
      :summariesForm="false"
    />
  </div>
</template>

<script>
  import Summary from "@/views/monitor/Summally.vue";
  import { getDate } from "@/utils/day.js";
  const countTime = {
    5: 300,
    10: 600,
    15: 900,
    30: 1800,
    45: 2700,
    60: 3600
  };

  export default {
    components: {
      Summary
    },

    data() {
      return {
        poleId: this.$store.state.selectPole.poleId,
        currentTime: "",
        count: 0,
        renderComponent: true
      };
    },

    created() {
      const formDate = getDate();
      this.currentTime = formDate[0] + " " + formDate[1];
      this.count = countTime["15"];
      setInterval(this.countDownTime, 1000);
    },

    methods: {
      countDownTime() {
        this.count--;
        if (this.count === 0) {
          this.renderComponent = false;
          this.$nextTick(() => {
            this.renderComponent = true;
            this.count = countTime["15"];
          });
        }
      }
    }
  };
</script>

<style>
  .addStyle {
    opacity: 0.38 !important;
  }
</style>
