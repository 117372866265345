<template>
  <!-- dark -->
  <v-btn light rounded color="primary" block @click="onclick()" :disabled="disabled"> {{ label }} </v-btn>
</template>

<script>
  export default {
    name: "Button",
    props: {
      label: {
        type: String,
        require: true
      },
      disabled: {
        type: Boolean,
        require: false
      }
    },
    methods: {
      onclick() {
        this.$emit("click");
      }
    }
  };
</script>

<style></style>
