<template>
  <div class="multicamera_view">
    <v-container fluid fill-height>
      <v-row>
        <v-col cols="6">
          <!-- Real映像ブロック１ -->
          <v-card id="multicamera_camera" elevation="5" outlined>
            <v-card-title class="py-0" style="font-size: 20px; font-weight: bold; color: white; background-color: #0041c0; height: 4vh">
              <span v-if="showCameraFix[0].show" v-text="showCameraFix[0].value.name" />
              <v-spacer />
            </v-card-title>
            <v-divider />
            <RealVideo v-if="showCameraFix[0].show" :camera="showCameraFix[0].select" />
            <div id="multicamera_logo" v-if="!showCameraFix[0].show"></div>
          </v-card>

          <v-divider />

          <!-- Real映像ブロック２ -->
          <v-card id="multicamera_camera2" elevation="5" outlined>
            <v-card-title class="py-0" style="font-size: 20px; font-weight: bold; color: white; background-color: #0041c0; height: 6vh">
              <span v-if="showCameraFix[1].show" v-text="showCameraFix[1].value.name" />
              <v-spacer />
            </v-card-title>
            <v-divider />
            <RealVideo v-if="showCameraFix[0].show" :camera="showCameraFix[1].select" />
            <div id="multicamera_logo" v-if="!showCameraFix[1].show"></div>
          </v-card>
        </v-col>

        <v-col cols="6">
          <!-- Real映像ブロック３ -->
          <v-card id="multicamera_camera" elevation="5" outlined>
            <v-card-title class="py-0" style="font-size: 20px; font-weight: bold; color: white; background-color: #0041c0; height: 4vh">
              <span v-if="showCameraFix[2].show" v-text="showCameraFix[2].value.name" />
              <v-spacer />
            </v-card-title>
            <v-divider />
            <RealVideo v-if="showCameraFix[2].show" :camera="showCameraFix[2].select" />
            <div id="multicamera_logo" v-if="!showCameraFix[2].show"></div>
          </v-card>

          <v-divider />

          <!-- Real映像ブロック４ -->
          <v-card id="multicamera_camera2" elevation="5" outlined>
            <v-card-title class="py-0" style="font-size: 15px; font-weight: bold; color: white; background-color: #0041c0; height: 6vh">
              <v-select
                dark
                v-model="showCameraVariable.select"
                :items="showCameraVariable.list"
                item-text="name"
                item-value="channel"
                label="カメラ選択"
                autowidth
                return-object
                style="width: 10vw; height: 6vh"
              />
            </v-card-title>
            <v-divider />
            <RealVideo :camera="showCameraVariable.select" />
            <div id="multicamera_logo" v-if="!showCameraVariable.show"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-main>
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" />
    <Loading v-show="isLoading" />
    <CommonDialog :dialog="dialog" />
  </div>
</template>

<script>
  import RealVideo from "@/components/RealVideo";
  import Loading from "@/components/Loading";
  import Title from "@/components/Title.vue";
  import CommonDialog from "@/components/CommonDialog";
  import { commonFunction, timerFunction, communicationFunction } from "@/mixins/utils";

  export default {
    name: "MultiCameraView",
    components: {
      RealVideo,
      Loading,
      Title,
      CommonDialog
    },
    mixins: [commonFunction, timerFunction, communicationFunction],
    data: function() {
      return {
        setting: undefined,
        address: undefined,
        isLoading: true,
        poleId: undefined,
        titleInfo: {
          title: undefined,
          show: {
            realtime: true,
            virtual: true,
            location: false,
            menu: true
          },
          // locationList: [],
          menuList: undefined
        },
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        cameraList: [],
        showCameraFix: [
          { show: false, value: undefined, select: undefined },
          { show: false, value: undefined, select: undefined },
          { show: false, value: undefined, select: undefined }
        ],
        showCameraVariable: {
          show: true,
          list: [],
          select: undefined
        }
      };
    },
    created() {
      this.initialize();
    },
    mounted() {
      this.startPosListWithPoleUpdateTimer(this.poleId, this.setting.common.posUpdateInterval, this.timeoutPosListWithPoleTimer);
    },
    beforeDestroy() {
      this.stopPosListWithPoleUpdateTimer();
    },
    methods: {
      initialize() {
        this.setting = this.getSetting();
        this.address = this.$store.state.selectPole.address;
        this.titleInfo.title = this.$store.state.selectPole.address + " マルチカメラモニター";
        this.titleInfo.menuList = this.setMenuList(this.setting.menu.realtime);
        this.poleId = this.$store.state.selectPole.poleId;

        this.initSensorList(this.poleId);
      },
      async initSensorList(poleId) {
        let promise = this.updatePoleList(null, poleId, null, true);
        promise
          .then(() => {
            this.setSelectPole(poleId, this.address);
            this.isLoading = false;
            this.initCameraList();
          })
          .catch(() => {
            this.isLoading = false;
            let title = this.str.dialog.title.getError;
            let message = this.str.dialog.message.getErrorSensorInfo;
            this.showErrorDialig(title, message);
          });
      },
      async initCameraList() {
        this.cameraList = this.getCameraList();
        if (this.cameraList.length == 0) {
          let title = this.str.dialog.title.getError;
          let message = this.str.dialog.message.getErrorCameraNoContent;
          this.showErrorDialig(title, message);
        } else {
          let fix = 0;
          this.cameraList.forEach(camera => {
            if (camera.cameraKind == 20 || fix >= 3) {
              this.showCameraVariable.list.push(camera);
              this.showCameraVariable.select = this.showCameraVariable.list[0];
            } else {
              this.showCameraFix[fix].value = camera;
              this.showCameraFix[fix].select = camera;
              this.showCameraFix[fix].show = true;
              fix += 1;
            }
          });
        }
      },
      showErrorDialig(title, message) {
        this.dialog.title = title;
        this.dialog.message = message;
        this.dialog.isDialog = true;
      },
      async timeoutPosListWithPoleTimer(poleId) {
        let promise = this.updatePosListWithPole(poleId, undefined, true);
        promise
          .then(() => {
            this.setSelectPole(poleId, this.address);
            let newCameraList = this.getCameraList();
            this.updateCameraList(this.cameraList, newCameraList, this.updateCameraEvent);
            // this.updateLocationList()
          })
          .catch(error => {
            if (error == this.errcode.noContent) {
              let newCameraList = this.getCameraList();
              this.updateCameraList(this.cameraList, newCameraList, this.updateCameraEvent);
            }
            // this.updateLocationList()
          });
      },
      // updateLocationList() {
      //   let sensorPosList = this.$store.state.posList;
      //   let locationList = [];
      //   sensorPosList.forEach(sensor => {
      //     let sensorSetting = this.getSensorSetting(sensor.sensorId);
      //     if (sensorSetting != undefined) {
      //       let sensorName = sensorSetting.name;
      //       sensor.posList.forEach(pos => {
      //         this.setLocationList(sensor.sensorId, pos.vehicleSizeClassification, sensorName, locationList);
      //         sensorName = "";
      //       });
      //     }
      //   });
      //   this.titleInfo.locationList = locationList;
      // },
      setLocationList(sensorId, size, sensorName, locationList) {
        let hit = false;

        locationList.forEach(item => {
          if (item.id == sensorId && item.size == size) {
            item.count += 1;
            hit = true;
            return;
          }
        });
        if (!hit) {
          let objectName = this.getObjectName(size);
          locationList.push({
            id: sensorId,
            size: size,
            sensorName: sensorName,
            objectName: objectName,
            count: 1
          });
        }
      },
      updateCameraEvent() {
        this.cameraList = this.getCameraList();
        if (this.showCameraFix[0].show) {
          if (this.checkSelectCamera(this.cameraList, this.showCameraFix[0].value)) {
            if (this.showCameraFix[0].select == undefined) {
              this.showCameraFix[0].select = this.showCameraFix[0].value;
            }
          } else {
            if (this.showCameraFix[0].select != undefined) {
              this.showCameraFix[0].select = undefined;
            }
          }
        }
        if (this.showCameraFix[1].show) {
          if (this.checkSelectCamera(this.cameraList, this.showCameraFix[1].value)) {
            if (this.showCameraFix[1].select == undefined) {
              this.showCameraFix[1].select = this.showCameraFix[1].value;
            }
          } else {
            if (this.showCameraFix[1].select != undefined) {
              this.showCameraFix[1].select = undefined;
            }
          }
        }
        if (this.showCameraFix[2].show) {
          if (this.checkSelectCamera(this.cameraList, this.showCameraFix[2].value)) {
            if (this.showCameraFix[2].select == undefined) {
              this.showCameraFix[2].select = this.showCameraFix[2].value;
            }
          } else {
            if (this.showCameraFix[2].select != undefined) {
              this.showCameraFix[2].select = undefined;
            }
          }
        }
        if (this.showCameraVariable.select != undefined) {
          if (!this.checkSelectCamera(this.cameraList, this.showCameraVariable.select)) {
            this.showCameraVariable.select = undefined;
          }
        }
        this.updateShowCameraVariable(this.cameraList);
      },
      checkSelectCamera(cameraList, selectCamera) {
        let result = false;
        cameraList.forEach(camera => {
          if (camera.sensorId == selectCamera.sensorId) {
            result = true;
            return;
          }
        });
        return result;
      },
      updateShowCameraVariable(cameraList) {
        let list = [];
        cameraList.forEach(camera => {
          if (
            camera.sensorId != this.showCameraFix[0].value.sensorId &&
            camera.sensorId != this.showCameraFix[1].value.sensorId &&
            camera.sensorId != this.showCameraFix[2].value.sensorId
          ) {
            list.push(camera);
          }
        });
        if (this.checkUpdateShowCameraVariableList(this.showCameraVariable.list, list)) {
          this.showCameraVariable.list = list;
        }
      },
      checkUpdateShowCameraVariableList(oldList, newList) {
        if (oldList.length != newList.length) {
          return true;
        }
        oldList.sort(function(a, b) {
          if (a.sensorId < b.sensorId) return -1;
        });
        newList.sort(function(a, b) {
          if (a.sensorId < b.sensorId) return -1;
        });
        for (let i = 0; i < oldList.length; i++) {
          if (oldList[i].sensorId != newList[i].sensorId) {
            return true;
          }
        }
        return false;
      }
    }
  };
</script>

<style>
  .multicamera_view {
    padding-top: 40px;
    height: calc(100vh - 50px);
  }

  #multicamera_camera {
    height: 45vh;
    width: 100%;
    background-color: rgb(56, 54, 54);
  }
  #multicamera_camera2 {
    height: 47vh;
    width: 100%;
    background-color: rgb(56, 54, 54);
  }
  #multicamera_logo {
    background-color: #353535;
    height: 41vh;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    opacity: 0.6;
  }
</style>
