<template>
  <v-container>
    <v-data-table
      nav
      dense
      :headers="shownHeaders"
      :items="posList"
      :icon="directionIcon"
      :items-per-page="itemsPerPage"
      :footer-props="{ itemsPerPageOptions: [itemsPerPage] }"
      @click:row="onClickEvent"
    >
      <template v-slot:item.mark="{ item }">
        <img :src="item.mark" class="black-icon" style="width: 25px; height: 25px" />
      </template>
      <template v-slot:item.direction="{ item }">
        <img :src="directionIcon" v-bind:style="item.direction" />
      </template>
      <template v-slot:item.angle="{ item }">
        <img :src="steeringIcon" v-bind:style="item.angle" />
      </template>
    </v-data-table>

    <LocationCheckBox :dialog="dialog" :checkboxes="checkboxes" v-on:dialogOk="dialogOk" />
  </v-container>
</template>

<script>
  import LocationCheckBox from "@/components/LocationCheckBox";
  import { commonFunction, mapFunction } from "@/mixins/utils";

  export default {
    name: "LocationInfoTable",
    components: {
      LocationCheckBox
    },
    mixins: [commonFunction, mapFunction],
    props: ["locationTable", "selectSensor", "itemsPerPage"],
    data: () => ({
      setting: undefined,
      dialog: false,
      checkboxes: [],
      listHeaders: [],
      posList: []
    }),
    created() {
      this.initialize();
    },
    computed: {
      shownHeaders() {
        return this.listHeaders.filter(h => h.shown);
      }
    },
    methods: {
      initialize() {
        this.checkboxes = this.locationTable.checkBoxes;
        this.listHeaders = this.locationTable.listHeader;
        this.setting = this.getSetting();
      },
      columSelectModal() {
        this.dialog = true;
      },
      dialogOk() {
        for (let i = 0; i < this.checkboxes.length; i++) {
          this.listHeaders[i].shown = false;
          if (this.checkboxes[i].value) {
            this.listHeaders[i].shown = true;
          }
        }
        this.dialog = false;
      },
      onClickEvent(data) {
        let coord = data.coordinate.split("\n");
        let latlng = [Number(coord[0]), Number(coord[1])];
        this.$emit("selectObject", latlng);
      },
      updateList() {
        let sensorPosList = this.$store.state.posList;
        this.posList = [];
        sensorPosList.forEach(sensorPos => {
          this.selectSensor.forEach(sensor => {
            let sensorKind = sensorPos.sensorId;
            if (sensorKind == sensor.value) {
              let posList = sensorPos.posList;
              this.setPosList(posList, sensor.name, sensor.kind);
              return;
            }
          });
        });
      },
      setPosList(posList, sensor, kind) {
        posList.forEach(pos => {
          let mark = this.getObjectMarker(pos.vehicleSizeClassification);
          let name = this.getObjectNameWithVehicleId(pos.vehicleId, pos.vehicleSizeClassification);
          let time = pos.time.slice(5);
          let speed = pos.speed.toFixed(2) + " m/sec";
          let coord = pos.latitude.toFixed(6) + "\n" + pos.longitude.toFixed(6);
          let shift = this.getShiftName(pos.transmissionState);
          let directionStyle = this.getDirectionStyle(pos.heading);
          let steeringStyle = this.getSteeringStyle(pos.heading);
          this.posList.push({
            mark: mark,
            time: time,
            sensor: sensor,
            kind: kind,
            name: name,
            coordinate: coord,
            speed: speed,
            direction: directionStyle,
            shift: shift,
            angle: steeringStyle
          });
        });
      },
      getDirectionStyle(direction) {
        return {
          width: "20px",
          height: "20px",
          transform: "rotate(" + direction + "deg)"
        };
      },
      getSteeringStyle(steering) {
        return {
          width: "20px",
          height: "20px",
          transform: "rotate(" + steering + "deg)"
        };
      },
      update() {
        this.updateList();
      }
    },
    watch: {
      selectSensor() {
        this.updateList();
      }
    }
  };
</script>

<style>
  .v-data-table-header {
    background-color: lightgray;
    color: white;
  }
  .black-icon {
    filter: brightness(0);
  }
</style>
