<template>
  <div class="historylist_view">
    <Loading v-show="isLoading" />
    <v-container fill-height>
      <v-row>
        <v-col id="history_list" cols="8">
          <v-card elevation="5">
            <v-card-title class="py-0" style="font-size: 17px; font-weight: bold; height: 10%">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details> </v-text-field>
            </v-card-title>
            <v-divider />
            <v-data-table v-if="isLoading == false" :headers="headers" :items="accidentList" :search="search" :items-per-page="10" :footer-props="{ itemsPerPageOptions: [5, 10] }">
              <template v-slot:item.icon="{ item }">
                <v-icon @click="selectItem(item)">mdi-location-enter</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-main>
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" />
    <CommonDialog :dialog="dialog" />
  </div>
</template>

<script>
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import CommonDialog from "@/components/CommonDialog";
  import router from "@/router";
  import { STORE_ACTIONS } from "@/utils/config";
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import * as log from "loglevel";

  export default {
    name: "HistoryListView",
    components: {
      Title,
      Loading,
      CommonDialog
    },
    mixins: [commonFunction, communicationFunction],
    data: function() {
      return {
        setting: undefined,
        titleInfo: {
          title: undefined,
          show: {
            menu: true
          },
          menuList: undefined
        },
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        poleid: undefined,
        directTransit: false,
        search: "",
        headers: [],
        accidentList: [],
        isLoading: true
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      initialize() {
        this.directTransit = this.$route.query.direct;

        this.setting = this.getSetting();
        this.headers = this.setting.historyListHeader;

        if (this.directTransit != "false") {
          this.titleInfo.title = "過去データ(事故データ)一覧 最新20件";
          this.titleInfo.menuList = this.setting.menu.historyListDirect;
          this.setAccidentList();
        } else {
          this.titleInfo.title = "過去データ(事故データ)一覧 " + this.$store.state.selectPole.address;
          this.titleInfo.menuList = this.setting.menu.historyList;
          this.poleid = this.$store.state.selectPole.poleId;
          this.setAccidentList(this.poleid);
        }
      },
      async setAccidentList(poleId) {
        let promise = this.updateAccidentList(poleId);
        promise
          .then(() => {
            this.createAccidentList();
          })
          .catch(error => {
            let title = this.str.dialog.title.getError;
            let message = this.str.dialog.message.getErrorAccidentInfo;
            if (error == this.errcode.noContent) {
              message = this.str.dialog.message.getErrorAccidentNoContent;
            }
            this.showErrorDialig(title, message);
            this.isLoading = false;
          });
      },
      showErrorDialig(title, message) {
        this.dialog.title = title;
        this.dialog.message = message;
        this.dialog.isDialog = true;
      },
      async createAccidentList() {
        let list = this.$store.state.accidentList;
        for (let i = 0; i < list.length; i++) {
          try {
            let response = await this.addressByCoordinates(list[i].latitude, list[i].longitude);
            if (response.ok) {
              let address = "";
              if (response.body.address.postcode != undefined) {
                address += "〒" + response.body.address.postcode + " ";
              }
              if (response.body.address.province != undefined) {
                address += response.body.address.province;
              }
              if (response.body.address.city != undefined) {
                address += response.body.address.city;
              }
              if (response.body.address.county != undefined) {
                address += response.body.address.county;
              }
              if (response.body.address.hamlet != undefined) {
                address += response.body.address.hamlet;
              }
              if (response.body.address.suburb != undefined) {
                address += response.body.address.suburb;
              }
              if (response.body.address.neighbourhood != undefined) {
                address += response.body.address.neighbourhood;
              }
              if (response.body.address.road != undefined) {
                address += response.body.address.road;
              }
              this.accidentList.push({
                accidentId: list[i].accidentId,
                poleId: list[i].poleId,
                accidentTime: list[i].accidentTime,
                location: address,
                latlng: [list[i].latitude, list[i].longitude],
                acceleration: list[i].acceleration,
                movieList: list[i].movieList
              });
            }
          } catch {
            this.accidentList.push({
              accidentId: list[i].accidentId,
              poleId: list[i].poleId,
              accidentTime: list[i].date,
              location: "unknown",
              latlng: [list[i].latitude, list[i].longitude],
              acceleration: list[i].acceleration,
              movieList: list[i].movieList
            });
          }
        }
        this.isLoading = false;
      },
      selectItem(item) {
        this.setSelectAccident(item);
        router.push({ path: this.getRouterPath("/monitor/historyData"), query: { direct: this.direct } });
      },
      setSelectAccident(item) {
        try {
          this.$store.dispatch(STORE_ACTIONS.setSelectAccident, item);
        } catch {
          log.error("setSelectAccident error");
        }
      }
    }
  };
</script>

<style>
  .historylist_view {
    padding-top: 40px;
    height: calc(100vh - 50px);
  }

  #history_title {
    height: 30vh;
  }
  #history_list {
    height: 90vh;
  }
  #title {
    font-size: 24px;
    font-weight: bold;
  }
</style>
