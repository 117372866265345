<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="time" label="HH:MM:SS" v-bind="attrs" v-on="on" :value="value" readonly :disabled="disabledTimePicker" />
    </template>
    <v-time-picker v-if="menu" v-model="picker" use-seconds @click="menu = false" />
  </v-menu>
</template>

<script>
  export default {
    name: "TimePicker",
    props: {
      value: {
        type: String,
        require: true
      },
      disabledTimePicker: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        menu: false
      };
    },
    computed: {
      picker: {
        get() {
          return this.value;
        },
        set(val) {
          this.menu = false;
          this.$emit("input", val);
        }
      },
      time: {
        get() {
          return this.value;
        },
        set(val) {
          this.menu = false;
          this.$emit("input", val);
        }
      }
    }
  };
</script>

<style></style>
