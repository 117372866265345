<template>
  <div class="accudentinfo">
    <v-row class="ml-2 mr-2">
      <v-col cols="6" id="left">
        <v-card>
          <div class="dataScreen cols-7">
            <div id="accudentinfoMap"></div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <div class="close-btn-div">
            <v-btn dark class="close-btn" color="blue-grey" v-on:click="close">×</v-btn>
          </div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>車両ID１</th>
                <th>車両ID２</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>種別</td>
                <td class="vehicleId1">{{ vehicleId1.kind }}</td>
                <td class="vehicleId2">{{ vehicleId2.kind }}</td>
              </tr>
              <tr>
                <td>最高速度[km/h]</td>
                <td class="vehicleId1">{{ vehicleId1.maximumSpeed }}</td>
                <td class="vehicleId2">{{ vehicleId2.maximumSpeed }}</td>
              </tr>
              <tr>
                <td>最低速度[km/h]</td>
                <td class="vehicleId1">{{ vehicleId1.minimumSpeed }}</td>
                <td class="vehicleId2">{{ vehicleId2.minimumSpeed }}</td>
              </tr>
              <tr>
                <td>平均速度[km/h]</td>
                <td class="vehicleId1">{{ vehicleId1.averageSpeed }}</td>
                <td class="vehicleId2">{{ vehicleId2.averageSpeed }}</td>
              </tr>
              <tr>
                <td>最頻値[km/h]</td>
                <td class="vehicleId1">{{ vehicleId1.mode }}</td>
                <td class="vehicleId2">{{ vehicleId2.mode }}</td>
              </tr>
            </tbody>
          </table>
        </v-card>
        <br />
        <v-card class="chart-size">
          <LineChart
            v-if="isLineChart"
            :vehicleId1SpeedList="vehicleId1.speedList"
            :vehicleId2SpeedList="vehicleId2.speedList"
            :graphTitleSupplement="graphTitleSupplement"
            :xAxisSupplement="xAxisSupplement"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import LineChart from "@/components/accident/Line";
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import "leaflet-arrowheads";

  export default {
    name: "NearMissinfoDashboard",
    components: {
      LineChart
    },
    data() {
      return {
        map: {},
        latlng: undefined,
        vehicleId1: {
          kind: undefined,
          maximumSpeed: undefined,
          minimumSpeed: undefined,
          averageSpeed: undefined,
          mode: undefined,
          longitudeAndLatitudeList: undefined,
          speedList: undefined
        },
        vehicleId2: {
          kind: undefined,
          maximumSpeed: undefined,
          minimumSpeed: undefined,
          averageSpeed: undefined,
          mode: undefined,
          longitudeAndLatitudeList: undefined,
          speedList: undefined
        },
        graphTitleSupplement: undefined,
        xAxisSupplement: undefined,
        isLineChart: false
      };
    },
    mounted() {
      this.initialize();
      this.setMap();
    },
    methods: {
      initialize() {
        this.latlng = this.$store.state.nearMissDetailInfo.poleCoordinates;
        this.vehicleId1.kind = this.$store.state.nearMissDetailInfo.vehicleId1.kind;
        this.vehicleId1.maximumSpeed = this.$store.state.nearMissDetailInfo.vehicleId1.maximumSpeed;
        this.vehicleId1.minimumSpeed = this.$store.state.nearMissDetailInfo.vehicleId1.minimumSpeed;
        this.vehicleId1.averageSpeed = this.$store.state.nearMissDetailInfo.vehicleId1.averageSpeed;
        this.vehicleId1.mode = this.$store.state.nearMissDetailInfo.vehicleId1.mode;
        this.vehicleId1.longitudeAndLatitudeList = this.$store.state.nearMissDetailInfo.vehicleId1.longitudeAndLatitudeList;
        this.vehicleId1.speedList = this.$store.state.nearMissDetailInfo.vehicleId1.speedList;

        this.vehicleId2.kind = this.$store.state.nearMissDetailInfo.vehicleId2.kind;
        this.vehicleId2.maximumSpeed = this.$store.state.nearMissDetailInfo.vehicleId2.maximumSpeed;
        this.vehicleId2.minimumSpeed = this.$store.state.nearMissDetailInfo.vehicleId2.minimumSpeed;
        this.vehicleId2.averageSpeed = this.$store.state.nearMissDetailInfo.vehicleId2.averageSpeed;
        this.vehicleId2.mode = this.$store.state.nearMissDetailInfo.vehicleId2.mode;
        this.vehicleId2.longitudeAndLatitudeList = this.$store.state.nearMissDetailInfo.vehicleId2.longitudeAndLatitudeList;
        this.vehicleId2.speedList = this.$store.state.nearMissDetailInfo.vehicleId2.speedList;
        this.graphTitleSupplement = this.$store.state.nearMissDetailInfo.graphTitleSupplement;
        this.xAxisSupplement = this.$store.state.nearMissDetailInfo.xAxisSupplement;
        this.isLineChart = true;
      },
      close() {
        this.isLineChart = false;
        this.$emit("colseNearMissinfo");
      },
      setMap() {
        let latlng = this.latlng;
        let zoomLevel = 19;

        if (Object.keys(this.map).length === 0) {
          const map = L.map("accudentinfoMap", {
            dragging: true,
            touchZoom: true,
            scrollWheelZoom: true,
            doubleClickZoom: true,
            boxZoom: true,
            tap: true,
            keyboard: true,
            zoomControl: true
          }).setView(latlng, zoomLevel);
          this.map = map;
        } else {
          this.map.remove();
          const map = L.map("accudentinfoMap", {
            dragging: true,
            touchZoom: true,
            scrollWheelZoom: true,
            doubleClickZoom: true,
            boxZoom: true,
            tap: true,
            keyboard: true,
            zoomControl: true
          }).setView(latlng, zoomLevel);
          this.map = map;
        }
        L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: 'Map data © <a href="http://openstreetmap.org/">OpenStreetMap</a>',
          maxZoom: zoomLevel
        }).addTo(this.map);

        L.polyline(this.vehicleId1.longitudeAndLatitudeList, {
          color: "rgba(19, 110, 214)",
          dashArray: "5, 5",
          weight: 3
        })
          .arrowheads({
            size: "8px",
            syawn: 90,
            frequency: "endonly",
            color: "rgba(19, 110, 214)",
            fill: true,
            fillColor: "rgba(19, 110, 214)"
          })
          .addTo(this.map);

        L.polyline(this.vehicleId2.longitudeAndLatitudeList, {
          color: "rgba(245, 0, 0)",
          dashArray: "5, 5",
          weight: 3
        })
          .arrowheads({
            size: "8px",
            syawn: 90,
            frequency: "endonly",
            color: "rgba(245, 0, 0)",
            fill: true,
            fillColor: "rgba(245, 0, 0)"
          })
          .addTo(this.map);
      }
    }
  };
</script>

<style scoped>
  .accudentinfo {
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #fefefe;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #accudentinfoMap {
    z-index: 0;
    height: 800px;
    width: 100%;
    margin: 0 auto;
  }
  .v-input--selection-controls {
    margin-top: 1px;
    padding-top: 1px;
  }
  table {
    width: 100%;
    height: 50%;
    border-collapse: separate;
    border-spacing: 0;
  }

  table th:first-child {
    border-radius: 5px 0 0 0;
  }

  table th:last-child {
    border-radius: 0 5px 0 0;
    border-right: 1px solid #3c6690;
  }

  table th {
    text-align: center;
    color: white;
    background: linear-gradient(#4334537a, #225588);
    border-left: 1px solid #3c6690;
    border-top: 1px solid #3c6690;
    border-bottom: 1px solid #3c6690;
    width: 25%;
    padding: 10px 0;
  }

  table td {
    text-align: center;
    border-left: 1px solid #0d0d0e;
    border-bottom: 1px solid #0d0d0e;
    border-top: none;
    width: 25%;
    padding: 10px 0;
  }

  table td:last-child {
    border-right: 1px solid #0d0d0e;
  }

  table tr:last-child td:first-child {
    border-radius: 0 0 0 5px;
  }

  table tr:last-child td:last-child {
    border-radius: 0 0 5px 0;
  }
  .vehicleId1 {
    background: rgba(19, 110, 214, 0.35);
  }
  .vehicleId2 {
    background: rgba(245, 0, 0, 0.35);
  }
  .chart-size {
    height: 530px;
  }
  .close-btn-div {
    position: absolute;
    right: 0vw;
    text-align: right;
    bottom: 12.5vw;
  }
  .close-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
</style>
