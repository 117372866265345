<template>
  <div class="d-flex">
    <DatePicker />
    <TimePicker />
  </div>
</template>

<script>
  export default {
    name: "DateTime",
    props: {}
  };
</script>

<style>
  .d-flex {
    display: flex;
  }
</style>
