<template>
  <v-dialog v-model="dialog.isDialog" width="300px" persistent>
    <v-card>
      <v-container>
        <v-card-title id="dialog_title" nav dense> 画面選択 </v-card-title>
        <v-divider />
        <v-card-subtitle id="dialog_subtitle" nav dense>
          <span v-text="dialog.name" />
        </v-card-subtitle>
        <v-card-actions>
          <v-row justify="center">
            <v-col cols="0">
              <v-btn color="blue-grey" dark rounded @click="selectPole">ポール選択画面</v-btn>
              <p />
              <v-btn color="blue-grey" dark rounded @click="watch">見守り画面</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-2" dark rounded @click="dialogClose">Close</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import router from "@/router";
  import { commonFunction } from "@/mixins/utils";

  export default {
    name: "DataSelectDialog",
    props: ["dialog"],
    mixins: [commonFunction],
    methods: {
      selectPole() {
        router.push({ path: this.getRouterPath("/monitor/selectpole") });
      },
      watch() {
        router.push({ path: this.getRouterPath("/monitor/watching") });
      },
      dialogClose() {
        this.$emit("dialogClose");
      }
    }
  };
</script>

<style>
  #dialog_title {
    height: 5vh;
    font-size: 18px;
    font-weight: bold;
  }
  #dialog_subtitle {
    height: 5vh;
    font-size: 15px;
    font-weight: bold;
  }
</style>
