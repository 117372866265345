<template>
  <v-list nav dense>
    <v-subheader style="font-size: 15px">メニュー</v-subheader>
    <v-list-item-group>
      <v-list-item v-for="(item, index) in menuList" :key="index" @click="onClickItem(item)">
        <v-list-item-title style="font-size: 15px">{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
  export default {
    name: "MenuTable",
    props: ["menuList"],
    methods: {
      onClickItem(data) {
        this.$emit("selectMenu", data);
      }
    }
  };
</script>

<style></style>
