<script>
  import { Bar } from "vue-chartjs";
  import moment from "moment";

  /**
   * @class
   * @classdesc 週次通過台数グラフ
   */
  const TrafficWeeklyChart = {
    extends: Bar,
    props: {
      label: Array,
      toDate: moment
    },
    data() {
      return {
        chartContent: {},
        maxLevel: 0,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "通過台数"
          },
          legend: {
            labels: {
              boxWidth: 10,
              fontSize: 10,
              padding: 6
            }
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                ticks: {
                  display: false
                },
                grid: {
                  display: false
                }
              },
              {
                id: "totalX",
                stacked: false
              }
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  min: 0,
                  max: 100,
                  display: false
                },
                grid: {
                  display: false
                }
              },
              {
                id: "totalY",
                ticks: {
                  min: 0,
                  max: 100
                },
                stacked: false
              }
            ]
          }
        }
      };
    },
    created() {
      // Vuexストアからグラフ用データを取得する
      this.chartContent = this.$store.state.aggregations.weekly.trafficReportList;
      let maxCurrent = 0;
      let maxPrev = 0;
      for (let i = 0; i < 7; i++) {
        if (maxCurrent < this.chartContent.current[i]) {
          maxCurrent = this.chartContent.current[i];
        }
        if (maxPrev < this.chartContent.prev[i]) {
          maxPrev = this.chartContent.prev[i];
        }
      }
      this.maxLevel = maxPrev > maxCurrent ? maxPrev : maxCurrent;
      if (this.maxLevel < 50) {
        this.maxLevel = 50;
      } else if (this.maxLevel < 100) {
        this.maxLevel = 100;
      }
      this.options.scales.yAxes.forEach(axes => {
        axes.ticks.max = this.maxLevel;
      });
    },
    mounted() {
      // グラフを生成する
      this.renderChart(this.chartData, this.options);
    },
    computed: {
      /**
       * グラフ生成に必要なデータを設定する
       */
      chartData() {
        if (this.chartContent !== void 0 && this.chartContent.current !== void 0 && this.chartContent.current.length > 0) {
          return {
            labels: this.label,
            datasets: [
              {
                type: "line",
                title: "当週台数",
                label: "当週台数: " + this.chartContent.currentTotal + "台",
                xAxisID: "totalX",
                yAxisID: "totalY",
                borderColor: "#c00",
                fill: false,
                data: this.chartContent.current,
                tension: 0
              },
              {
                type: "line",
                title: "前週台数",
                label: "前週台数: " + this.chartContent.prevTotal + "台",
                xAxisID: "totalX",
                yAxisID: "totalY",
                borderColor: "#393",
                fill: false,
                data: this.chartContent.prev,
                tension: 0
              },
              {
                title: "大型自動車",
                label: "大型自動車",
                backgroundColor: "#f66",
                data: this.chartContent.carsL
              },
              {
                title: "中型自動車",
                label: "中型自動車",
                backgroundColor: "#cc0",
                data: this.chartContent.carsM
              },
              {
                title: "小型自動車",
                label: "小型自動車",
                backgroundColor: "#6c3",
                data: this.chartContent.carsS
              },
              {
                title: "自動二輪車",
                label: "自動二輪車",
                backgroundColor: "#3cc",
                data: this.chartContent.moto
              },
              {
                title: "自転車",
                label: "自転車",
                backgroundColor: "#00f",
                data: this.chartContent.bike
              },
              {
                title: "軽車両",
                label: "軽車両",
                backgroundColor: "#c0c",
                data: this.chartContent.cart
              },
              {
                title: "歩行者",
                label: "歩行者",
                backgroundColor: "#999",
                data: this.chartContent.ped
              },
              {
                title: "その他",
                label: "その他",
                backgroundColor: "#333",
                data: this.chartContent.others
              }
            ]
          };
        }
      }
    }
  };

  export default TrafficWeeklyChart;
</script>
