<template>
  <v-dialog v-model="dialog.isDialog" width="300px" persistent>
    <v-card>
      <v-card-title id="dialog_title" nav dense>{{ dialog.name }}</v-card-title>
      <v-divider />
      <v-card-actions>
        <v-container>
          <v-row dense justify="center">
            <v-col>
              <v-btn color="blue-grey" dark rounded @click="realtimeData">現在データ</v-btn>
            </v-col>
          </v-row>
          <v-row dense justify="center">
            <v-col>
              <v-btn color="blue-grey" dark rounded @click="historyData">過去データ</v-btn>
            </v-col>
          </v-row>
          <v-row dense justify="center">
            <v-col>
              <v-btn color="blue-grey" dark rounded @click="accidentData">ヒヤリハット</v-btn>
            </v-col>
          </v-row>
          <v-row dense justify="center">
            <v-col>
              <v-btn color="blue-grey" dark rounded @click="collections">集計</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey darken-2" dark rounded @click="dialogClose">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import router from "@/router";
  import { commonFunction } from "@/mixins/utils";

  export default {
    name: "DataSelectDialog",
    props: ["dialog"],
    mixins: [commonFunction],
    methods: {
      realtimeData() {
        router.push({ path: this.getRouterPath("/monitor/realtime") });
      },
      historyData() {
        router.push({ path: this.getRouterPath("/monitor/historylist"), query: { direct: false } });
      },
      accidentData() {
        router.push({ path: this.getRouterPath("/monitor/accident") });
      },
      collections() {
        router.push({ path: this.getRouterPath("/monitor/collection") });
      },
      dialogClose() {
        this.$emit("dialogClose");
      }
    }
  };
</script>

<style>
  #dialog_title {
    height: 5vh;
    font-size: 18px;
    font-weight: bold;
  }
  #dialog_subtitle {
    height: 20px;
    padding: 0 8px;
    margin: 0 auto;
    font-size: 15px;
    font-weight: bold;
  }
</style>
