<script>
  import { Bar } from "vue-chartjs";

  export default {
    extends: Bar,
    name: "DailyChart",
    props: {
      label: {
        type: String,
        require: true
      },
      currentData: {
        type: Array,
        require: true
      },
      prevData: {
        type: Array,
        require: true
      },
      ylabelTitle: {
        type: String,
        require: true
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options);
    },
    computed: {
      props() {
        return {
          label: this.label,
          currentData: this.currentData,
          prevData: this.prevData,
          ylabelTitle: this.ylabelTitle
        };
      },
      chartData() {
        return {
          labels: ["0", "2", "4", "6", "8", "10", "12", "14", "16", "18", "20", "22"],
          datasets: [
            {
              label: "本日",
              backgroundColor: "blue",
              data: this.currentData,
              yAxisID: "y-axis"
            },
            {
              label: "前日",
              backgroundColor: "red",
              data: this.prevData,
              yAxisID: "y-axis"
            }
          ]
        };
      },
      options() {
        return {
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "時間(2時間間隔)"
                }
              }
            ],
            yAxes: [
              {
                id: "y-axis",
                position: "left",
                scaleLabel: {
                  display: true,
                  labelString: this.ylabelTitle
                }
              }
            ]
          },
          title: {
            display: true,
            text: this.label
          },
          onClick: this.showModal
        };
      }
    },
    methods: {
      showModal() {
        this.$emit("showModal", this.$options.components.DailyChart, this.props);
      }
    }
  };
</script>
