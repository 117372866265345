<script>
  import { Bar } from "vue-chartjs";

  export default {
    extends: Bar,
    name: "WeeklyChart",
    props: {
      label: {
        type: String,
        require: true
      },
      currentData: {
        type: Array,
        require: true
      },
      prevData: {
        type: Array,
        require: true
      },
      ylabelTitle: {
        type: String,
        require: true
      },
      labels: {
        type: Array,
        require: false,
        default: () => ["月", "火", "水", "木", "金", "土", "日"]
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options);
    },
    computed: {
      props() {
        return {
          label: this.label,
          currentData: this.currentData,
          prevData: this.prevData,
          ylabelTitle: this.ylabelTitle,
          labels: this.labels
        };
      },
      chartData() {
        return {
          labels: this.labels,
          datasets: [
            {
              label: "今週",
              backgroundColor: "blue",
              data: this.currentData,
              yAxisID: "y-axis"
            },
            {
              label: "前週",
              backgroundColor: "red",
              data: this.prevData,
              yAxisID: "y-axis"
            }
          ]
        };
      },
      options() {
        return {
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "曜日"
                }
              }
            ],
            yAxes: [
              {
                id: "y-axis",
                position: "left",
                scaleLabel: {
                  display: true,
                  labelString: this.ylabelTitle
                }
              }
            ]
          },
          title: {
            display: true,
            text: this.label
          },
          onClick: this.showModal
        };
      }
    },
    methods: {
      showModal() {
        this.$emit("showModal", this.$options.components.WeeklyChart, this.props);
      }
    }
  };
</script>
