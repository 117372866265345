<template>
  <div>
    <Loading v-show="isLoading" />
    <v-row class="ml-2 mr-2">
      <v-col cols="12">
        <Bar Title="駐停車状況" />
      </v-col>
    </v-row>

    <v-row class="ml-2 mr-2">
      <v-col cols="7">
        <v-card elevation="10">
          <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 8vh">
            <span class="py-0">VIRTUAL映像</span>
          </v-card-title>

          <div class="dataScreen">
            <div id="monitorMap"></div>
          </div>
        </v-card>
      </v-col>

      <v-col cols="5">
        <div class="mb-2">
          <v-card elevation="3">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 8vh">
              <span class="py-0">日付(表示範囲)</span>
            </v-card-title>
            <ValidationObserver ref="form" v-slot="ObserverProps">
              <Form>
                <template v-slot:dateTime>
                  <div class="d-flex">
                    <ValidationProvider rules="required" v-slot="ProviderProps">
                      <DatePicker :value="searchConditions.fromDate" @input="searchConditions.fromDate = $event" />
                      <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="ProviderProps">
                      <TimePicker :value="searchConditions.fromTime" @input="searchConditions.fromTime = $event" />
                      <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                    </ValidationProvider>
                  </div>

                  <p class="pt-3">～</p>
                  <div class="d-flex">
                    <ValidationProvider rules="required" v-slot="ProviderProps">
                      <DatePicker :value="searchConditions.toDate" @input="searchConditions.toDate = $event" />
                      <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="ProviderProps">
                      <TimePicker :value="searchConditions.toTime" @input="searchConditions.toTime = $event" />
                      <p class="searchError">{{ ProviderProps.errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                </template>

                <template v-slot:threshold>
                  <v-select :items="selectItems" v-model="searchConditions.threshold" multiple></v-select>
                </template>

                <template v-slot:button>
                  <Button v-bind:disabled="isProcessing()" label="決定" @click="Search((init = false), ObserverProps)" />
                </template>
              </Form>
            </ValidationObserver>
          </v-card>
        </div>

        <div class="mb-2">
          <v-card elevation="3">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 8vh">
              <span class="py-0">駐停車車両</span>
            </v-card-title>
            <DataTable :headers="parkingTableHeader" :items="editingData.parkingList" />
          </v-card>
        </div>
      </v-col>
    </v-row>

    <Modal :value="error" @input="error = $event" :text="errorText" />
    <Title :titleInfo="titleInfo" />
  </div>
</template>

<script>
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import { getInitFromDate, getInitToDate } from "@/utils/day.js";
  import { poleIcon, greenIcon, blueIcon, redIcon, yellowIcon } from "@/utils/mapIcon.js";

  export default {
    components: {
      Title,
      Loading
    },
    mixins: [commonFunction, communicationFunction],
    data() {
      return {
        poleId: this.$store.state.selectPole.poleId,
        titleInfo: {
          title: "",
          show: {
            realtime: true,
            multicamera: true,
            virtual: true,
            menu: true
          },
          menuList: []
        },
        init: false,
        isLoading: false,
        activateSubmit: true,
        parkingTableHeader: [
          { text: "車両ID", value: "vehicleId" },
          { text: "緯度", value: "latitude" },
          { text: "経度", value: "longitude" },
          { text: "停車時間", value: "parkingTime" },
          { text: "駐停車開始時間", value: "occurrenceTime" }
        ],

        searchConditions: {
          fromDate: "",
          toDate: "",
          fromTime: "",
          toTime: "",
          threshold: [3]
        },

        editingData: {
          areaName: "",
          mapLatlng: [],
          poleMarkers: [],
          parkingList: [],
          markerLatlng: [0, 0]
        },

        map: {},
        selectItems: [
          { text: "1分未満", value: 0 },
          { text: "1分以上3分未満", value: 1 },
          { text: "3分以上5分未満", value: 2 },
          { text: "5分以上", value: 3 }
        ],

        error: false,
        errorText: "データ"
      };
    },

    created() {
      this.initialize();
    },

    mounted() {
      this.Search((this.init = true));
      this.isProcessing();
    },

    methods: {
      initialize() {
        this.setting = this.getSetting();
        this.titleInfo.menuList = this.setting.menu.parking;
        const from = getInitFromDate();
        const to = getInitToDate();
        this.searchConditions.fromDate = from[0];
        this.searchConditions.fromTime = from[1];
        this.searchConditions.toDate = to[0];
        this.searchConditions.toTime = to[1];

        this.initSensorList(this.poleId);
      },

      isProcessing: function() {
        if (this.searchConditions.threshold.length >= 1) {
          return false;
        } else {
          return true;
        }
      },

      async initSensorList(poleId) {
        let promise = this.updatePoleList(null, poleId, null, true);
        promise
          .then(() => {
            this.setSelectPole(poleId, this.address);
            this.poleInfo = JSON.parse(JSON.stringify(this.$store.state.selectPole));
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },

      async Search(init, ObserverProps) {
        if (!init) {
          const valid = await ObserverProps.validate();
          if (!valid) return;
        }
        this.editingData.parkingList = [];
        const from = this.searchConditions.fromDate + " " + this.searchConditions.fromTime;
        const to = this.searchConditions.toDate + " " + this.searchConditions.toTime;

        this.isLoading = true;
        let promise = this.updateParkingData(this.poleId, from.replaceAll("-", "/"), to.replaceAll("-", "/"), this.searchConditions.threshold);
        promise
          .then(() => {
            this.isLoading = false;
            this.editingData.parkingList = this.$store.state.parkingList;
            this.checkNull(this.editingData.parkingList);
            this.setMonitorMap();
          })
          .catch(() => {
            this.isLoading = false;
          });
      },

      checkNull(data) {
        let targetData = data;
        for (let i = 0; i <= targetData.length; i++) {
          if (targetData[i]) {
            const keys = Object.keys(targetData[i]);
            for (let j = 0; j < keys.length; j++) {
              const key = keys[j];
              if (targetData[i][key] === null) {
                targetData[i][key] = 0;
              }
            }
          }
        }
      },

      setMonitorMap() {
        let latlng = this.$store.state.selectPole.latlng;
        let zoom = this.setting.virtualMap.zoom.default;
        let zoom_min = this.setting.virtualMap.zoom.min;
        let zoom_max = this.setting.virtualMap.zoom.max;

        if (Object.keys(this.map).length === 0) {
          const map = L.map("monitorMap", {
            dragging: true,
            touchZoom: true,
            scrollWheelZoom: true,
            doubleClickZoom: true,
            boxZoom: true,
            tap: true,
            keyboard: true,
            zoomControl: true,
            minZoom: zoom_min,
            maxZoom: zoom_max
          }).setView(latlng, zoom);
          this.map = map;
        } else {
          this.map.remove();
          const map = L.map("monitorMap", {
            dragging: true,
            touchZoom: true,
            scrollWheelZoom: true,
            doubleClickZoom: true,
            boxZoom: true,
            tap: true,
            keyboard: true,
            zoomControl: true,
            minZoom: zoom_min,
            maxZoom: zoom_max
          }).setView(latlng, zoom);
          this.map = map;
        }

        L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: 'Map data © <a href="http://openstreetmap.org/">OpenStreetMap</a>',
          maxZoom: 18
        }).addTo(this.map);

        this.editingData.poleMarkers = L.marker(latlng, { icon: poleIcon }).addTo(this.map);

        if (this.editingData.parkingList.length !== 0) {
          for (let i = 0; i < this.editingData.parkingList.length; i++) {
            this.editingData.markerLatlng[0] = this.editingData.parkingList[i].latitude;
            this.editingData.markerLatlng[1] = this.editingData.parkingList[i].longitude;

            let icon = blueIcon;

            if (this.editingData.parkingList[i].parkingTime < 1) {
              icon = greenIcon;
            }

            if (this.editingData.parkingList[i].parkingTime >= 1 && this.editingData.parkingList[i].parkingTime < 3) {
              icon = blueIcon;
            }

            if (this.editingData.parkingList[i].parkingTime >= 3 && this.editingData.parkingList[i].parkingTime < 5) {
              icon = yellowIcon;
            }

            if (this.editingData.parkingList[i].parkingTime >= 5) {
              icon = redIcon;
            }

            let parkingPoint = L.marker(this.editingData.markerLatlng, { icon });

            parkingPoint.bindPopup(
              `<ul>
              <li>車両ID: ${this.editingData.parkingList[i].vehicleId}</li>
              <li>停車時間: ${this.editingData.parkingList[i].parkingTime}分</li>
            </ul>`
            );
            parkingPoint.addTo(this.map);
          }
        }
      }
    }
  };
</script>

<style>
  #monitorMap {
    z-index: 0;
    height: 500px;
    width: 100%;
    margin: 0 auto;
  }
</style>
