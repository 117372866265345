<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="15vw">
      <v-card elevation="5" outlined>
        <v-card-title>ログアウト</v-card-title>
        <v-card-text>ログアウトしますか？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey" dark @click="agree">OK</v-btn>
          <v-btn color="blue-grey" dark @click="cancel">NO</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        dialog: false,
        resolve: false,
        reject: null
      };
    },
    methods: {
      open() {
        this.dialog = true;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree() {
        this.resolve(true);
        this.dialog = false;
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      }
    }
  };
</script>
