<template>
  <v-dialog v-model="dialog.isDialog" width="500px" persistent>
    <v-card>
      <v-container>
        <v-card-title class="dialog-title" nav dense> グループ編集 </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="3">
                <span>グループID</span>
              </v-col>
              <v-col cols="9">
                <span>{{ toHexNumber(groupInfo.groupId) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <span>グループ名</span>
              </v-col>
              <v-col cols="9">
                <v-text-field :rules="[rules.checkInValidGroupName]" clearable v-model="groupInfo.groupName"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="!isValidation()" color="primary darken-2" class="update-btn" @click="onClickUpdate">変更</v-btn>
          <v-btn color="grey darken-2" dark @click="dialogClose">キャンセル</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <Loading v-show="isLoading" />
  </v-dialog>
</template>

<script>
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import Loading from "@/components/Loading";
  import mathFunctions from "@/utils/math";

  export default {
    name: "GroupUpdateDialog",
    components: {
      Loading
    },
    props: ["dialog"],
    mixins: [commonFunction, communicationFunction],
    data: function() {
      return {
        isLoading: false,
        isDisable: false,
        originalGroupName: undefined,
        groupInfo: {
          groupId: undefined,
          groupName: undefined
        },
        errDialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        rules: {
          checkInValidGroupName: value => {
            if (!value) {
              // 値が空ではないこと
              return "値を入力してください";
            } else if (value == this.originalGroupName) {
              // 古い値ではないこと
              return "新しいグループ名を入力してください";
            } else if (value.length > 50) {
              // グループ名文字数が1~50の範囲内であること
              return "グループ名が長すぎます";
            }
            return true;
          }
        }
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      ...mathFunctions,
      /**
       * 初期処理
       */
      initialize() {
        this.groupInfo.groupId = this.dialog.item.groupid;
        this.groupInfo.groupName = this.dialog.item.groupname;
        this.originalGroupName = this.dialog.item.groupname;
      },
      /**
       * 変更ボタン活性/非活性の判定
       */
      isValidation() {
        if (this.groupInfo.groupName && this.groupInfo.groupName.length <= 50 && this.originalGroupName != this.groupInfo.groupName) {
          this.isDisable = true;
        } else {
          this.isDisable = false;
        }
        return this.isDisable;
      },
      /**
       * グループ情報更新処理
       */
      onClickUpdate() {
        this.isLoading = true;
        let promise = this.updateGroupInfo(this.groupInfo.groupId, this.groupInfo.groupName);
        promise
          .then(() => {
            this.isLoading = false;
            this.$emit("show-error-dialog", true, "グループ更新成功", "グループを更新しました");
          })
          .catch(() => {
            this.isLoading = false;
            // エラーメッセージを設定する
            let title = this.str.dialog.title.updateError;
            let message = this.str.dialog.message.updateErrorGroupInfo;
            this.$emit("show-error-dialog", false, title, message);
          });
      },
      /**
       * ダイアログ閉じる処理
       */
      dialogClose() {
        this.$emit("dialogClose");
      }
    }
  };
</script>

<style scoped>
  .dialog-title {
    height: 5vh;
    font-size: 18px;
    font-weight: bold;
  }
  .update-btn {
    width: 19%;
  }
  .v-text-field {
    padding: 0px;
    margin: 0px;
  }
  .v-input >>> .v-messages {
    margin-top: 10px;
  }
</style>
