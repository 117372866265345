<template>
  <v-list nav dense>
    <v-subheader style="font-size: 15px">地点リスト</v-subheader>
    <v-list-item-group mandatory>
      <v-list-item v-for="(item, index) in pointList" :key="index" @click="onSelectPoint(item)">
        <v-btn icon>
          <v-icon @click="onEnterPoint(item)"> mdi-map-marker </v-icon>
        </v-btn>
        <v-list-item-title style="font-size: 15px">{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
  export default {
    name: "ListTable",
    props: ["pointList"],
    data: () => ({}),
    created() {
      this.initialize();
    },
    methods: {
      initialize() {},
      onSelectPoint(data) {
        this.$emit("selectPoint", data);
      },
      onEnterPoint(data) {
        this.$emit("enterPoint", data);
      }
    }
  };
</script>

<style></style>
