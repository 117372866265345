import atoms from "@/components/atoms";
import molecules from "@/components/molecules";

const components = [...atoms, ...molecules];

function install(Vue) {
  components.forEach(component => {
    Vue.component(component.name, component);
  });
}

export default { install };
