<template>
  <div>
    <v-card>
      <div class="p-2">
        <v-row>
          <v-col cols="12">
            <slot name="date" />
            <v-row>
              <v-col cols="6">
                <slot name="time" />
              </v-col>
              <v-col cols="4" class="pa-13">
                <slot name="threshold" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="pa-2">
                <slot name="deduplication" />
              </v-col>
              <v-col cols="7" class="pa-2">
                <slot name="button" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "AccidentAllIndividualForm"
  };
</script>

<style lang="scss" scoped>
  .p-2 {
    padding: 20px;
  }
</style>
