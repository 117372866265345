<script>
  import { Bar } from "vue-chartjs";

  export default {
    extends: Bar,
    name: "MacroAnalysisHistgram",
    props: {
      xAxes: {
        type: Object,
        require: false,
        default() {
          return {
            scaleLabelDisplay: false,
            labelString: ""
          };
        }
      },
      yAxes: {
        type: Object,
        require: false,
        default() {
          return {
            scaleLabelDisplay: false,
            labelString: ""
          };
        }
      },
      legend: {
        type: Object,
        require: false,
        default() {
          return {
            display: false
          };
        }
      },
      title: {
        type: Object,
        require: false,
        default() {
          return {
            display: true,
            text: ""
          };
        }
      },
      isSuggested: {
        type: Boolean,
        require: false,
        default() {
          return true;
        }
      },
      max: {
        type: Number,
        require: false,
        default() {
          return 1;
        }
      },
      min: {
        type: Number,
        require: false,
        default() {
          return 0;
        }
      },
      maxTicksLimit: {
        type: Number,
        require: false,
        default() {
          return 2;
        }
      },
      backgroundColor: {
        type: String,
        require: true
      },
      labelName: {
        type: String,
        require: false,
        default() {
          return "";
        }
      },
      data: {
        type: Array,
        require: true,
        default() {
          return [];
        }
      }
    },
    computed: {
      props() {
        return {
          xAxes: this.xAxes,
          yAxes: this.yAxes,
          legend: this.legend,
          title: this.title,
          backgroundColor: this.backgroundColor,
          labelName: this.labelName,
          data: this.data,
          isSuggested: this.isSuggested,
          max: this.max,
          min: this.min,
          maxTicksLimit: this.maxTicksLimit
        };
      },
      chartData() {
        return {
          labels: ["0-10km/h", "10-20km/h", "20-30km/h", "30-40km/h", "40-50km/h", "50-60km/h"],
          datasets: [
            {
              label: this.labelName,
              backgroundColor: this.backgroundColor,
              data: this.data,
              yAxisID: "current-y-axis"
            }
          ]
        };
      },
      options() {
        if (this.isSuggested) {
          return {
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: this.xAxes.scaleLabelDisplay,
                    labelString: this.xAxes.labelString
                  },
                  ticks: {
                    maxTicksLimit: 6
                  }
                }
              ],
              yAxes: [
                {
                  id: "current-y-axis",
                  position: "left",
                  scaleLabel: {
                    display: this.yAxes.scaleLabelDisplay,
                    labelString: this.yAxes.labelString
                  },
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: this.maxTicksLimit,
                    max: this.max,
                    min: this.min
                  }
                }
              ]
            },
            onClick: this.showModal,
            title: {
              display: this.title.display,
              text: this.title.text
            },
            legend: {
              display: this.legend.display
            }
          };
        } else {
          return {
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: this.xAxes.scaleLabelDisplay,
                    labelString: this.xAxes.labelString
                  },
                  ticks: {
                    maxTicksLimit: 6
                  }
                }
              ],
              yAxes: [
                {
                  id: "current-y-axis",
                  position: "left",
                  scaleLabel: {
                    display: this.yAxes.scaleLabelDisplay,
                    labelString: this.yAxes.labelString
                  },
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
            },
            onClick: this.showModal,
            title: {
              display: this.title.display,
              text: this.title.text
            },
            legend: {
              display: this.legend.display
            }
          };
        }
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options);
    },
    methods: {
      showModal() {
        this.$emit("showModal", this.$options.components.MacroAnalysisHistgram, this.props);
      }
    }
  };
</script>
