<template>
  <v-list nav dense>
    <v-subheader>交差点情報</v-subheader>
    <v-list-item-group mandatory>
      <v-list-item v-for="(item, index) in locationList" :key="index">
        <v-list-item-title style="font-size: 13px">{{ item.sensorName }}</v-list-item-title>
        <v-list-item-title style="font-size: 13px">{{ item.objectName }}</v-list-item-title>
        <v-list-item-title style="font-size: 13px">{{ item.count }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
  export default {
    name: "LocationListTable",
    props: ["locationList"],
    data: function() {
      return {};
    },
    created() {
      this.initialize();
    },
    methods: {
      initialize() {}
    }
  };
</script>

<style></style>
