<template>
  <div id="aggre_menu">
    <v-expansion-panels dark focusable v-model="isFilterOpen" :disabled="isFilterOpen === void 0 && selectedCategory === 0">
      <v-expansion-panel>
        <v-expansion-panel-header>絞り込み項目</v-expansion-panel-header>
        <v-expansion-panel-content color="#fff">
          <v-container fluid>
            <v-row v-if="selectedCategory === 1">
              <v-col>
                <v-card color="#eee">
                  <v-toolbar dark dense color="#c00">優先</v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-card height="200px" color="#fff">
                          <v-system-bar dark color="primary">物標</v-system-bar>
                          <v-card-text style="padding: 0; height: 170px; overflow-y: auto;">
                            <object-list :mode="1" />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card height="200px" color="#fff">
                          <v-system-bar dark color="primary">IN方路</v-system-bar>
                          <v-card-text style="padding: 0; height: 170px; overflow-y: auto;">
                            <direction-list :mode="1" :dir="1" />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card height="200px" color="#fff">
                          <v-system-bar dark color="primary">OUT方路</v-system-bar>
                          <v-card-text style="padding: 0; height: 170px; overflow-y: auto;">
                            <direction-list :mode="1" :dir="2" />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card color="#eee">
                  <v-toolbar dark dense color="#090">非優先</v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-card height="200px" color="#fff">
                          <v-system-bar dark color="primary">物標</v-system-bar>
                          <v-card-text style="padding: 0; height: 170px; overflow-y: auto;">
                            <object-list :mode="2" />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card height="200px" color="#fff">
                          <v-system-bar dark color="primary">IN方路</v-system-bar>
                          <v-card-text style="padding: 0; height: 170px; overflow-y: auto;">
                            <direction-list :mode="2" :dir="1" />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card height="200px" color="#fff">
                          <v-system-bar dark color="primary">OUT方路</v-system-bar>
                          <v-card-text style="padding: 0; height: 170px; overflow-y: auto;">
                            <direction-list :mode="2" :dir="2" />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else-if="selectedCategory > 1">
              <v-col>
                <v-row>
                  <v-col>
                    <v-card height="200px" color="#fff">
                      <v-system-bar dark color="primary">物標</v-system-bar>
                      <v-card-text style="padding: 0; height: 170px; overflow-y: auto;">
                        <object-list :mode="0" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card height="200px" color="#fff">
                      <v-system-bar dark color="primary">IN方路</v-system-bar>
                      <v-card-text style="padding: 0; height: 170px; overflow-y: auto;">
                        <direction-list :mode="0" :dir="1" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card height="200px" color="#fff">
                      <v-system-bar dark color="primary">OUT方路</v-system-bar>
                      <v-card-text style="padding: 0; height: 170px; overflow-y: auto;">
                        <direction-list :mode="0" :dir="2" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-container>
      <v-row>
        <v-col>
          <Button label="時間別" :disabled="dispSpanSwitch === 1" @click="setDispSpanSwitch(1)" />
        </v-col>
        <v-col>
          <Button label="日別" :disabled="dispSpanSwitch === 2" @click="setDispSpanSwitch(2)" />
        </v-col>
        <v-col>
          <Button label="月別" :disabled="dispSpanSwitch === 3" @click="setDispSpanSwitch(3)" />
        </v-col>
      </v-row>
      <v-row style="color: #000;">
        <!-- 前後期間切り替えボタン -->
        <v-col>
          <v-btn v-if="dispSpanSwitch === 1" block light @click="updateDate(-1)">＜ 前の日</v-btn>
          <v-btn v-if="dispSpanSwitch === 2" block light @click="updateWeek(-1)">＜ 前の週</v-btn>
        </v-col>
        <v-spacer />
        <v-col style="font-size: 1.6em;">
          {{ dateString + " (" + dayStringList[day] + ")" }}
        </v-col>
        <v-spacer />
        <v-col>
          <v-btn v-if="dispSpanSwitch === 1" block light :disabled="isToday" @click="updateDate(1)">次の日 ＞</v-btn>
          <v-btn v-if="dispSpanSwitch === 2" block light :disabled="isToday" @click="updateWeek(1)">次の週 ＞</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import moment from "moment";
  import { mapFunction } from "@/mixins/utils";
  import directions from "@/assets/setting/directions.json";
  import ObjectList from "@/components/aggre/ObjectList";
  import DirectionList from "@/components/aggre/DirectionList";

  /**
   * @class
   * @classdesc 集計メインメニュー
   */
  const AggreMenu = {
    components: {
      ObjectList,
      DirectionList
    },
    props: {
      targetDate: moment,
      selectedCategory: Number
    },
    mixins: [mapFunction],
    data() {
      return {
        // Vuexの購読停止関数
        unsubscribe: null,
        dispSpanSwitch: 0,
        isFilterOpen: void 0,
        dateString: "",
        day: 0,
        dayStringList: ["日", "月", "火", "水", "木", "金", "土"],
        bgList: ["#ddd", "#eee"],
        isToday: true,
        objList: [
          {
            kind: "-01",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "種別不明"
          },
          {
            kind: "001",
            objectMarker: require("@/assets/img/object/human.svg"),
            definition: "歩行者"
          },
          {
            kind: "002",
            objectMarker: require("@/assets/img/object/bicycle.svg"),
            definition: "自転車"
          },
          {
            kind: "003",
            objectMarker: require("@/assets/img/object/bike.svg"),
            definition: "自動二輪車"
          },
          {
            kind: "004",
            objectMarker: require("@/assets/img/object/car.svg"),
            definition: "自動車"
          }
        ],
        directionList: [],
        chkboxLabel: ["IN", "OUT"]
      };
    },
    created() {
      // 対象の日付と曜日を設定する
      this.dateString = this.targetDate.format("YYYY/MM/DD");
      this.day = this.targetDate.day();
      // 物標、方路の一覧データを取得する
      this.directionList = directions.directions;
    },
    watch: {
      targetDate(val) {
        // 現在時刻を設定する
        let latest = moment()
          .hours(0)
          .minutes(0)
          .seconds(0);
        // 表示モードに合わせて、最新の日付の場合は「次」のボタンクリックを無効にする
        switch (this.dispSpanSwitch) {
          case 1:
            // 対象の日付が最新の場合、「次の日」のボタンクリックを無効にする
            this.isToday = latest.diff(val) < 0;
            break;
          case 2:
            // 対象の日付の週が最新以上の場合、「次の週」のボタンクリックを無効にする
            this.isToday = latest.year() <= val.year() && latest.week() <= val.week();
            break;
        }
        // メニューに表示する日付を更新する
        this.dateString = val.format("YYYY/MM/DD");
        this.day = val.day();
      },
      selectedCategory(val) {
        // 集計対象が選択された場合は、絞り込み項目を開く
        if (val !== 0) {
          this.isFilterOpen = 0;
        }
      }
    },
    methods: {
      startAggregation() {
        this.$emit("update-aggregation", true);
      },
      /**
       * メニュー操作に合わせて取得対象の日付を変更する
       * @param {Number} diff 差分
       */
      updateDate(diff) {
        this.$emit("update-target-date", 1, diff);
      },
      updateWeek(diff) {
        this.$emit("update-target-date", 2, diff);
      },
      /**
       * 集計期間の変更に伴う処理
       * @param {Number} val 集計期間コード
       */
      setDispSpanSwitch(val) {
        // 本コンポーネントおよびVuexストア上の値を更新し、親コンポーネントに通知を行う
        this.dispSpanSwitch = val;
        this.$emit("update-aggregation", false);
        this.$store.dispatch("updateAggreMode", { category: this.selectedCategory, span: val });
      }
    }
  };

  export default AggreMenu;
</script>

<style>
  #aggre_menu {
    background-color: #eee;
  }
  .menu_icon {
    width: 16px;
    height: 16px;
  }
  .chkbox_bg:first-child {
    background-color: #ddd;
  }
  .chkbox_bg:last-child {
    background-color: #eee;
  }
  .v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0px !important;
  }
  .v-input__slot {
    margin-bottom: 0px !important;
  }
  .v-messages {
    min-height: 0px !important;
  }
</style>
