<script>
  import { Line } from "vue-chartjs";
  import moment from "moment";

  /**
   * @class
   * @classdesc 日次交差点侵入速度
   */
  const AxlDailyChart = {
    extends: Line,
    props: {
      label: Array,
      toDate: moment
    },
    data() {
      return {
        chartContent: {},
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "交差点侵入速度"
          },
          legend: {
            labels: {
              boxWidth: 10
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0
                }
              }
            ]
          }
        }
      };
    },
    created() {
      // Vuexストアからデータを取得する
      this.chartContent = this.$store.state.aggregations.daily.axlApproachReportList;
    },
    mounted() {
      // グラフを生成する
      this.renderChart(this.chartData, this.options);
    },
    computed: {
      /**
       * グラフ生成に必要なデータを設定する
       */
      chartData() {
        // 当日と前日のデータがそろってないと設定しないようにする
        if (this.chartContent !== void 0 && this.chartContent.length === 2) {
          if (
            this.chartContent[0].currentAverage !== void 0 &&
            this.chartContent[0].currentAverage.length > 0 &&
            this.chartContent[1].currentMaxAcceleration !== void 0 &&
            this.chartContent[1].currentMaxAcceleration.length > 0
          ) {
            return {
              labels: this.label,
              datasets: [
                {
                  title: "当日平均速度",
                  label: "当日平均速度: 最大" + this.chartContent[0].currentMaxAverage + "km/h",
                  borderColor: "#f00",
                  fill: false,
                  data: this.chartContent[0].currentAverage,
                  tension: 0
                },
                {
                  title: "前日平均速度",
                  label: "前日平均速度: 最大" + this.chartContent[0].prevMaxAverage + "km/h",
                  borderColor: "#3c3",
                  fill: false,
                  data: this.chartContent[0].prevAverage,
                  tension: 0
                },
                {
                  title: "当日最高速度",
                  label: "当日最高速度: 最大" + this.chartContent[1].currentTopAcceleration + "km/h",
                  borderColor: "#600",
                  fill: false,
                  data: this.chartContent[1].currentMaxAcceleration,
                  tension: 0
                },
                {
                  title: "前日最高速度",
                  label: "前日最高速度: 最大" + this.chartContent[1].prevTopAcceleration + "km/h",
                  borderColor: "#060",
                  fill: false,
                  data: this.chartContent[1].prevMaxAcceleration,
                  tension: 0
                }
              ]
            };
          }
        }
      }
    }
  };

  export default AxlDailyChart;
</script>
