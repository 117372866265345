<template>
  <div>
    <v-card>
      <div class="p-2">
        <v-data-table :headers="headers" :items="items"></v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "DataTable",
    props: {
      headers: {
        type: Array,
        require: true
      },
      items: {
        type: Array,
        require: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  .p-2 {
    padding: 10px;
  }
</style>
