<template>
  <div id="object_list">
    <v-simple-table dense light>
      <template v-slot: default>
        <tbody>
          <tr v-for="item in objList" :key="item.icon">
            <td class="text-left">
              <v-checkbox v-model="filteringObj" :value="item.kind">
                <template v-slot:label>
                  <img :src="item.objectMarker" class="obj_icon" />
                  <span style="font-size: 0.8em;">{{ item.definition }}</span>
                </template>
              </v-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
  import { mapFunction } from "@/mixins/utils";

  /**
   * @class
   * @classdesc 物標一覧
   */
  const ObjectList = {
    props: {
      mode: Number
    },
    mixins: [mapFunction],
    data() {
      return {
        objList: [
          {
            kind: "-01",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "種別不明"
          },
          {
            kind: "001",
            objectMarker: require("@/assets/img/object/human.svg"),
            definition: "歩行者"
          },
          {
            kind: "002",
            objectMarker: require("@/assets/img/object/bicycle.svg"),
            definition: "自転車"
          },
          {
            kind: "003",
            objectMarker: require("@/assets/img/object/bike.svg"),
            definition: "自動二輪車"
          },
          {
            kind: "004",
            objectMarker: require("@/assets/img/object/car.svg"),
            definition: "自動車"
          }
        ],
        filteringObj: []
      };
    },
    created() {
      // チェックボックスの選択をリセットする
      this.objList.forEach(obj => {
        this.filteringObj.push(obj.kind);
      });
      this.setObjectCheckbox();
    },
    watch: {
      filteringObj() {
        this.setObjectCheckbox();
      }
    },
    methods: {
      /**
       * 選択されたチェックボックスの値を更新する
       */
      setObjectCheckbox() {
        // 親コンポーネントから送られた集計対象の値に応じて、対象のVuexストアを更新する
        switch (this.mode) {
          // 通過台数、侵入速度
          case 0:
            this.$store.dispatch("updateFilteringObj", this.filteringObj);
            break;
          // ヒヤリハット（優先）
          case 1:
            this.$store.dispatch("updatePriorityObj", this.filteringObj);
            break;
          // ヒヤリハット（非優先）
          case 2:
            this.$store.dispatch("updateNonPriorityObj", this.filteringObj);
            break;
          default:
        }
      }
    }
  };

  export default ObjectList;
</script>

<style lang="scss">
  #object_list {
    table {
      tbody {
        tr {
          td {
            padding-left: 0px;
          }
        }
      }
    }
  }
  .v-input {
    text-align: center;
  }
  .obj_icon {
    width: 16px;
    height: 16px;
  }
</style>
