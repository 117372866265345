<template>
  <v-dialog v-model="dialog.isDialog" width="400px" persistent>
    <v-card>
      <v-container>
        <v-card-title class="dialog-title" nav dense> グループ削除 </v-card-title>
        <v-divider />
        <v-card-text>以下のグループを削除してもよろしいですか？</v-card-text>
        <v-card-text>
          <strong>{{ groupInfo.groupName }}</strong>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary darken-2" class="delete-btn" @click="onClickDelete">削除</v-btn>
          <v-btn color="grey darken-2" dark @click="dialogClose">キャンセル</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <Loading v-show="isLoading" />
  </v-dialog>
</template>

<script>
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import Loading from "@/components/Loading";

  export default {
    name: "GroupDeleteDialog",
    components: {
      Loading
    },
    props: ["dialog"],
    mixins: [commonFunction, communicationFunction],
    data: function() {
      return {
        isLoading: false,
        groupInfo: {
          groupId: undefined,
          groupName: undefined
        },
        errDialog: {
          isDialog: false,
          title: "",
          message: ""
        }
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      /**
       * 初期処理
       */
      initialize() {
        this.groupInfo.groupId = this.dialog.item.groupid;
        this.groupInfo.groupName = this.dialog.item.groupname;
      },
      /**
       * グループ情報削除処理
       */
      onClickDelete() {
        this.isLoading = true;
        let promise = this.deleteGroupInfo(this.groupInfo.groupId);
        promise
          .then(() => {
            this.isLoading = false;
            this.$emit("show-error-dialog", true, "グループ削除成功", "グループを削除しました");
          })
          .catch(() => {
            this.isLoading = false;
            // エラーメッセージを設定する
            let title = this.str.dialog.title.deleteError;
            let message = this.str.dialog.message.deleteErrorGroupInfo;
            this.$emit("show-error-dialog", false, title, message);
          });
      },
      /**
       * ダイアログ閉じる処理
       */
      dialogClose() {
        this.$emit("dialogClose");
      }
    }
  };
</script>

<style scoped>
  .dialog-title {
    height: 5vh;
    font-size: 18px;
    font-weight: bold;
  }
  .delete-btn {
    width: 24%;
  }
  .v-input >>> .v-messages {
    margin-top: 10px;
  }
</style>
