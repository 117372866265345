<template>
  <div id="passing_object_collection_unit">
    <v-layout style="position: related; height: 70px;">
      <v-row>
        <v-col sm="1" class="search_title">
          選択期間
        </v-col>
        <v-col sm="3" md="2">
          <v-menu v-model="datePicked.start" :close-on-content-click="false" :return-value.sync="collectedSpan.start" transition="scale-transition" offset-y min-width="auto">
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="displayedSpan.start"
                label="開始日"
                prepend-inner-icon="mdi-calendar"
                readonly
                :rules="[validateRules.startLimit, validateRules.diffDays]"
                v-bind="attrs"
                v-on="on"
                @click:prepend-inner="datePicked.start = true"
              />
            </template>
            <v-date-picker v-model="collectedSpan.start" no-title scrollable @input="updateCollectedSpanStart" />
          </v-menu>
        </v-col>
        <v-col sm="3" md="2">
          <v-menu v-model="datePicked.end" :close-on-content-click="false" :return-value.sync="collectedSpan.end" transition="scale-transition" offset-y min-width="auto">
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="displayedSpan.end"
                label="終了日"
                prepend-inner-icon="mdi-calendar"
                readonly
                :rules="[validateRules.endLimit]"
                v-bind="attrs"
                v-on="on"
                @click:prepend-inner="datePicked.end = true"
              />
            </template>
            <v-date-picker v-model="collectedSpan.end" no-title scrollable @input="updateCollectedSpanEnd" />
          </v-menu>
        </v-col>
        <v-col sm="1">
          <button v-on:click="createCsv" class="csv_icon">
            <img src="@/assets/icons/csvDownloadIcon.png" />
          </button>
        </v-col>
        <v-spacer />
      </v-row>
    </v-layout>
    <v-layout>
      <v-row>
        <v-col>
          <v-card outlined elevation="5">
            <v-card-title class="py-0 title_text">
              {{ collectionTitle }}
            </v-card-title>
            <v-card-text v-if="collectionsEnabled">
              <div class="table_unit">
                <v-data-table :headers="collectionListHeader" :items="collectionData" :items-per-page="10" class="table_body" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script>
  /**
   * PassingObjectsCollection.vue
   * 通過物標件数集計画面
   */
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import mathFunctions from "@/utils/math.js";
  import directionsJson from "@/assets/setting/directions.json";
  import moment from "moment";

  const PassingObjectCollection = {
    mixins: [commonFunction, communicationFunction],
    data() {
      return {
        // セッティング情報
        setting: {},
        // ポール情報
        poleData: {
          poleId: 0,
          areaId: 0,
          latlng: [0, 0],
          address: "",
          sensorList: []
        },
        // 一覧タイトル(ポール名を追加する)
        collectionTitle: "",
        // 通過物標件数データ
        passingObjectCollectionList: [],
        // 表示対象となる方位名リスト
        vdiParamList: [],
        // 一覧表表示フラグ
        collectionsEnabled: false,
        // 一覧表ヘッダー
        collectionListHeader: [],
        csvHeader: "",
        // 一覧表ボディ
        collectionData: [],
        // 集計用一覧テンプレート
        collectionDataTemplate: {},
        // デートピッカー表示フラグ
        datePicked: {
          start: false,
          end: false
        },
        // 取得日付範囲
        collectedSpan: {
          start: "",
          end: ""
        },
        // テキストフィールド表示用取得日付範囲
        displayedSpan: {
          start: "",
          end: ""
        },
        // 入力フォームのバリデーションルール
        validateRules: {
          // 終了日
          startLimit: value => {
            const target = moment(value, "YYYY/MM/DD");
            if (moment().diff(target, "days") < 1) {
              return "開始日は前日以前の日付を入力してください。";
            } else {
              return true;
            }
          },
          // 終了日
          endLimit: value => {
            const target = moment(value, "YYYY/MM/DD");
            if (moment().diff(target, "days") < 1) {
              return "終了日は前日以前の日付を入力してください。";
            } else {
              return true;
            }
          },
          // 開始、終了日の整合性
          diffDays: value => {
            const target = moment(value, "YYYY/MM/DD");
            if (target.diff(moment(this.displayedSpan.end, "YYYY/MM/DD"), "days") > 0) {
              return "開始日は終了日の前の日を入力してください。";
            } else {
              return true;
            }
          }
        }
      };
    },
    /**
     * 初期処理
     */
    created() {
      // セッティング情報を取得する
      this.setting = this.getSetting();
      // ポール情報を取得する
      this.poleData = this.$store.state.selectPole;
      this.collectionTitle = "通過物標件数　" + this.poleData.address;
      // 取得期間として、10日前から前日までに設定する
      this.collectedSpan = {
        start: moment()
          .subtract(10, "days")
          .toISOString(true),
        end: moment()
          .subtract(1, "days")
          .toISOString(true)
      };
      this.displayedSpan = {
        start: moment(this.collectedSpan.start).format("YYYY/MM/DD"),
        end: moment(this.collectedSpan.end).format("YYYY/MM/DD")
      };
      // 通過物標件数を取得する
      this.setPassingObjectCollection();
    },
    methods: {
      ...mathFunctions,
      /**
       * デートピッカーで選択された値から、取得開始日を更新する
       * @param {String} date 選択された日付
       */
      updateCollectedSpanStart(date) {
        this.collectedSpan.start = moment(date, "YYYY/MM/DD").toISOString(true);
        this.displayedSpan.start = date.replace(/-/g, "/");
        // 更新したらデートピッカーを閉じる
        this.datePicked.start = false;
        this.validateCollectionSpan();
      },
      /**
       * デートピッカーで選択された値から、取得終了日を更新する
       * @param {String} date 選択された日付
       */
      updateCollectedSpanEnd(date) {
        this.collectedSpan.end = moment(date, "YYYY/MM/DD").toISOString(true);
        this.displayedSpan.end = date.replace(/-/g, "/");
        this.datePicked.end = false;
        this.validateCollectionSpan();
      },
      /**
       * 入力された取得期間のバリデーションチェックを行う
       */
      validateCollectionSpan() {
        const start = moment(this.displayedSpan.start, "YYYY/MM/DD");
        const end = moment(this.displayedSpan.end, "YYYY/MM/DD");
        // 取得開始日がアクセス時の前日化をチェック
        if (moment().diff(start, "days") < 1) {
          this.$emit("set-error", "不正な開始日", "開始日は前日以前の日付を入力してください。");
          return;
        }
        // 取得終了日がアクセス時の前日以前かをチェック
        if (moment().diff(end, "days") < 1) {
          this.$emit("set-error", "不正な終了日", "終了日は前日以前の日付を入力してください。");
          return;
        }
        // 開始日が終了日の後になっていないかをチェック
        if (start.diff(end, "days") > 0) {
          this.$emit("set-error", "日付指定の不正", "開始日は終了日の前の日を入力してください。");
          return;
        }
        // バリデーションに問題がなければ通過物標件数データを再取得する
        this.setPassingObjectCollection();
      },
      /**
       * 通過物標件数データの取得
       */
      setPassingObjectCollection() {
        this.$emit("set-loading", true);
        // 表示用の一覧表データをリセットする
        this.collectionListHeader = [];
        this.collectionData = [];
        // すでに同じポールID、開始日、終了日で一覧を取得している場合は、Vuexストアから設定する
        if (
          this.poleData.poleId === this.$store.state.passingObjectsPoleId &&
          this.displayedSpan.start.replace(/\//g, "-") === this.$store.state.passingObjectsStartDate &&
          this.displayedSpan.end.replace(/\//g, "-") === this.$store.state.passingObjectsEndDate &&
          this.$store.state.passingObjectCollectionList !== void 0
        ) {
          this.passingObjectCollectionList = this.$store.state.passingObjectCollectionList;
          this.setVdiParamList();
          return;
        }
        // APIからデータを取得する
        // 日付指定が、10日前から前日前の場合、ポールIDのみをパラメータに指定する
        let promise = void 0;
        if (this.collectedSpan.start === moment().subtract(10, "days") && this.collectedSpan.end === moment().subtract(1, "days")) {
          promise = this.getPassingObjectCollectionData(null, null, this.poleData.poleId);
        } else {
          promise = this.getPassingObjectCollectionData(this.displayedSpan.start.replace(/\//g, "-"), this.displayedSpan.end.replace(/\//g, "-"), this.poleData.poleId);
        }
        promise
          .then(res => {
            if (res !== void 0 && res.objects !== null && res.objects.length > 0) {
              this.passingObjectCollectionList = res.objects;
              this.setVdiParamList();
            } else {
              // 取得内容が空値の場合、例外を発生させてエラーメッセージを表示する
              throw new Error(500);
            }
          })
          .catch(error => {
            if (error === 500) {
              this.$emit("set-error", "通過物標件数なし", "通過物標件数の登録はありませんでした。");
            } else {
              this.$emit("set-error", "通過物標件数取得異常", "通過物標件数取得中に異常が発生しました。: " + error);
            }
            this.$emit("set-loading", false);
          });
      },
      /**
       * 方路方位リストの取得
       */
      setVdiParamList() {
        // すでに同じポールIDでアクセス済みの場合、Vuexストアから方位リストを取得する
        if (this.poleData.poleId === this.$store.state.vdiPoleId) {
          this.vdiParamList = this.$store.state.vdiParamList;
          this.createList();
          return;
        }
        const promise = this.getVdiParametersList(this.poleData.poleId);
        promise
          .then(res => {
            if (res !== void 0 && res.direction !== void 0 && res.direction.length > 0) {
              this.vdiParamList = res.direction;
              this.createList();
            } else {
              throw new Error(500);
            }
          })
          .catch(error => {
            if (error === 500) {
              this.$emit("set-error", "方位リストなし", "方位リストの登録はありませんでした。");
            } else {
              this.$emit("set-error", "方位リスト取得異常", "方位リスト取得中に異常が発生しました。");
            }
            this.$emit("set-loading", false);
          });
      },
      /**
       * 一覧表を生成する
       */
      createList() {
        // 一覧のヘッダーを生成する
        this.collectionListHeader = [
          { text: "日付", value: "collectDate", width: "120px", align: "center", class: "header_text" },
          { text: "総数", value: "total", width: "80px", align: "right", class: "header_text" }
        ];
        // CSVのカラム行を生成する
        this.csvHeader = "日付, 総数";
        // 方位と物標の組み合わせでカラム名を生成し、ヘッダーに追加する
        for (let vdi of this.vdiParamList) {
          let dirDesc = "不明";
          const targetDir = directionsJson.directions.find(dir => dir.name === vdi);
          if (targetDir !== void 0) {
            dirDesc = targetDir.desc;
            // 集計データを保存するオブジェクトのテンプレートを生成する
            this.collectionDataTemplate[vdi] = {};
          }
          // 物標は、あらかじめ取得しうる項目をすべて含める
          this.setting.common.objectInfoListForCollection.forEach(obj => {
            this.collectionDataTemplate[vdi][obj.kind.toString()] = 0;
            this.collectionListHeader.push({
              text: dirDesc + " / " + obj.name,
              value: targetDir.name + "_" + obj.kind,
              width: "160px",
              align: "right"
            });
            this.csvHeader += ", " + dirDesc + " / " + obj.name;
          });
        }
        // 通過物標件数一覧表の生成
        let targetDate = this.displayedSpan.start;
        do {
          // 日単位の集計表をテンプレートから設定する
          let work = JSON.parse(JSON.stringify(this.collectionDataTemplate));
          let outputData = {
            collectDate: "",
            total: 0
          };
          // 対象の日のデータを抽出する(APIから取得したデータでは時刻が追加されているため、日付部分だけを抽出して比較する)
          const targetData = this.passingObjectCollectionList.filter(obj => obj.date.replace(/-/g, "/").substring(0, 10) === targetDate);
          if (targetData.length !== 0) {
            // 対象の日のデータが存在する場合、各項目の件数を抽出し、合計を算出する
            let total = 0;
            // 取得した通過物標件数リストを日付単位に抽出する
            for (let data of targetData) {
              work[data.accessroad][data.vehiclesizeclassification] = data.vehiclecount;
              total += data.vehiclecount;
            }
            // 一覧表示用の行データを設定する
            outputData = {
              collectDate: targetDate,
              total: total
            };
            for (let direction in work) {
              for (let vehicleCode in work[direction]) {
                // 方位名、物標コードのキーから、一覧表用のカラム名を設定する
                const numberKey = direction + "_" + vehicleCode;
                // 対象の方位、物標の件数を設定する
                outputData[numberKey] = work[direction][vehicleCode];
              }
            }
          } else {
            // 対象の日のデータが存在しない場合、合計、各項目の値としてハイフンを設定する
            outputData = {
              collectDate: targetDate,
              total: "-"
            };
            for (let key1 in work) {
              for (let key2 in work[key1]) {
                const numberKey = key1 + "_" + key2;
                outputData[numberKey] = "-";
              }
            }
          }
          // 対象の日付分のデータを追加する
          this.collectionData.push(outputData);
          // 対象の日付を1日進める
          targetDate = moment(targetDate, "YYYY/MM/DD")
            .add(1, "day")
            .format("YYYY/MM/DD");
        } while (moment(targetDate, "YYYY/MM/DD").diff(moment(this.displayedSpan.end, "YYYY/MM/DD")) <= 0);
        // 一覧表データを追加したら表示させる
        this.$emit("set-loading", false);
        this.collectionsEnabled = true;
      },
      /**
       * CSVファイルを生成して自動ダウンロードさせる
       */
      createCsv() {
        // 通過物標データがない場合は、エラーを出して終了する
        if (this.collectionData === void 0 || this.collectionData.length === 0) {
          this.$emit("set-error", "通過物標データなし", "出力するデータはありません。");
          return;
        }
        let csvBody = "";
        // 画面表示用の一覧データからCSVボディ部分を生成する
        this.collectionData.forEach(data => {
          for (let key in data) {
            // 合計の値が0の場合、ハイフンを設定する
            if (key === "total") {
              const outputTotal = data[key] === 0 ? "-" : data[key];
              csvBody += outputTotal + ", ";
            } else {
              csvBody += data[key] + ", ";
            }
          }
          csvBody = csvBody.slice(0, -2);
          csvBody += "\n";
        });
        // CSVファイルの生成
        const csv = this.csvHeader + "\n" + csvBody;
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, csv], { type: "text/csv" });
        // 画面内にリンクを生成して、自動でクリックすることでダウンロードを実施させる
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        // ファイル名は、ポールID(16進数表記)、選択期間を付与する形で設定する
        link.download =
          "通過物標件数集計一覧" +
          "_" +
          this.toHexNumber(this.poleData.poleId) +
          "_" +
          this.displayedSpan.start.replace(/\//g, "_") +
          "～" +
          this.displayedSpan.end.replace(/\//g, "_") +
          ".csv";
        link.click();
      }
    }
  };

  export default PassingObjectCollection;
</script>

<style lang="scss" scoped>
  #passing_object_collection_unit {
    padding-top: 10px;
  }
  .title_text {
    font-size: 22px;
    font-weight: bold;
    color: white;
    background-color: #0041c0;
    height: 40px;
  }
  .search_title {
    text-align: right;
    font-size: 24px;
    font-weight: bold;
    margin: 12px auto;
  }
  .csv_icon {
    width: 60px;
  }
  .table_unit {
    width: calc(100vw - 80px);
    overflow-x: auto;
  }
  .header_text {
    font-size: 1.2em;
    font-weight: bold;
  }
</style>
