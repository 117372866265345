<template>
  <div id="aggregation_view">
    <v-layout fluid>
      <v-row>
        <v-col md="3" id="aggre_side_bar">
          <v-row no-gutters style="margin: 8px 0;">
            <v-col>
              <target-map :pole-data="poleData" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-expansion-panels dark focusable multiple v-model="sideMenuOpened">
                <v-expansion-panel>
                  <v-expansion-panel-header>ポール一覧</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <pole-list-mini @update-pole-data="updatePoleData" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>集計対象</v-expansion-panel-header>
                  <v-expansion-panel-content color="#eee">
                    <v-radio-group light v-model="selectedCategory" style="padding: 5px 10px;">
                      <v-radio label="ヒヤリハット" :value="1" />
                      <v-radio label="通過台数" :value="2" />
                      <v-radio label="侵入速度" :value="3" />
                    </v-radio-group>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <Button label="検索" @click="setupAggregation" />
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <aggre-main ref="main" :pole-data="poleData" :selected-category="selectedCategory" @loading-chart-data="setLoading" @set-dialog="setDialog" />
        </v-col>
      </v-row>
    </v-layout>
    <v-main style="padding: 0;">
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" @selectPoint="selectPoint" @enterPoint="enterPoint" />
    <Loading v-show="isLoading" />
    <common-dialog :dialog="dialogMessage" />
  </div>
</template>

<script>
  import TargetMap from "@/components/aggre/TargetMap";
  import PoleListMini from "@/components/aggre/PoleListMini";
  import ObjectList from "@/components/aggre/ObjectList";
  import DirectionList from "@/components/aggre/DirectionList";
  import AggreMain from "@/components/aggre/AggreMain";
  import PageSelectDialog from "@/components/PageSelectDialog";
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import CommonDialog from "@/components/CommonDialog";
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import moment from "moment";
  import * as log from "loglevel";

  /**
   * @classdesc 集計画面
   * @class
   * @author HBA
   */
  const AggregationView = {
    components: {
      PageSelectDialog,
      Title,
      Loading,
      TargetMap,
      PoleListMini,
      ObjectList,
      DirectionList,
      AggreMain,
      CommonDialog
    },
    mixins: [commonFunction, communicationFunction],
    data() {
      return {
        unsubscribe: null,
        isLoading: false,
        titleInfo: {
          title: "",
          show: {
            logout: true,
            point: false,
            menu: true
          },
          pointList: [],
          menuList: []
        },
        poleData: {},
        selectPoint: "",
        enterPoint: "",
        selectedCategory: 0,
        dialogMessage: {
          isDialog: false,
          title: "",
          message: ""
        },
        sideMenuOpened: [0, 1]
      };
    },
    created() {
      this.initialize();
    },
    mounted() {
      // Vuexストアのミュート購読を行うとともに購読停止関数を設定する
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        // 集計画面モードのステートが更新されたら、プロパティの値を更新する
        switch (mutation.type) {
          case "setPriorityObj":
            log.info(state.aggreFilters.priorityObj);
            break;
          case "setNonPriorityObj":
            log.info(state.aggreFilters.nonPriorityObj);
            break;
          case "setFilteringObj":
            log.info(state.aggreFilters.filteringObj);
            break;
          case "setPriorityDirIn":
            log.info(state.aggreFilters.priorityDirIn);
            break;
          case "setPriorityDirOut":
            log.info(state.aggreFilters.priorityDirOut);
            break;
          case "setNonPriorityDirIn":
            log.info(state.aggreFilters.nonPriorityDirIn);
            break;
          case "setNonPriorityDirOut":
            log.info(state.aggreFilters.nonPriorityDirOut);
            break;
          case "setFilteringDirIn":
            log.info(state.aggreFilters.filteringDirIn);
            break;
          case "setFilteringDirOut":
            log.info(state.aggreFilters.filteringDirOut);
            break;
          default:
        }
      });
    },
    beforeDestroy() {
      // ミューテーションの購読を停止する
      this.unsubscribe();
    },
    watch: {
      selectedCategory(newval, oldval) {
        // 集計対象のラジオボタンが初回選択の時だけ、Vuexストアの内容を更新する
        if (oldval === 0) {
          const span = this.$store.state.aggreMode.span;
          this.$store.dispatch("updateAggreMode", { category: newval, span: span });
        }
      }
    },
    methods: {
      initialize() {
        this.setting = this.getSetting();
        // 集計対象の値をリセットする
        this.$store.dispatch("updateAggreMode", { category: this.selectedCategory, span: 0 });
        // ポール情報を設定する
        this.poleData = this.$store.state.selectPole;
        // 画面タイトルを設定する
        this.titleInfo.title = this.$store.state.selectPole.address + " 集計";
        // 右サイドメニューを取得する
        this.titleInfo.menuList = this.setting.menu.aggregation;
      },
      /**
       * 子コンポーネントから送られたフラグ値に応じてローディング画面の表示を制御する
       * @param {Boolean} flag フラグ
       */
      setLoading(flag) {
        this.isLoading = flag;
      },
      /**
       * ポール情報の更新時の処理
       * @param {String} poleId ポールID
       */
      updatePoleData(poleId) {
        const poleList = this.$store.state.poleList;
        // ポール一覧が取得済みなら、対象のポールIDに紐づく情報を一覧から取得する
        if (poleList !== void 0 && poleList !== null && poleList.length > 0) {
          const target = poleList.find(pole => pole.poleId === poleId);
          if (target !== void 0) {
            this.setSelectPole(target.poleId, target.address);
            this.poleData = this.$store.state.selectPole;
            this.titleInfo.title = this.poleData.address + " 集計";
            return;
          }
        }
        // 取得していない場合、ポール情報をAPIから取得する
        const currentDate = moment().format("YYYY/MM/DD HH:mm:ss");
        const getPoleFunc = this.updatePoleList(null, poleId, currentDate, true);
        getPoleFunc.then(() => {
          log.info(this.$store.state.selectPole);
        });
      },
      /**
       * 検索ボタンをクリック時に集計内容を更新する
       */
      setupAggregation() {
        if (this.selectedCategory === 0) {
          this.dialogMessage = {
            isDialog: true,
            title: "集計対象未入力",
            message: "集計対象を選択してください"
          };
          return;
        }
        const span = this.$store.state.aggreMode.span;
        if (span === 0) {
          this.dialogMessage = {
            isDialog: true,
            title: "集計期間未入力",
            message: "集計期間（時間別、日別、月別）を選択してください"
          };
          return;
        }
        this.$store.dispatch("updateAggreMode", { category: this.selectedCategory, span: span });
        this.$refs.main.updateAggregation(true);
      },
      /**
       * メッセージダイアログを表示
       * @param {boolean} flag 表示フラグ
       * @param {string} title タイトル
       * @param {string} message 内容
       */
      setDialog(flag, title, message) {
        this.dialogMessage = {
          isDialog: flag,
          title: title,
          message: message
        };
        this.setLoading(false);
        return;
      }
    }
  };

  export default AggregationView;
</script>

<style>
  #aggregation_view {
    height: calc(100vh - 48px);
  }
  #aggre_side_bar {
    height: calc(100vh - 88px);
    overflow-y: auto;
    position: relative;
    margin-left: 8px;
    margin-top: 8px;
  }
  .v-expansion-panel-header {
    background-color: #0041c0;
    padding: 8px;
    min-height: auto;
  }
  .v-expansion-panel-header--active {
    min-height: auto !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
</style>
