<script>
  import { Bar } from "vue-chartjs";

  export default {
    extends: Bar,
    name: "DailyHistogram",
    props: {
      label: {
        type: String,
        require: true
      },
      currentData: {
        type: Array,
        require: true
      },
      prevData: {
        type: Array,
        require: true
      }
    },
    data() {
      return {
        chartData: {
          labels: ["0-10km/h", "10-20km/h", "20-30km/h", "30-40km/h", "40-50km/h", "50-60km/h"],
          datasets: [
            {
              label: "本日",
              backgroundColor: "blue",
              data: typeof this.currentData !== "undefined" ? this.currentData.slice(0, 6) : [],
              yAxisID: "current-y-axis"
            },
            {
              label: "前日",
              backgroundColor: "red",
              data: typeof this.prevData !== "undefined" ? this.prevData.slice(0, 6) : [],
              yAxisID: "current-y-axis"
            }
          ]
        },
        options: {
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "時間別速度階級"
                }
              }
            ],
            yAxes: [
              {
                id: "current-y-axis",
                position: "left",
                scaleLabel: {
                  display: true,
                  labelString: "台数"
                }
              }
            ]
          },
          onClick: this.showDailyModalHist,
          title: {
            display: true,
            text: this.label
          }
        }
      };
    },
    computed: {
      props0() {
        return {
          label: "0-2時",
          currentData: this.currentData.slice(0, 6),
          prevData: this.prevData.slice(0, 6)
        };
      },
      props2() {
        return {
          label: "2-4時",
          currentData: this.currentData.slice(6, 12),
          prevData: this.prevData.slice(6, 12)
        };
      },
      props4() {
        return {
          label: "4-6時",
          currentData: this.currentData.slice(12, 18),
          prevData: this.prevData.slice(12, 18)
        };
      },
      props6() {
        return {
          label: "6-8時",
          currentData: this.currentData.slice(18, 24),
          prevData: this.prevData.slice(18, 24)
        };
      },
      props8() {
        return {
          label: "8-10時",
          currentData: this.currentData.slice(24, 30),
          prevData: this.prevData.slice(24, 30)
        };
      },
      props10() {
        return {
          label: "10-12時",
          currentData: this.currentData.slice(30, 36),
          prevData: this.prevData.slice(30, 36)
        };
      },
      props12() {
        return {
          label: "12-14時",
          currentData: this.currentData.slice(36, 42),
          prevData: this.prevData.slice(36, 42)
        };
      },
      props14() {
        return {
          label: "14-16時",
          currentData: this.currentData.slice(42, 48),
          prevData: this.prevData.slice(42, 48)
        };
      },
      props16() {
        return {
          label: "16-18時",
          currentData: this.currentData.slice(48, 54),
          prevData: this.prevData.slice(48, 54)
        };
      },
      props18() {
        return {
          label: "18-20時",
          currentData: this.currentData.slice(54, 60),
          prevData: this.prevData.slice(54, 60)
        };
      },
      props20() {
        return {
          label: "20-22時",
          currentData: this.currentData.slice(60, 66),
          prevData: this.prevData.slice(60, 66)
        };
      },
      props22() {
        return {
          label: "22-24時",
          currentData: this.currentData.slice(66, 72),
          prevData: this.prevData.slice(66, 72)
        };
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options);
    },
    methods: {
      showDailyModalHist() {
        this.$emit(
          "showDailyModalHist",
          this.$options.components.DailyHistogram,
          this.props0,
          this.props2,
          this.props4,
          this.props6,
          this.props8,
          this.props10,
          this.props12,
          this.props14,
          this.props16,
          this.props18,
          this.props20,
          this.props22
        );
      }
    }
  };
</script>
