<template>
  <v-text-field v-model="innerValue" :value="value" :label="label" :hint="hint" :prepend-icon="icon" :readonly="readonly" :class="textFieldclass" />
</template>

<script>
  export default {
    name: "TextField",
    props: {
      value: {
        type: [String, Number],
        require: true
      },
      hint: {
        type: String,
        require: false
      },

      icon: {
        type: String,
        require: false
      },
      label: {
        type: String,
        require: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      textFieldclass: {
        type: String,
        require: false
      }
    },
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    }
  };
</script>

<style></style>
