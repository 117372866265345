import moment from "moment";

export function getInitFromDate() {
  let initDate = new Date();
  initDate.setMonth(initDate.getMonth() - 1);
  initDate.setDate(1);
  const formatDate = moment(initDate).format("YYYY-MM-DDTHH:mm:ss");
  let dateTime = formatDate.split("T");
  dateTime[1] = "00:00:00";
  return dateTime;
}

export function getInitToDate() {
  const initDate = new Date();
  const formatDate = moment(initDate).format("YYYY-MM-DDTHH:mm:ss");
  const dateTime = formatDate.split("T");
  return dateTime;
}

export function getDate() {
  const initDate = new Date();
  const formatDate = moment(initDate).format("YYYY-MM-DDTHH:mm:ss");
  const dateTime = formatDate.split("T");
  return dateTime;
}

export function formatDate(date, format) {
  return moment(date).format(format);
}

export function getPreviousDay() {
  let initDate = new Date();
  initDate.setDate(initDate.getDate() - 1);
  const formatDate = moment(initDate).format("YYYY-MM-DDTHH:mm:ss");
  let dateTime = formatDate.split("T");
  dateTime[1] = "00:00:00";
  return dateTime;
}
