<script>
  import { Bar } from "vue-chartjs";

  export default {
    extends: Bar,
    name: "WeeklyHistogram",
    props: {
      label: {
        type: String,
        require: true
      },
      currentData: {
        type: Array,
        require: true
      },
      prevData: {
        type: Array,
        require: true
      },
      selectToWeeklydateTime: {
        type: String,
        require: true
      }
    },
    data() {
      return {
        chartData: {
          labels: ["0-10km/h", "10-20km/h", "20-30km/h", "30-40km/h", "40-50km/h", "50-60km/h"],
          datasets: [
            {
              label: "今週",
              backgroundColor: "blue",
              data: typeof this.currentData !== "undefined" ? this.currentData.slice(0, 6) : [],
              yAxisID: "current-y-axis"
            },
            {
              label: "前週",
              backgroundColor: "red",
              data: typeof this.prevData !== "undefined" ? this.prevData.slice(0, 6) : [],
              yAxisID: "current-y-axis"
            }
          ]
        },
        options: {
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "時間別速度階級"
                }
              }
            ],
            yAxes: [
              {
                id: "current-y-axis",
                position: "left",
                scaleLabel: {
                  display: true,
                  labelString: "台数"
                }
              }
            ]
          },
          onClick: this.showWeeklyModalHist,
          title: {
            display: true,
            text: this.label
          }
        }
      };
    },
    computed: {
      props0() {
        return {
          label: this.getLabel(0),
          currentData: this.currentData.slice(0, 6),
          prevData: this.prevData.slice(0, 6)
        };
      },
      props1() {
        return {
          label: this.getLabel(1),
          currentData: this.currentData.slice(6, 12),
          prevData: this.prevData.slice(6, 12)
        };
      },
      props2() {
        return {
          label: this.getLabel(2),
          currentData: this.currentData.slice(12, 18),
          prevData: this.prevData.slice(12, 18)
        };
      },
      props3() {
        return {
          label: this.getLabel(3),
          currentData: this.currentData.slice(18, 24),
          prevData: this.prevData.slice(18, 24)
        };
      },
      props4() {
        return {
          label: this.getLabel(4),
          currentData: this.currentData.slice(24, 30),
          prevData: this.prevData.slice(24, 30)
        };
      },
      props5() {
        return {
          label: this.getLabel(5),
          currentData: this.currentData.slice(30, 36),
          prevData: this.prevData.slice(30, 36)
        };
      },
      props6() {
        return {
          label: this.getLabel(6),
          currentData: this.currentData.slice(36, 42),
          prevData: this.prevData.slice(36, 42)
        };
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options);
    },
    methods: {
      showWeeklyModalHist() {
        this.$emit("showWeeklyModalHist", this.$options.components.WeeklyHistogram, this.props0, this.props1, this.props2, this.props3, this.props4, this.props5, this.props6);
      },
      getLabel(num) {
        let options = { year: "numeric", month: "numeric", day: "numeric" };
        let startWeek = new Date(this.selectToWeeklydateTime);
        let endWeek = new Date(this.selectToWeeklydateTime);
        let lastWeek = startWeek.setDate(startWeek.getDate() - (7 - num));
        let thisWeek = endWeek.setDate(endWeek.getDate() + num);
        lastWeek = new Date(lastWeek);
        thisWeek = new Date(thisWeek);
        let label = "今週:" + thisWeek.toLocaleString("ja-JP", options) + "  " + "前週:" + lastWeek.toLocaleString("ja-JP", options);
        return label;
      }
    }
  };
</script>
