<template>
  <div class="virtualmap">
    <!-- /* 絶対値で高さを指定しないと地図が表示されない */ -->
    <div id="virtual_map" v-bind:style="{ height: isRealTime ? '60vh' : '82vh' }" />
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
  /**
   * VirtualMap.vue
   * バーチャルマップ
   */
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import "leaflet-semicircle";
  import "leaflet-rotatedmarker";
  import { commonFunction, mapFunction } from "../mixins/utils";

  // フィリピンのエリアID(暫定対応)
  // ※汎用化する場合は別途検討予定
  const PHI_AREA_ID = 2146830336;

  delete L.Icon.Default.prototype._getIconUrl;

  export default {
    name: "VirtualMap",
    mixins: [commonFunction, mapFunction],
    props: ["accident", "selectSensor", "isRealTime"],
    data: function() {
      return {
        setting: undefined,
        poleMarkers: [],
        objectMarkers: [],
        sensorMarkers: [],
        ledMarkers: [],
        targetArea: 0,
        map: undefined,
        sensorList: undefined,
        accidentPoint: undefined
      };
    },
    /**
     * 画面描写前処理
     */
    created() {
      this.initialize();
    },
    /**
     * 画面描写後処理
     */
    mounted() {
      this.createMap();
    },
    /**
     * ウォッチャー
     */
    watch: {
      // 対象のセンサーが更新されたら物標描写を更新する
      selectSensor() {
        this.updateObject();
        this.updateSensorArea();
      }
    },
    methods: {
      /**
       * 初期表示
       */
      initialize() {
        // セッティングファイルとVuexストアからエリアID、センサー一覧、事故地点を取得する
        this.setting = this.getSetting();
        this.targetArea = this.$store.state.selectPole.areaId;
        this.sensorList = this.$store.state.selectPole.sensorList;
        this.accidentPoint = this.$store.state.selectAccident.latlng;
      },
      /**
       * 地図の作成
       */
      createMap() {
        // 対象のポール情報を取得する
        let latlng = this.$store.state.selectPole.latlng;
        let zoom = this.setting.virtualMap.zoom.default;
        let zoom_min = this.setting.virtualMap.zoom.min;
        let zoom_max = this.setting.virtualMap.zoom.max;
        let zoom_max_native = this.setting.virtualMap.zoom.maxNative;

        // markerアイコンと合成する
        L.Icon.Default.mergeOptions(this.getMarkerIcon(false));
        // 地図を表示する
        this.map = L.map("virtual_map", {
          dragging: true,
          touchZoom: true,
          scrollWheelZoom: true,
          doubleClickZoom: true,
          boxZoom: true,
          tap: true,
          keyboard: true,
          zoomControl: true,
          minZoom: zoom_min,
          maxZoom: zoom_max
        }).setView(latlng, zoom);

        L.control.layers(this.getTileLayer(this.map, zoom_max, zoom_max_native)).addTo(this.map);
        // ポールを追加する
        this.addPoleMarker(latlng);

        // 事故があったそのアイコンを表示する
        if (this.accident) {
          this.addAccidentObjectMarker(this.accidentPoint);
        }

        this.addSensorArea();
      },
      /**
       * ポールを追加する
       * @param {Number[]} latlng ポール座標
       */
      addPoleMarker(latlng) {
        this.poleMarkers = null;
        this.poleMarkers = L.marker(latlng).addTo(this.map);
      },
      /**
       * 表示板を追加する
       * @param {Number[]} latlng 表示板座標
       */
      addLedMarker(latlng) {
        L.Icon.Default.mergeOptions(this.getLedMarkerIcon());
        this.ledMarkers.push(L.marker(latlng).addTo(this.map));
      },
      /**
       * 表示板を削除する
       */
      removeLedMarker() {
        for (const item of this.ledMarkers) {
          this.map.removeLayer(item);
        }
      },
      /**
       * ポールを更新する
       */
      updatePoleMarker() {
        this.removePoleMarker();
        this.addPoleMarker(this.$store.state.selectPole.latlng);
      },
      /**
       * ポールを削除する
       */
      removePoleMarker() {
        this.map.removeLayer(this.poleMarkers);
      },
      /**
       * 事故発生地点マーカーを追加する
       * @param {Number[]} latlng 事故地点座標
       */
      addAccidentObjectMarker(latlng) {
        let Icon = L.icon({
          iconUrl: this.accidentIcon,
          iconSize: [20, 20] // アイコンサイズ
        });
        let marker = L.marker(latlng, {
          icon: Icon,
          rotationOrigin: "center center"
        });
        marker.addTo(this.map);
      },
      /**
       * センサー検出エリアを追加する
       */
      addSensorArea() {
        this.removeLedMarker();
        let list = this.sensorList;
        list.forEach(sensor => {
          let type = this.checkSensorType(sensor.sensorId);
          let ischeck = this.checkSensor(sensor.sensorId);
          if (ischeck && type != "表示板") {
            let sensorSetting = this.getSensorSetting(sensor.sensorId);
            this.addSensorAreaMarker(sensor, sensorSetting.color, sensorSetting.name);
          } else if (ischeck && type == "表示板") {
            this.addLedMarker([sensor.latitude, sensor.longitude]);
          }
        });
      },
      /**
       * センサー検出エリアを更新する
       */
      updateSensorArea() {
        this.sensorList = this.$store.state.selectPole.sensorList;
        this.removeSensorAreaMarker();
        this.addSensorArea();
      },
      /**
       * センサーの種類を検出する
       * @param {Number} sensorId センサーID
       * @returns {String} センサー種類名
       */
      checkSensorType(sensorId) {
        let type = "";
        this.selectSensor.forEach(sensor => {
          let sensorKind = sensorId;
          if (sensorKind == sensor.value) {
            type = sensor.kind;
            return;
          }
        });
        return type;
      },
      /**
       * センサーを検出する
       * @param {Number} sensorId センサーID
       * @returns {Boolean} 検出結果
       */
      checkSensor(sensorId) {
        let result = false;
        this.selectSensor.forEach(sensor => {
          let sensorKind = sensorId;
          if (sensorKind == sensor.value) {
            result = true;
            return;
          }
        });
        return result;
      },
      /**
       * センサーの検出範囲を追加する
       * @param {Object} sensor センサー情報
       * @param {String} color 色情報
       * @param {String} name センサー名
       */
      addSensorAreaMarker(sensor, color, name) {
        let latlng = [sensor.latitude, sensor.longitude];
        let directionMin = sensor.detectDirectionMin;
        let directionMax = sensor.detectDirectionMax;
        let radius = sensor.detectDistanceMax;

        if (directionMin == 65535 || directionMax == 65535 || radius == 65535) {
          return;
        }

        if (sensor.detectDirectionMin > sensor.detectDirectionMax) {
          directionMax += 360;
        }
        let semicircle = L.semiCircle(latlng, {
          radius: radius,
          startAngle: directionMin,
          stopAngle: directionMax,
          color: color,
          opacity: 0.0,
          fillOpacity: 0.3,
          weight: 1
        });
        semicircle.bindTooltip(name, {
          className: "sensorArea",
          direction: "center",
          offset: L.point(20, -50),
          opacity: 0.6
        });
        semicircle.addTo(this.map);

        this.sensorMarkers.push({
          sensorId: sensor.sensorId,
          marker: semicircle
        });
      },
      /**
       * センサー検出範囲エリアを削除する
       */
      removeSensorAreaMarker() {
        this.sensorMarkers.forEach(marker => {
          this.map.removeLayer(marker.marker);
        });
        this.sensorMarkers = null;
        this.sensorMarkers = [];
      },
      /**
       * 物標アイコンを更新する
       */
      updateObject() {
        let sensorPosList = this.$store.state.posList;
        this.removeObjectMarker();
        sensorPosList.forEach(sensorPos => {
          this.selectSensor.forEach(sensor => {
            let sensorKind = sensorPos.sensorId;
            // 物標を検出したセンサーが一致する場合、表示を更新する
            if (sensorKind == sensor.value) {
              let posList = sensorPos.posList;
              this.updateObjectMarker(posList, sensorPos.sensorId, sensor.name);
              return;
            }
          });
        });
      },
      /**
       * 物標アイコンを更新する
       * @param {Object} posList 物標リスト
       * @param {Number} sensorId センサーID
       * @param {String} name センサー名(IDの16進数表記)
       */
      updateObjectMarker(posList, sensorId, name) {
        posList.forEach(pos => {
          this.addObjectMarker(sensorId, pos.vehicleId, [pos.latitude, pos.longitude], pos.heading, this.getMapMarker(pos.vehicleSizeClassification, false), name);
        });
      },
      /**
       * 物標アイコンを追加する
       * @param {Number} sensorId センサーID
       * @param {Number} vehicleId 物標ID
       * @param {Number[]} latlng 物標座標
       * @param {Any} heading
       * @param {Object} icon アイコン画像
       * @param {String} name センサー名
       */
      addObjectMarker(sensorId, vehicleId, latlng, heading, icon, name) {
        // アイコンの色と吹き出しの色を設定する
        const colorClass = this.setIconColor(name);
        const tooltipShape = this.setTootipShape(name);
        // アイコンの定義
        let Icon = L.icon({
          iconUrl: icon,
          iconSize: [20, 20], // アイコンサイズ
          className: colorClass
        });
        let direction = 0;
        let marker = L.marker(latlng, {
          icon: Icon,
          rotationAngle: direction,
          rotationOrigin: "center center"
        });
        // ツールチップの定義
        marker.bindTooltip(vehicleId.toString(), {
          className: tooltipShape,
          direction: "center",
          offset: L.point(10, -25),
          permanent: true,
          opacity: 0.6
        });
        marker.addTo(this.map);
        // 表示する物標情報に追加する
        this.objectMarkers.push({
          sensorId: sensorId,
          vehicleId: vehicleId,
          marker: marker
        });
      },
      /**
       * 対象のセンサー、物標が画面上に存在するかをチェックする
       * @param {Number} sensorId センサーID
       * @param {Number} vehicleId 物標ID
       * @returns {Boolean} 検出結果
       */
      checkObjectMarker(sensorId, vehicleId) {
        let result = undefined;
        this.objectMarkers.forEach(marker => {
          if (marker.sensorId == sensorId && marker.vehicleId == vehicleId) {
            result = marker;
            return;
          }
        });
        return result;
      },
      /**
       * 物標アイコンを削除する
       */
      removeObjectMarker() {
        this.objectMarkers.forEach(marker => {
          this.map.removeLayer(marker.marker);
        });
        this.objectMarkers = null;
        this.objectMarkers = [];
      },
      /**
       * 物標情報更新時の処理
       */
      updatePos() {
        this.updateObject();
      },
      /**
       * ポール情報更新時の処理
       */
      updatePole() {
        this.updatePoleMarker();
      },
      /**
       * センサー情報更新時の処理
       */
      updateSensor() {
        this.updateSensorArea();
      },
      /**
       * 地図の中心位置を設定する
       * @param {Number[]} latlng 剤表情報
       */
      setCenter(latlng) {
        this.map.setView(latlng, this.map.getZoom());
      },
      /**
       * 物標アイコンの色を設定する
       * @param {String} name センサー名(センサーIDの16進数表記)
       * @returns {String} アイコンの色設定を行うスタイル名
       */
      setIconColor(name) {
        // フィリピンのポールにのみ、色分け対応を行う
        if (this.targetArea === PHI_AREA_ID) {
          switch (name) {
            // CMOS
            case "050e01":
              return "blue-icon";
            // LiDAR
            case "020c01":
              return "cyan-icon";
            // それ以外
            default:
              return "black-icon";
          }
          // フィリピン以外のエリアでは黒表示にする
        } else {
          return "black-icon";
        }
      },
      /**
       * ツールチップ（吹き出し）の色を設定する
       * @param {String} name センサー名(センサーIDの16進数表記)
       * @return {String} 色設定を行うスタイル名
       */
      setTootipShape(name) {
        if (this.targetArea === PHI_AREA_ID) {
          switch (name) {
            case "050e01":
              return "default_tooltip";
            case "020c01":
              return "diamond_tooltip";
            default:
              return "markerObject";
          }
        } else {
          return "markerObject";
        }
      }
    }
  };
</script>

<style>
  #virtual_map {
    z-index: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 15px;
  }
  /* フィリピン以外のデフォルトの吹き出し形状 */
  .markerObject {
    background: darkcyan;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    border-radius: 40px;
    width: 40px;
    height: 30px;
  }
  /* フィリピンでのデフォルトの吹き出し形状 */
  .default_tooltip {
    background: #33c;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    border-radius: 40px;
    width: 40px;
    height: 30px;
  }
  /* ひし形 */
  .diamond_tooltip {
    background: #0cf;
    color: #333;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    width: 40px;
    height: 30px;
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  }
  /* 爆発型 */
  .exploded_tooltip {
    background: #363;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    width: 40px;
    height: 30px;
    clip-path: polygon(28% 23%, 50% 5%, 68% 18%, 92% 7%, 88% 28%, 100% 45%, 76% 58%, 96% 87%, 60% 75%, 53% 100%, 45% 85%, 28% 100%, 32% 83%, 0 80%, 15% 62%, 4% 47%, 18% 36%, 0 3%);
  }
  /* 吹き出し形状 */
  .speech_bubble_tooltip {
    background: #039;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    width: 40px;
    height: 30px;
    border-radius: 40px;
    clip-path: polygon(20% 10%, 50% 0, 85% 15%, 95% 40%, 100% 50%, 95% 65%, 80% 75%, 70% 75%, 40% 72%, 50% 100%, 25% 72%, 10% 65%, 0 50%, 7% 30%);
  }
  /* アイコン色設定(画像の色相などを変化させる) */
  .black-icon {
    filter: brightness(0);
  }
  .gray-icon {
    filter: contrast(0);
  }
  .red-icon {
    filter: hue-rotate(0deg);
  }
  .orange-icon {
    filter: hue-rotate(60deg);
  }
  .yellow-icon {
    filter: hue-rotate(75deg) brightness(0.6);
  }
  .green-icon {
    filter: hue-rotate(150deg);
  }
  .cyan-icon {
    filter: hue-rotate(192deg);
  }
  .blue-icon {
    filter: hue-rotate(240deg) brightness(0.8) contrast(0.75);
  }
  .purple-icon {
    filter: hue-rotate(285deg);
  }
</style>
