<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <v-card>
      <v-container>
        <v-card-title class="dialog-title" nav dense> グループ作成 </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="3">
                <span>グループ名</span>
              </v-col>
              <v-col cols="9">
                <v-text-field v-model="groupName" :rules="[rules.checkInValidGroupName]" placeholder="グループ名を入力してください。" clearable></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="!isValidation()" color="primary darken-2" class="create-btn" v-on:click="onClickCreate">作成</v-btn>
          <v-btn color="grey darken-2" dark @click="dialogClose">キャンセル</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <Loading v-show="isLoading" />
  </v-dialog>
</template>

<script>
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import Loading from "@/components/Loading";

  export default {
    name: "GroupCreateDialog",
    components: {
      Loading
    },
    props: ["dialog"],
    mixins: [commonFunction, communicationFunction],
    data: function() {
      return {
        isLoading: false,
        isDisable: false,
        groupName: undefined,
        errDialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        rules: {
          checkInValidGroupName: value => {
            if (!value) {
              // 値が空ではないこと
              return "値を入力してください";
            } else if (value.length > 50) {
              // グループ名文字数が1~50の範囲内であること
              return "グループ名が長すぎます";
            }
            return true;
          }
        }
      };
    },
    methods: {
      /**
       * 作成ボタン活性/非活性の判定
       */
      isValidation() {
        if (this.groupName && this.groupName.length <= 50) {
          this.isDisable = true;
        } else {
          this.isDisable = false;
        }
        return this.isDisable;
      },
      /**
       * グループ情報作成処理
       */
      onClickCreate() {
        this.isLoading = true;
        let promise = this.createGroupInfo(this.groupName);
        promise
          .then(() => {
            this.isLoading = false;
            this.$emit("show-error-dialog", true, "グループ作成成功", "グループを作成しました");
          })
          .catch(() => {
            this.isLoading = false;
            // エラーメッセージを設定する
            let title = this.str.dialog.title.createError;
            let message = this.str.dialog.message.createErrorGroupInfo;
            this.$emit("show-error-dialog", false, title, message);
          });
      },
      /**
       * ダイアログ閉じる処理
       */
      dialogClose() {
        this.$emit("dialogClose");
      }
    }
  };
</script>

<style scoped>
  .dialog-title {
    height: 5vh;
    font-size: 18px;
    font-weight: bold;
  }
  .create-btn {
    width: 19%;
  }
  .v-text-field {
    padding: 0px;
    margin: 0px;
  }
  .v-input >>> .v-messages {
    margin-top: 10px;
  }
</style>
